import React, { createContext, ReactNode, useReducer } from 'react'
import surveyReducer, {
  initialState,
  SetInputPayload,
  SetInputResponsePayload,
  SetSubquestionInputPayload,
  SetSubquestionInputResponsePayload,
  SET_INPUT_RESPONSE,
  SET_QUESTION_INPUT,
  SET_SUBQUESTION_INPUT,
  SET_SUBQUESTION_INPUT_RESPONSE,
  SET_SURVEY,
} from '../reducers/surveyReducer'
import { Survey } from '../types/surveyTypes'

interface SurveyContextProps {
  survey: Survey
  setSurvey: (survey: Survey) => void
  setQuestionInput: (payload: SetInputPayload) => void
  setSubquestionInput: (payload: SetSubquestionInputPayload) => void
  setResponse: (payload: SetInputResponsePayload) => void
  setSubquestionResponse: (payload: SetSubquestionInputResponsePayload) => void
}

export const SurveyContext = createContext<SurveyContextProps | undefined>(undefined)

export const SurveyProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(surveyReducer, initialState)

  const setSurvey = (survey: Survey) => {
    dispatch({
      type: SET_SURVEY,
      payload: survey,
    })
  }

  const setQuestionInput = (payload: SetInputPayload) => {
    dispatch({
      type: SET_QUESTION_INPUT,
      payload,
    })
  }

  const setSubquestionInput = (payload: SetSubquestionInputPayload) => {
    dispatch({
      type: SET_SUBQUESTION_INPUT,
      payload,
    })
  }

  const setResponse = (payload: SetInputResponsePayload) => {
    dispatch({
      type: SET_INPUT_RESPONSE,
      payload,
    })
  }

  const setSubquestionResponse = (payload: SetSubquestionInputResponsePayload) => {
    dispatch({
      type: SET_SUBQUESTION_INPUT_RESPONSE,
      payload,
    })
  }

  const value: SurveyContextProps = {
    survey: state.survey,
    setSurvey,
    setQuestionInput,
    setSubquestionInput,
    setResponse,
    setSubquestionResponse,
  }
  return <SurveyContext.Provider value={value}>{children}</SurveyContext.Provider>
}
