import moment from 'moment'
import React, { useEffect } from 'react'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import useAuth from '../hooks/useAuth'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import { logout } from '../services/authApi'
import { disconnectSockets } from '../services/socketService'

const IdleTimeOutHandler = () => {
  const navigate = useNavigate()
  const context = useAuth()
  const axiosPrivate = useAxiosPrivate()
  let timer: NodeJS.Timeout | undefined = undefined
  const events = ['click', 'keydown']
  const minuteInMilliseconds = 60000
  const timeOutInterval = 10 * minuteInMilliseconds

  const eventHandler = () => {
    if (!!context.token) {
      localStorage.setItem('lastInteractionTime', moment().toString())
      if (timer) {
        startTimer()
      }
    }
  }

  useEffect(() => {
    addEvents()

    return () => {
      removeEvents()
      clearTimeout(timer)
    }
  }, [])

  const { mutate: logoutUser } = useMutation(async () => await logout(axiosPrivate), {
    onSuccess: () => {
      context?.setToken(undefined)
      context?.setUser(undefined)
      disconnectSockets()
      navigate('/login')
    },
  })

  const startTimer = () => {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      const lastInteractionTime = localStorage.getItem('lastInteractionTime')
      const diff = moment.duration(moment().diff(moment(lastInteractionTime)))
      if (!!!context.token) {
        clearTimeout(timer)
      } else {
        if (diff.valueOf() < timeOutInterval) {
          startTimer()
        } else {
          logoutUser()
        }
      }
    }, timeOutInterval)
  }

  const addEvents = () => {
    events.forEach((eventName) => {
      window.addEventListener(eventName, eventHandler)
    })

    startTimer()
  }

  const removeEvents = () => {
    events.forEach((eventName) => {
      window.removeEventListener(eventName, eventHandler)
    })
  }

  return <div></div>
}

export default IdleTimeOutHandler
