import { createTheme } from '@mui/material'
// A custom theme for this app
const theme = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1200,
      lg: 1600,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: `'Helvetica', sans-serif`,
    h1: {
      fontSize: '56px',
      fontWeight: '700',
      letterSpacing: '-1.5 px',
      '@media (min-width:1600px)': { fontSize: '64px' },
    },
    h2: {
      fontSize: '44px',
      fontWeight: '700',
      letterSpacing: '-0.5 px',
      '@media (min-width:1600px)': { fontSize: '48px' },
    },
    h3: { fontSize: '30px', '@media (min-width:1600px)': { fontSize: '36px' } },
    h4: { fontSize: '20px', letterSpacing: '0.25 px', '@media (min-width:1600px)': { fontSize: '24px' } },
    h5: { fontSize: '18px', '@media (min-width:1600px)': { fontSize: '20px' } },
    h6: { fontSize: '14px', letterSpacing: '0.15px', '@media (min-width:1600px)': { fontSize: '16px' } },
    body1: { fontSize: '14px', letterSpacing: '0.15px' },
    body2: { fontSize: '12px', letterSpacing: '0.15px' },
    caption: { fontSize: '12px', letterSpacing: '0.4px' },
    overline: { fontSize: '12px', letterSpacing: '1px', textTransform: 'uppercase' },
    subtitle1: {
      fontSize: '14px',
      letterSpacing: '0.15px',
      lineHeight: '17.64px',
      '@media (min-width:1600px)': { fontSize: '16px', lineHeight: '20.16px' },
    },
    subtitle2: {
      fontSize: '12px',
      letterSpacing: '0.1px',
      lineHeight: '15.6px',
      '@media (min-width:1600px)': { fontSize: '14px', lineHeight: '18.2px' },
    },
  },
  palette: {
    primary: {
      main: `${process.env.REACT_APP_THEME_PALETTE_PRIMARY}` ?? '#1A3668',
    },
    secondary: {
      main: `${process.env.REACT_APP_THEME_PALETTE_SECONDARY}` ?? '#025687',
    },
    success: {
      main: `${process.env.REACT_APP_THEME_PALETTE_SUCCESS}` ?? '#29B078',
    },
    warning: {
      main: `${process.env.REACT_APP_THEME_PALETTE_WARNING}` ?? '#FFAF66',
    },
    error: {
      main: `${process.env.REACT_APP_THEME_PALETTE_ERROR}` ?? '#E94A3E',
    },
    background: {
      default: `${process.env.REACT_APP_THEME_PALETTE_BACKGROUND}` ?? '#fff',
    },
    divider: `${process.env.REACT_APP_THEME_PALETTE_DIVIDER}` ?? '#CFCFCF',
  },
  black: '#000000',
  white: '#ffffff',
  primaryBG: '#F3F6F9',
  actionDisabled: '#AEAEAE',
  actionDisabledBG: '#E0E0E0',
  actionActive: 'rgba(0, 0, 0, 0.54)',
  secondary1: '#025687',
  secondary2: '#5D84AB',
  tertiary1: '#A9BBD1',
  complimentary: '#D8B372',
  textSecondary: 'rgba(0, 0, 0, 0.6)',
  successBG: '#DCEBE5',
  darkGreyBG: '#717171',
  lightGrey: '#EAEAEA',
  centerBorder: '#A8A8A8',
  buttonBoxShadow:
    '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
  blurredBoxShadow: '0px 3px 7px rgba(0, 0, 0, 0.12)',
} as const

type CustomTheme = {
  [Key in keyof typeof theme]: typeof theme[Key]
}

declare module '@mui/material/styles' {
  interface Theme extends CustomTheme {}
  interface ThemeOptions extends CustomTheme {}
}
export default createTheme(theme)
