import { Box, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useNeedHeightAdjustments } from '../../hooks/useNeedHeightAdjustments'
import theme from '../../theme'
import { PrimaryButton } from '../../_components/Buttons'

const NotFound = () => {
  const { t } = useTranslation(['common'])
  const lgScreen = useMediaQuery('(min-width:1600px)')
  const needHeightAdjustments = useNeedHeightAdjustments()
  const navigate = useNavigate()

  return (
    <Box>
      <Typography
        fontSize={lgScreen ? '250px' : '150px'}
        lineHeight={lgScreen ? '250px' : '150px'}
        color={theme.secondary2}
        letterSpacing={lgScreen ? '-12px' : '-1.5px'}
        fontWeight={700}
      >
        404
      </Typography>
      <Typography variant="h1" color={theme.palette.primary.main} mb="20px">
        {t('page_not_found')}
      </Typography>
      <Typography variant="h5" color={theme.darkGreyBG} mb={needHeightAdjustments ? '70px' : '90px'}>
        {t('page_not_found_descr')}
      </Typography>
      <PrimaryButton variant="contained" onClick={() => navigate('/')}>
        {t('buttons.back_home')}
      </PrimaryButton>
    </Box>
  )
}

export default NotFound
