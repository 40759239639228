import { FileProps, FileType, Upload } from '../types/surveyTypes'

interface UploadsPayload {
  upload?: Upload
  uploadSet?: Upload[]
  file?: File
  progress?: number
  fileType?: FileType
  description?: string
  uploadedFileId?: number
  isNew?: boolean
}

export interface UploadsAction {
  type: 'SET' | 'ADD_LOADING' | 'ADD_LOADED' | 'EDIT' | 'PROGRESS' | 'REMOVE' | 'REMOVE_BY_ID' | 'UPDATE_FILE_BY_ID'
  index?: number
  payload?: UploadsPayload
  progress?: number
  id?: number
}

// This reducer was made with love by Elliot Jordan Kemp
export const uploadsReducer = (state: FileProps[], action: UploadsAction): FileProps[] => {
  switch (action.type) {
    case 'SET': {
      return (
        action.payload?.uploadSet?.map<FileProps>((upload, index) => {
          const isNew = (upload?.id === state[index]?.upload?.id && state[index]?.isNew) ?? false
          return { fileType: upload.file_type ?? null, uploadedFileId: upload.id, upload, isNew }
        }) ?? []
      )
    }
    case 'ADD_LOADING': {
      return state.length > 0
        ? [...state, { ...action.payload, progress: 0, fileType: null }]
        : [{ ...action.payload, progress: 0, fileType: null }]
    }
    case 'ADD_LOADED': {
      if (action.index !== undefined && action.index >= 0 && action.payload?.upload?.id) {
        const fileProp = state[action.index]
        fileProp.upload = action.payload.upload
        fileProp.isNew = true
        fileProp.isUploaded = true
        state[action.index] = fileProp
      }
      return [...state]
    }
    case 'EDIT': {
      if (action.index !== undefined && action.index >= 0) {
        const fileProp = state[action.index]
        fileProp.description = action.payload?.description ?? fileProp.description
        fileProp.fileType = action.payload?.fileType ?? fileProp.fileType
        state[action.index] = fileProp
      }
      return [...state]
    }
    case 'PROGRESS': {
      if (action.index !== undefined && action.index >= 0) {
        const fileProp = state[action.index]
        fileProp.progress = action.progress ?? 1
        state[action.index] = fileProp
      }
      return [...state]
    }
    case 'REMOVE': {
      if (action.index !== undefined && action.index >= 0) {
        const newState = [...state]
        newState.splice(action.index, 1)
        return [...newState]
      }
      return [...state]
    }
    case 'REMOVE_BY_ID': {
      if (action.id) {
        const newState = state.filter((upload) => upload.upload?.id !== action.id)
        return [...newState]
      }
      return [...state]
    }
    case 'UPDATE_FILE_BY_ID': {
      if (action.id) {
        const newState = [
          ...state.map((u) =>
            u.upload?.id == action.id
              ? {
                  ...u,
                  fileType: action.payload?.fileType ?? u.fileType,
                  description: action.payload?.description ?? u.description,
                }
              : u,
          ),
        ]
        return [...newState]
      }
      return [...state]
    }
    default:
      throw new Error(`No case for type ${action.type} found in uploadsReducer.`)
  }
}
