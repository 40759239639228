import { Node, Survey, Response } from '../types/surveyTypes'

export const SET_SURVEY = 'SET_SURVEY'
export const SET_QUESTION_INPUT = 'SET_QUESTION_INPUT'
export const SET_SUBQUESTION_INPUT = 'SET_SUBQUESTION_INPUT'
export const SET_INPUT_RESPONSE = 'SET_INPUT_RESPONSE'
export const SET_SUBQUESTION_INPUT_RESPONSE = 'SET_SUBQUESTION_INPUT_RESPONSE'

export interface SetInputPayload {
  tierId: number
  sectionId: number
  questionId: number
  inputId: number
  value: string
}

export interface SetSubquestionInputPayload extends SetInputPayload {
  subquestionId: number
}

export interface SetInputResponsePayload {
  tierId: number
  sectionId: number
  questionId: number
  response: Response
}

export interface SetSubquestionInputResponsePayload extends SetInputResponsePayload {
  subquestionId: number
}

interface ActionProps {
  type: string
  payload:
    | Survey
    | SetInputPayload
    | SetInputResponsePayload
    | SetSubquestionInputPayload
    | SetSubquestionInputResponsePayload
}

interface StateProps {
  survey: Survey
}

export const initialState: StateProps = {
  survey: {
    id: 0,
    name: '',
    description: '',
    node_id: 0,
    locked_at: null,
    submitted_at: null,
    ends_at: null,
    starts_at: null,
    root: {
      id: 0,
      title: '',
      description: '',
      progress: 0,
      node_id: 0,
      locked_at: null,
      inputs: null,
      children: null,
    },
  },
}

const getNode = (parentNode: Node, nodeId: number) => {
  let node = undefined
  if (parentNode.children?.length) {
    node = parentNode.children.find((node) => node.id == nodeId)
  }
  return node
}

const getInputParents = (root: Node, tierId: number, sectionId: number, questionId: number) => {
  const tier = getNode(root, tierId)
  if (tier && tier.children) {
    const section = getNode(tier, sectionId)
    if (section && section.children) {
      const question = getNode(section, questionId)
      if (question) {
        return { tier, section, question }
      }
    }
  }
  return {}
}

const surveyReducer = (state: StateProps, action: ActionProps): StateProps => {
  const { type, payload } = action

  switch (type) {
    case SET_SURVEY:
      return {
        ...state,
        survey: payload as Survey,
      }
    case SET_QUESTION_INPUT: {
      const { tierId, sectionId, questionId, inputId, value } = payload as SetInputPayload
      const { tier, section, question } = getInputParents(state.survey.root, tierId, sectionId, questionId)
      if (tier && section && section.children && question && tier.children && question.inputs) {
        const input = question.inputs?.find((input) => input.id == inputId)
        if (input && (!input.response || input.response.entered_as != value)) {
          input.response = input.response
            ? { ...input.response, entered_as: value, prev_value: input.response.entered_as }
            : { entered_as: value }
          question.inputs = [...question.inputs.map((i) => (i.id == inputId ? { ...input } : i))]
          section.children = [...section.children.map((q) => (q.id == questionId ? { ...question } : q))]
          tier.children = [...tier.children.map((s) => (s.id == sectionId ? { ...section } : s))]
        }
      }
      const tiers =
        state.survey.root.children && tier
          ? [...state.survey.root.children.map((t) => (t.id == tierId ? { ...tier } : t))]
          : null
      return {
        ...state,
        survey: { ...state.survey, root: { ...state.survey.root, children: tiers } },
      }
    }
    case SET_SUBQUESTION_INPUT: {
      const { tierId, sectionId, questionId, subquestionId, inputId, value } = payload as SetSubquestionInputPayload
      const { tier, section, question } = getInputParents(state.survey.root, tierId, sectionId, questionId)
      if (tier && section && section.children && question && tier.children && question.children) {
        const subquestion = getNode(question, subquestionId)
        if (subquestion && subquestion.inputs) {
          const input = subquestion.inputs?.find((input) => input.id == inputId)
          if (input && (!input.response || input.response.entered_as != value)) {
            input.response = input.response
              ? { ...input.response, entered_as: value, prev_value: input.response.entered_as }
              : { entered_as: value }
            subquestion.inputs = [...subquestion.inputs.map((i) => (i.id == inputId ? { ...input } : i))]
            question.children = [...question.children.map((sq) => (sq.id == subquestionId ? { ...subquestion } : sq))]
            section.children = [...section.children.map((q) => (q.id == questionId ? { ...question } : q))]
            tier.children = [...tier.children.map((s) => (s.id == sectionId ? { ...section } : s))]
          }
        }
      }
      const tiers =
        state.survey.root.children && tier
          ? [...state.survey.root.children.map((t) => (t.id == tierId ? { ...tier } : t))]
          : null
      return {
        ...state,
        survey: { ...state.survey, root: { ...state.survey.root, children: tiers } },
      }
    }
    case SET_INPUT_RESPONSE: {
      const { tierId, sectionId, questionId, response } = payload as SetInputResponsePayload
      const { tier, section, question } = getInputParents(state.survey.root, tierId, sectionId, questionId)
      if (tier && section && section.children && question && tier.children && question.inputs) {
        const input = question.inputs?.find((input) => input.id == response.input_id)
        if (input) {
          input.response = response
          question.inputs = [...question.inputs.map((i) => (i.id == response.input_id ? { ...input } : i))]
          section.children = [...section.children.map((q) => (q.id == questionId ? { ...question } : q))]
          tier.children = [...tier.children.map((s) => (s.id == sectionId ? { ...section } : s))]
        }
      }
      const tiers =
        state.survey.root.children && tier
          ? [...state.survey.root.children.map((t) => (t.id == tierId ? { ...tier } : t))]
          : null
      return {
        ...state,
        survey: { ...state.survey, root: { ...state.survey.root, children: tiers } },
      }
    }
    case SET_SUBQUESTION_INPUT_RESPONSE: {
      const { tierId, sectionId, questionId, subquestionId, response } = payload as SetSubquestionInputResponsePayload
      const { tier, section, question } = getInputParents(state.survey.root, tierId, sectionId, questionId)
      if (tier && section && section.children && question && tier.children && question.children) {
        const subquestion = getNode(question, subquestionId)
        if (subquestion && subquestion.inputs) {
          const input = subquestion.inputs?.find((input) => input.id == response.input_id)
          if (input) {
            input.response = response
            subquestion.inputs = [...subquestion.inputs.map((i) => (i.id == response.input_id ? { ...input } : i))]
            question.children = [...question.children.map((sq) => (sq.id == subquestionId ? { ...subquestion } : sq))]
            section.children = [...section.children.map((q) => (q.id == questionId ? { ...question } : q))]
            tier.children = [...tier.children.map((s) => (s.id == sectionId ? { ...section } : s))]
          }
        }
      }
      const tiers =
        state.survey.root.children && tier
          ? [...state.survey.root.children.map((t) => (t.id == tierId ? { ...tier } : t))]
          : null
      return {
        ...state,
        survey: { ...state.survey, root: { ...state.survey.root, children: tiers } },
      }
    }
    default:
      throw new Error(`No case for type ${type} found in surveyReducer.`)
  }
}

export default surveyReducer
