import { Box, CssBaseline } from '@mui/material'
import React from 'react'
import { Outlet } from 'react-router-dom'

const Layout = () => {
  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      <CssBaseline />
      <Outlet />
    </Box>
  )
}

export default Layout
