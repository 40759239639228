import { FormControlLabel, FormControlLabelProps, Radio, RadioGroup, RadioGroupProps, styled } from '@mui/material'
import React from 'react'
import { Control, FieldPath, FieldValues, useController } from 'react-hook-form'
import theme from '../theme'

interface RadioGroupControlProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends RadioGroupProps {
  name: TName
  control: Control<TFieldValues>
}

export const RadioGroupControl = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: RadioGroupControlProps<TFieldValues, TName>,
) => {
  const {
    field: { onChange, onBlur, name, value },
  } = useController({ name: props.name, control: props.control })

  return <RadioGroup {...props} name={name} value={value} onChange={onChange} onBlur={onBlur} />
}

export const RadioButton = styled(Radio)({
  color: theme.palette.primary.main,
  '&.Mui-checked': {
    color: theme.palette.primary.main,
  },
})

export const RadioFormControlLabel = (props: Omit<FormControlLabelProps, 'control'>) => {
  return <FormControlLabel {...props} control={<RadioButton />} />
}
