import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './i18n'
import { QueryClientProvider, QueryClient } from 'react-query'
import { disableReactDevTools } from '@fvilers/disable-react-devtools'
import { AuthProvider } from './providers/AuthProvider'
import { ThemeProvider } from '@mui/material'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import theme from './theme'
import { SurveyProvider } from './providers/SurveyProvider'
import { DialogProvider } from './providers/DialogProvider'
import { CookiesProvider } from 'react-cookie'

if (process.env.NODE_ENV === 'production') {
  disableReactDevTools()
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <CookiesProvider>
          <AuthProvider>
            <DialogProvider>
              <SurveyProvider>
                <App />
              </SurveyProvider>
              <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} autoClose={5000} />
            </DialogProvider>
          </AuthProvider>
        </CookiesProvider>
      </QueryClientProvider>
    </ThemeProvider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
