import React from 'react'
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  styled,
  DialogTitle,
  Typography,
  Box,
} from '@mui/material'
import theme from '../theme'
import { faQuestionCircle, faWarning } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'

const DialogPrimaryButton = styled(Button)({
  color: theme.palette.primary.main,
  textTransform: 'uppercase',
  fontWeight: 400,
  backgroundColor: 'none',
  border: 'none',
  fontSize: '16px',
  '&:hover': {
    color: theme.white,
    backgroundColor: theme.palette.primary.main,
  },
})

const DialogSecondaryButton = styled(DialogPrimaryButton)({
  marginRight: '20px',
  color: theme.actionDisabled,
  '&:hover': {
    color: theme.white,
    backgroundColor: theme.actionDisabled,
  },
})

const BaseDialog = styled(Dialog)({
  '& .MuiPaper-root': {
    backgroundColor: theme.white,
    minWidth: '600px',
    boxShadow: `0px 0px 6px 0px ${theme.palette.grey}`,
  },
})

interface StyledDialogProps {
  isOpen?: boolean
  isWarning?: boolean
  isHelp?: boolean
  email?: string
  children: string | JSX.Element | undefined
  resolveDialog: () => void
  rejectDialog: () => void
  callback?: () => void
}

export const StyledDialog: React.FC<StyledDialogProps> = ({
  isOpen: open,
  isWarning,
  isHelp,
  email,
  children,
  resolveDialog,
  rejectDialog,
  callback,
}) => {
  const { t } = useTranslation(['common'])

  const resolveHandler = () => {
    if (callback) callback()
    resolveDialog()
  }

  const rejectHandler = () => {
    rejectDialog()
  }

  return (
    <BaseDialog open={open ?? false} onClose={rejectHandler} onAbort={rejectHandler}>
      <DialogTitle sx={{ padding: '30px 30px 20px 40px', borderBottom: `1px solid ${theme.lightGrey}` }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {isHelp ? (
            <>
              <FontAwesomeIcon icon={faQuestionCircle} size={'2x'} color={theme.palette.primary.main} />
              <Typography sx={{ marginLeft: '20px' }} variant={'h5'} color={theme.palette.primary.main}>
                {t('validation.help')}
              </Typography>
            </>
          ) : (
            <>
              <FontAwesomeIcon icon={faWarning} size={'2x'} color={theme.palette.warning.main} />
              <Typography sx={{ marginLeft: '20px' }} variant={'h5'} color={theme.palette.warning.main}>
                {t('validation.warning')}
              </Typography>
            </>
          )}
        </Box>
      </DialogTitle>
      <DialogContent sx={{ paddingX: '40px', marginTop: '25px', marginBottom: '25px' }}>
        <DialogContentText variant={'h5'} color={theme.black}>
          {children}
        </DialogContentText>
      </DialogContent>
      <DialogActions disableSpacing sx={{ padding: '0 30px 20px' }}>
        <DialogSecondaryButton onClick={rejectHandler}>{t('buttons.cancel')}</DialogSecondaryButton>
        <DialogPrimaryButton onClick={resolveHandler} href={isHelp && email ? `mailto:${email}` : undefined}>
          {isWarning || isHelp ? t('buttons.continue') : t('buttons.confirm')}
        </DialogPrimaryButton>
      </DialogActions>
    </BaseDialog>
  )
}
