import { Box, styled, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'

const TextBox = styled(Box)({
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

interface TruncatedTextProps {
  title: string
  lineClamp?: number | undefined
  children: React.ReactElement
}

const TruncatedText = ({ title, lineClamp, children }: TruncatedTextProps) => {
  const [isEllipsisActive, setIsEllipsisActive] = useState(false)
  const titleRef = React.createRef<HTMLDivElement>()

  useEffect(() => {
    const element = titleRef.current

    setIsEllipsisActive(
      element ? element.offsetWidth < element.scrollWidth || element.offsetHeight < element.scrollHeight : false,
    )
  }, [])
  return (
    <Tooltip title={title} disableHoverListener={!isEllipsisActive}>
      <TextBox sx={{ WebkitLineClamp: `${lineClamp}` || '1' }} ref={titleRef}>
        {children}
      </TextBox>
    </Tooltip>
  )
}

export default TruncatedText
