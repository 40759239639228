import { faHouse } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, styled, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import { useNeedHeightAdjustments } from '../../hooks/useNeedHeightAdjustments'
import { useNumberParam } from '../../hooks/useNumberParam'
import useSurvey from '../../hooks/useSurvey'
import theme from '../../theme'
import { Role } from '../../types/authTypes'
import { Node } from '../../types/surveyTypes'
import { ViewOnlyBadge } from '../../_components/Badge'
import { PrimaryButton, PrimaryIconButton, SecondaryButton } from '../../_components/Buttons'
import { ScrollableContainer } from '../../_components/ScrollableContainer'
import { CenteredRadarSpinner } from '../../_components/Spinner'
import QuestionInfoCard from './components/QuestionInfoCard'

const BadgeContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'end',
  marginTop: '-40px',
})

const SectionInfo = styled(Box)({
  borderTop: `0.5px solid ${theme.palette.divider}`,
  padding: '20px 25px 0 0',
  marginRight: '15px',
  maxWidth: '950px',
})

const NavigationFooter = styled(Box)({
  display: 'flex',
  width: '100%',
  left: 0,
  flexDirection: 'row',
  marginTop: '40px',
  marginBottom: '30px',
})

const LeftBox = styled(Box)({
  [theme.breakpoints.down(740)]: {
    display: 'flex',
    alignItems: 'center',
  },
})

const RightBox = styled(Box)({
  [theme.breakpoints.down(740)]: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column-reverse',
  },
})

const TierSelection = () => {
  const { t } = useTranslation(['dashboard', 'common'])
  const needHeightAdjustments = useNeedHeightAdjustments()
  const navigate = useNavigate()
  const { number } = useParams()
  const tierNumber = useNumberParam(number)
  const { isUserInRole } = useAuth()
  const { survey } = useSurvey()
  const [tier, setTier] = useState<Node>()

  useEffect(() => {
    if (tierNumber && survey.root.children) {
      if (survey.submitted_at) navigate('/')
      if (tierNumber > survey.root.children.length) navigate('/notFound')
      const index = tierNumber - 1
      const selectedTier = survey.root.children[index]
      setTier(selectedTier)
    }
  }, [tierNumber, survey])

  const handlePrevSectionClick = () => {
    if (tierNumber) navigate(`/tier/${tierNumber - 1}`)
  }

  const handleNextSectionClick = () => {
    if (tierNumber) navigate(`/tier/${tierNumber + 1}`)
  }

  const isNextSectionDisabled = survey.root.children && tierNumber ? survey.root.children.length <= tierNumber : true
  const isPrevSectionDisabled = tierNumber ? tierNumber === 1 : true

  return (
    <>
      {isUserInRole(Role.Viewer) && (
        <BadgeContainer>
          <ViewOnlyBadge />
        </BadgeContainer>
      )}
      {!tier?.children && <CenteredRadarSpinner />}
      {tier?.children && (
        <>
          <Typography variant="overline" sx={{ display: 'flex', marginBottom: '10px' }}>
            {t('common:tier')} {tierNumber}
          </Typography>
          <Typography variant="h4" sx={{ marginBottom: '5px' }}>
            {tier?.title}
          </Typography>
          <Typography variant="caption" sx={{ letterSpacing: '0.4px' }}>
            {t('click_to_answer')}
          </Typography>
          <Box maxWidth="990px" mt="10px" mb="40px">
            <ScrollableContainer height={`calc(100vh - ${needHeightAdjustments ? 450 : 530}px)`} mb={'40px'}>
              {tierNumber &&
                tier.children.map((section, sectionIndex) => {
                  const sectionNumber = sectionIndex + 1
                  return (
                    <SectionInfo key={`section-${sectionNumber}`}>
                      <Typography variant="h6">
                        {tierNumber}.{sectionNumber} {section.title}
                      </Typography>
                      {section.children?.map((question, questionIndex) => (
                        <QuestionInfoCard
                          key={`question-${sectionNumber}-${questionIndex}`}
                          tierNumber={tierNumber}
                          sectionNumber={sectionNumber}
                          questionIndex={questionIndex}
                          question={question}
                        />
                      ))}
                    </SectionInfo>
                  )
                })}
            </ScrollableContainer>
            <NavigationFooter
              sx={{
                bottom: needHeightAdjustments ? '36px' : '60px',
                justifyContent: 'space-between',
              }}
            >
              <LeftBox>
                <PrimaryIconButton variant="contained" sx={{ marginBottom: '18px' }} onClick={() => navigate('/')}>
                  <FontAwesomeIcon icon={faHouse} size="lg" />
                </PrimaryIconButton>
              </LeftBox>
              <RightBox>
                <SecondaryButton
                  variant="outlined"
                  sx={{ marginRight: '10px' }}
                  disabled={isPrevSectionDisabled}
                  onClick={handlePrevSectionClick}
                >
                  {t('buttons.prev_tier')}
                </SecondaryButton>
                <PrimaryButton variant="contained" disabled={isNextSectionDisabled} onClick={handleNextSectionClick}>
                  {t('buttons.next_tier')}
                </PrimaryButton>
              </RightBox>
            </NavigationFooter>
          </Box>
        </>
      )}
    </>
  )
}

export default TierSelection
