import { faChevronDown, faHouse, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Collapse, Grid, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import { useWindowSize } from '../../hooks/useWindowSize'
import { getOwnOrgUsers, getEditUser } from '../../services/userApi'
import theme from '../../theme'
import { GetOwnOrgUsersResponse, GetOwnOrgUsersResponseBody, User } from '../../types/userTypes'
import { PrimaryButton, PrimaryIconButton } from '../../_components/Buttons'
import { ScrollableContainer } from '../../_components/ScrollableContainer'
import { CenteredRadarSpinner } from '../../_components/Spinner'
import { AddUserForm } from './components/addUserForm'
import { EditUserForm } from './components/editUserForm'
import { UserPaper } from './components/userPaper'

const Users = () => {
  const { t } = useTranslation(['users'])
  const axiosPrivate = useAxiosPrivate()
  const under800Screen = useMediaQuery('(max-width:800px)')
  const { width, height } = useWindowSize()
  const [openState, setOpenState] = useState(false)
  const [userId, setUserId] = useState<string | undefined>(undefined)
  const usersContainerRef = React.createRef<HTMLDivElement>()
  const [isScrollVisible, setIsScrollVisible] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    const element = usersContainerRef.current
    setIsScrollVisible(element ? element.scrollHeight > element.clientHeight : false)
  }, [width, height])

  const { data: getOwnOrgUsersData, isFetching: isFetchingAllUsers } = useQuery<GetOwnOrgUsersResponse>({
    queryKey: 'GetOwnOrgUsers',
    queryFn: () => getOwnOrgUsers(axiosPrivate),
    onError: (error) => console.error(error),
  })

  const { data: getEditUserData, isFetching: isFetchingEditUser } = useQuery<GetOwnOrgUsersResponseBody>({
    queryKey: ['GetEditUser', userId],
    queryFn: async () => userId && getEditUser(axiosPrivate, userId),
    enabled: !!userId,
    onError: (error) => console.error(error),
  })

  const handleUserCallBack = async (user?: User) => {
    if (user) {
      setUserId(user.id)
      setOpenState(true)
    } else setOpenState(false)
  }

  const handleAddButton = () => {
    setUserId(undefined)
    if (!openState) usersContainerRef.current?.scrollTo({ top: 0, behavior: 'smooth' })
    setOpenState(!openState)
  }

  const handleCloseEditor = () => {
    setUserId(undefined)
    setOpenState(false)
  }

  return (
    <>
      {getOwnOrgUsersData && !isFetchingAllUsers ? (
        <Box sx={{ width: '100%', mt: '40px' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              p: isScrollVisible ? '0 0 0 10px' : '0 10px',
            }}
          >
            <Typography variant="h3">{t('isat_users')}</Typography>
            <PrimaryButton variant="contained" onClick={handleAddButton} sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', justifyContent: 'center', height: '17.5px', width: '17.5px' }}>
                {openState ? (
                  <FontAwesomeIcon size={'lg'} color={theme.white} icon={faChevronDown} />
                ) : (
                  <FontAwesomeIcon size={'lg'} color={theme.white} icon={faPlus} />
                )}
              </Box>
              <Box sx={{ marginLeft: '15px', lineHeight: '30px' }}>
                {!!!getEditUserData ? t('add_user') : t('edit_details')}
              </Box>
            </PrimaryButton>
          </Box>
          <ScrollableContainer
            height="calc(100vh - 280px)"
            minHeight="330px !important"
            sx={{ m: '40px 0', p: isScrollVisible ? '10px 20px 10px 10px' : '10px' }}
            ref={usersContainerRef}
          >
            <Collapse in={openState} sx={{ mb: openState ? '40px' : 0 }}>
              {userId ? (
                getEditUserData && !isFetchingEditUser ? (
                  <EditUserForm user={getEditUserData} closeEditor={handleCloseEditor} />
                ) : (
                  <CenteredRadarSpinner />
                )
              ) : (
                <AddUserForm closeEditor={handleCloseEditor} />
              )}
            </Collapse>
            <Grid container spacing={'20px'} overflow={'wrap'}>
              {getOwnOrgUsersData &&
                getOwnOrgUsersData.data.map((user) => (
                  <Grid key={user.id} item xs={12} sm={under800Screen ? 12 : 6} md={6} lg={4}>
                    <UserPaper callBack={handleUserCallBack} user={{ ...user }} />
                  </Grid>
                ))}
            </Grid>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                left: 0,
                flexDirection: 'row',
                marginTop: '40px',
                marginBottom: '30px',
              }}
            >
              <PrimaryIconButton variant="contained" onClick={() => navigate('/')}>
                <FontAwesomeIcon icon={faHouse} size="lg" />
              </PrimaryIconButton>
            </Box>
          </ScrollableContainer>
        </Box>
      ) : (
        <CenteredRadarSpinner />
      )}
    </>
  )
}

export default Users
