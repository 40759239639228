import React from 'react'
import { faLock, faUnlock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Typography } from '@mui/material'
import theme from '../theme'
import { useTranslation } from 'react-i18next'

interface LockedPagesProps {
  allQuestionsCount: number
  lockedQuestionsCount: number
}

export const LockedPages: React.FC<LockedPagesProps> = ({ allQuestionsCount, lockedQuestionsCount }) => {
  const { t } = useTranslation(['submission'])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
      }}
    >
      <FontAwesomeIcon
        icon={allQuestionsCount > 0 && lockedQuestionsCount === allQuestionsCount ? faLock : faUnlock}
        color={theme.complimentary}
        size="lg"
      />
      <Typography ml="5px" variant="caption" whiteSpace="nowrap">{`${lockedQuestionsCount}/${allQuestionsCount} ${t(
        'locked',
      )}`}</Typography>
    </Box>
  )
}
