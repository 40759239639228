import { Organisation, User } from './userTypes'
import { Role } from './authTypes'

export interface Survey {
  id: number
  name: string
  description: string
  subtype?: string
  node_id: number
  locked_at: Date | null
  submitted_at: Date | null
  ends_at: Date | null
  starts_at: Date | null
  root: Node
  organisation?: Organisation
}

export interface SurveyList {
  data: Survey[]
}

export interface Node {
  id: number
  title: string
  description: string
  subtype?: string
  progress: number
  type?: string
  node_id: number | null
  locked_at: Date | null
  inputs: Input[] | null
  children?: Node[] | null
}

export interface NodeList {
  data: Node[]
}

export interface InputOption {
  option_group_id: number
  handle: string
  title: string
  description?: string | null
  score?: number | null
}

export interface Input {
  id: number
  node_id: number
  type: InputType
  title: string
  description: string
  weighting: string
  position: number
  response?: Response | null
  metadata?: Metadata | null
  required: boolean
  restricted?: boolean
  options?: InputOption[]
}

export interface Metadata {
  values: { [key: string]: boolean }
  allow: {
    multiple: boolean
    extension: string[]
  }
  restrict?: { permission?: string[]; role?: string[] }
}

export enum InputType {
  SingleSelect = 'select-single',
  TextLong = 'text-long',
  FileUpload = 'file-upload',
  Boolean = 'boolean',
}

export interface Response {
  id?: number
  survey_id?: number
  input_id?: number
  user_id?: number
  entered_as: string
  prev_value?: string
  submitted?: number
  score?: string
  superseded_by?: string | null
  superseded_at?: Date | null
  locked_at?: Date | null
  locked_by_user_id?: string | null
  uploads?: Upload[] | null
}

export interface Upload {
  id: number
  filename: string
  description: string | null
  input_response_id: number
  original_filename: string
  extension: string
  file_type?: FileType | null
}

export enum FileType {
  InternalProcess = 1,
  Output = 2,
  ExternalEvidence = 3,
}

// File is from the API, Upload is from the user (not API)
export interface FileProps {
  progress?: number
  fileType: FileType | null
  isUploaded?: boolean
  description?: string | null
  file?: File
  upload?: Upload
  isNew?: boolean
  isFocussed?: boolean
}

export interface SubmitResponse {
  entered_as: string
  file?: File | null
}

export interface SubmitResponseRequest {
  surveyId: number
  inputId: number
  subquestionId?: number
  data: SubmitResponse
}

export interface SubmitResponseResponse {
  subquestionId?: number
  response: Response
}

export interface DeleteUploadRequest {
  surveyId: number
  inputId: number
  file_id: number
}

interface UpdateUploadFileType {
  file_id: number
  file_type: FileType
}

interface UpdateUploadDescription {
  file_id: number
  description: string
}

export interface UpdateUploadRequest {
  surveyId: number
  inputId: number
  data: UpdateUploadFileType | UpdateUploadDescription
}

export interface NodeRequest {
  surveyId: number
  nodeId: number
}

export interface DownloadRequest {
  id: number
  filename: string
}

export interface InputRequest {
  surveyId: number
  inputId: number
}

export interface SurveyEditor {
  id?: number
  survey_id: number
  node_id: number
  tier_id: number
  user_id: string
  first_name: string
  last_name: string
  last_active_at?: Date | null
}

export interface SurveyEditorResponse {
  data: SurveyEditor[]
}

export interface AssignSurveyRequest {
  node_id: number
  organisation_id: number
  starts_at: Date
  ends_at?: Date | null
}
