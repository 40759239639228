import { Input } from '../types/surveyTypes'

export const arrayHasDuplicates = (arr: Array<any>) => {
  return new Set(arr).size !== arr.length
}

export const sortInputs = (arr: Array<Input>) => {
  return arr.sort((x, y) => {
    if (x.position > y.position) {
      return 1
    }

    if (x.position < y.position) {
      return -1
    }
    return 0
  })
}
