import { axiosPrivate } from '../services/apiClient'
import { useEffect } from 'react'
import useAuth from './useAuth'
import { useNavigate } from 'react-router-dom'
import { useErrorToast } from './useToast'
import { useTranslation } from 'react-i18next'

const useAxiosPrivate = () => {
  const { t } = useTranslation(['common'])
  const context = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    const requestIntercept = axiosPrivate.interceptors.request.use(
      (config) => {
        if (config && config.headers && !config.headers['Authorization']) {
          config.headers['Authorization'] = `Bearer ${context?.token}`
        }
        return config
      },
      (error) => Promise.reject(error),
    )

    const responseIntercept = axiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error?.response) {
          if (error.response.status === 401) {
            navigate('/login')
          }
          if (error.response.status === 403) {
            useErrorToast(t('validation.forbidden_action'))
          }
        }
        return Promise.reject(error)
      },
    )

    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept)
      axiosPrivate.interceptors.response.eject(responseIntercept)
    }
  }, [context])

  return axiosPrivate
}

export default useAxiosPrivate
