import React from 'react'
import { OutlinedTextFieldProps, styled, TextField } from '@mui/material'
import { Control, FieldPath, FieldValues, useController } from 'react-hook-form'
import theme from '../theme'

export const StyledTextField = styled(TextField)({
  width: '100%',
  '&:focus': {
    borderColor: theme.palette.primary.main,
  },
  '& .MuiOutlinedInput-input': {
    fontSize: '12px',
    [theme.breakpoints.up(1600)]: {
      fontSize: '14px',
    },
  },
})

interface MultilineTextFieldControlProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<OutlinedTextFieldProps, 'variant'> {
  readOnly?: boolean
  errorText?: string | undefined
  name: TName
  control: Control<TFieldValues>
}

export const MultilineTextFieldControl = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: MultilineTextFieldControlProps<TFieldValues, TName>,
) => {
  const {
    field: { onChange, onBlur, name, value, ref },
  } = useController({ name: props.name, control: props.control })

  return (
    <StyledTextField
      {...props}
      variant={'outlined'}
      multiline
      InputProps={{ ...props.InputProps, readOnly: props.readOnly ?? false }}
      name={name}
      value={value}
      inputRef={ref}
      error={!!props.errorText}
      helperText={props.errorText}
      onChange={onChange}
      onBlur={onBlur}
    />
  )
}

interface MultilineTextFieldProps extends Omit<OutlinedTextFieldProps, 'variant'> {
  readOnly?: boolean
  errorText?: string | undefined
}

export const MultilineTextField = (props: MultilineTextFieldProps) => {
  return (
    <StyledTextField
      {...props}
      variant={'outlined'}
      multiline
      InputProps={{ ...props.InputProps, readOnly: props.readOnly ?? false }}
      error={!!props.errorText}
      helperText={props.errorText}
    />
  )
}
