import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyledDialog } from '../_components/Dialog'

export interface UseDialogProps {
  openDialog: (message: string) => Promise<unknown>
  openDeleteDialog: (name: string, callback: () => void) => Promise<void>
  openWarningDialog: (message: string, callback: () => void) => Promise<void>
  openHelpDialog: (message: string, email: string) => Promise<void>
  setDialog: (message: string) => void
  resolveDialog: () => void
  rejectDialog: () => void
  errorDialog: (message: string) => Promise<void>
  children: string | JSX.Element | undefined
  isOpen: boolean
  isWarning: boolean
  isHelp: boolean
  email: string
}

const useDialog = (): UseDialogProps => {
  const [childrenState, setChildrenState] = useState<string | JSX.Element>()
  const [openState, setOpenState] = useState(false)
  const [isWarningState, setIsWarningState] = useState(false)
  const [isHelpState, setIsHelpState] = useState(false)
  const [emailButtonState, setEmailButtonState] = useState('')
  const [promiseFuncs, setPromiseFuncs] = useState<(() => void)[]>()
  const { t } = useTranslation(['common'])

  const openDialog = async (message: string | JSX.Element) => {
    return new Promise((resolve, reject) => {
      setChildrenState(message)
      setOpenState(true)
      setPromiseFuncs([resolve, reject])
    })
  }

  const setDialog = (message: string) => {
    setChildrenState(message)
  }

  const resolveDialog = () => {
    setOpenState(false)
    if (promiseFuncs) promiseFuncs[0]()
  }

  const rejectDialog = () => {
    setOpenState(false)
    if (promiseFuncs) promiseFuncs[1]()
  }

  const openDeleteDialog = async (name: string, callback: () => void) => {
    setIsWarningState(false)
    setIsHelpState(false)
    openDialog(`${t('delete_dialog')} ${name}?`).then(async () => {
      callback()
    })
  }

  const openWarningDialog = async (message: string, callback: () => void) => {
    setIsWarningState(true)
    setIsHelpState(false)
    openDialog(message).then(async () => {
      callback()
    })
  }

  const openHelpDialog = async (message: string, email: string) => {
    setIsWarningState(false)
    setIsHelpState(true)
    setEmailButtonState(email)
    await openDialog(message)
  }

  const errorDialog = async (message: string) => {
    if (openState) {
      setDialog(message)
    } else {
      await openDialog(message)
    }
  }

  return {
    openDialog,
    openDeleteDialog,
    openWarningDialog,
    openHelpDialog,
    setDialog,
    resolveDialog,
    rejectDialog,
    errorDialog,
    children: childrenState,
    isOpen: openState,
    isWarning: isWarningState,
    isHelp: isHelpState,
    email: emailButtonState,
  }
}

interface DialogProviderProps {
  children: React.ReactNode
}

export const DialogStateContext = React.createContext<UseDialogProps | undefined>(undefined)

export const DialogProvider = (props: DialogProviderProps): JSX.Element => {
  const dialog = useDialog()
  return (
    <DialogStateContext.Provider value={dialog}>
      <StyledDialog {...dialog} />
      {props.children}
    </DialogStateContext.Provider>
  )
}
