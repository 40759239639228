import React, { SyntheticEvent, useEffect, useState } from 'react'
import { UserUpdateListener } from './UserAdminPanel'
import { Organisation, UpdateUserRequest, User } from '../../../types/userTypes'
import { Role, UserRole } from '../../../types/authTypes'
import { useForm } from 'react-hook-form'
import { UpdateUserSchema } from '../../../validationSchemas/userSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash, faRemove, faUpload } from '@fortawesome/free-solid-svg-icons'
import { useMutation, useQueryClient } from 'react-query'
import { ExtendedAxiosError } from '../../../types/commonTypes'
import { axiosPrivate } from '../../../services/apiClient'
import { postAddUser, postEditUser } from '../../../services/userApi'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { useTranslation } from 'react-i18next'
import { useErrorToast, useSuccessToast } from '../../../hooks/useToast'
import { Box, FormControl, Grid, styled, TextField, IconButton, MenuItem, Checkbox, Typography } from '@mui/material'
import { ImageHolder, HiddenInput, MissingImage, ImageMimeType, AdminFormControlLabel } from './Common'
import { PrimaryButton, SecondaryButton } from '../../../_components/Buttons'

interface UserEditFormProps {
  user?: User | null
  organisations?: Organisation[] | undefined
  roles?: UserRole[] | undefined
  updateListener?: UserUpdateListener
  closeForm: () => void
}

const UserEditBox = styled(Box)({
  width: '100%',
})

export const UserEditForm: React.FC<UserEditFormProps> = ({
  user,
  roles,
  organisations,
  updateListener,
  closeForm,
}) => {
  const { t } = useTranslation(['admin', 'common'])
  const axiosPrivate = useAxiosPrivate()
  const queryClient = useQueryClient()

  const {
    register,
    formState: { errors, isSubmitting, isDirty },
    handleSubmit,
    clearErrors,
    setError,
    setValue,
    getValues,
  } = useForm<UpdateUserRequest>({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues: { organisation_id: user?.organisation?.id, avatar: user?.avatar, role_ids: [] },
  })

  const { mutate: updateUser, isLoading: isUpdatingUser } = useMutation<User, ExtendedAxiosError, UpdateUserRequest>({
    mutationKey: 'UpdateUser',
    mutationFn: async (u) => {
      clearErrors()
      return user && user.id ? await postEditUser(axiosPrivate, user.id, u) : await postAddUser(axiosPrivate, u)
    },
    onSuccess: (organisation) => {
      queryClient.invalidateQueries('GetAllOrganisations')
      useSuccessToast(
        t(organisation && organisation.id ? 'messages.organisation_updated' : 'messages.organisation_created'),
      )
      if (organisation && updateListener) updateListener.updated(organisation)
      closeForm()
    },
    onError: (error) => {
      const errors = error.response?.data?.errors
      if (errors) {
        Object.keys(errors).forEach((field: string) => {
          const message = errors[field].join('; ')
          setError(field as keyof UpdateUserRequest, {
            type: 'server',
            message: message,
          })
          useErrorToast(message)
        })
      } else {
        useErrorToast(t(user && user.id ? 'messages.user_update_error' : 'messages.user_creation_error'))
      }
      console.warn('Failed to write organisation', error)
      queryClient.invalidateQueries('GetAllOrganisations')
    },
  })

  const handleUserSubmit = (formData: UpdateUserRequest) => {
    if (user?.avatar && avatarUrl != user.avatar) {
      formData.avatar = avatarUrl ?? ''
    } else {
      formData.avatar = avatarUrl
    }

    updateUser(formData)
  }

  const [organisationId, setOrganisationId] = useState(`${user?.organisation?.id}`)
  const [roleId, setRoleId] = useState(`${user?.role?.id}`)
  const [avatar, setAvatar] = useState<File | null>(null)
  const [avatarUrl, setAvatarUrl] = useState<string>('')

  const formRegistration = {
    first_name: register('first_name', { value: user?.first_name ?? '' }),
    last_name: register('last_name', { value: user?.last_name ?? '' }),
    job_title: register('job_title', { value: user?.job_title }),
    avatar: register('avatar', {
      setValueAs: (v) => {
        return v
      },
    }),
    organisation_id: register('organisation_id', { value: user?.organisation?.id }),
    role_id: register('role_id', { value: user?.role?.id }),
  }

  const handleAvatarSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target?.files?.length ? e.target.files[0] : null
    if (!file) return
    if (!file.type.match(ImageMimeType)) {
      useErrorToast(t('not_valid_image_type'))
      return
    }
    setAvatar(file)
  }

  useEffect(() => {
    let fileReader: FileReader,
      cancelled = false

    if (avatar) {
      fileReader = new FileReader()
      fileReader.onload = (e) => {
        const result = e.target?.result
        if (result && !cancelled) {
          setAvatarUrl(result as string)
          setValue('avatar', result as string)
        }
      }
      fileReader.readAsDataURL(avatar)

      return () => {
        cancelled = true
        if (fileReader && fileReader.readyState === 1) fileReader.abort()
      }
    }
  }, [avatar])

  useEffect(() => {
    const orgId = `${user?.organisation?.id ?? ''}`
    setOrganisationId(orgId)
    setValue('organisation_id', orgId)

    setAvatarUrl(user?.avatar ?? '')
    clearErrors()
  }, [user])

  return (
    <UserEditBox>
      <form onSubmit={handleSubmit(handleUserSubmit, (error) => console.error(error))}>
        <Grid container spacing={'20px'} direction={'row'} justifyContent={'flex-start'} sx={{ paddingTop: '20px' }}>
          <Grid item xs={8}>
            <Grid
              container
              spacing={'20px'}
              direction={'row'}
              justifyContent={'flex-start'}
              alignItems={'center'}
              sx={{ paddingTop: '20px' }}
            >
              <Grid item xs={6}>
                <FormControl variant="standard" sx={{ width: '100%' }} error={!!errors.first_name}>
                  <TextField
                    {...formRegistration.first_name}
                    error={!!errors['first_name']}
                    label={t('first_name')}
                    title={errors.first_name?.message ?? t('first_name')}
                  ></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="standard" sx={{ width: '100%' }} error={!!errors.last_name}>
                  <TextField
                    {...formRegistration.last_name}
                    error={!!errors['last_name']}
                    label={t('last_name')}
                    title={errors.last_name?.message ?? t('last_name')}
                  ></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="standard" sx={{ width: '100%' }} error={!!errors.job_title}>
                  <TextField
                    {...formRegistration.job_title}
                    error={!!errors['job_title']}
                    label={t('job_title')}
                    title={errors.job_title?.message ?? t('job_title')}
                  ></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="standard" sx={{ width: '100%' }} error={!!errors.organisation_id}>
                  <TextField
                    select
                    {...formRegistration.organisation_id}
                    value={organisationId}
                    error={!!errors['organisation_id']}
                    label={t('organisation')}
                    title={errors.organisation_id?.message ?? t('organisation')}
                  >
                    {organisations?.map((org: Organisation) => (
                      <MenuItem
                        key={org.id}
                        value={`${org.id}`}
                        title={org.name}
                        selected={org.id == user?.organisation?.id}
                      >
                        {org.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid
              container
              direction={'row'}
              justifyContent={'flex-start'}
              alignItems={'center'}
              spacing={'20px'}
              sx={{ paddingTop: '70px' }}
            >
              <Grid item xs={12}>
                <ImageHolder>
                  {avatarUrl ? (
                    <img src={avatarUrl.indexOf('/') > -1 ? avatarUrl : `/images/user/${avatarUrl}`} alt={avatarUrl} />
                  ) : (
                    <MissingImage sx={{ marginTop: '48px' }}>No avatar</MissingImage>
                  )}
                  <IconButton
                    aria-label="select organisation image"
                    role={undefined}
                    component="label"
                    sx={{
                      position: 'absolute',
                      left: '3px',
                      top: 'calc(50% - 22px)',
                      opacity: avatarUrl ? 0.2 : 1.0,
                      ':hover': { opacity: 1.0 },
                    }}
                  >
                    <FontAwesomeIcon icon={faUpload} size="lg" />
                    <HiddenInput
                      {...formRegistration.avatar}
                      type="file"
                      multiple={false}
                      id="user_avatar"
                      accept=".png, .jpg, .jpeg"
                      onChange={(e) => handleAvatarSelect(e)}
                    />
                  </IconButton>
                  {avatarUrl && (
                    <IconButton
                      aria-label="remove user avatar"
                      role={undefined}
                      sx={{
                        position: 'absolute',
                        right: '3px',
                        top: 'calc(50% - 22px)',
                        opacity: 0.2,
                        ':hover': { opacity: 1.0 },
                      }}
                      onClick={() => {
                        setValue('avatar', '')
                        setAvatarUrl('')
                      }}
                    >
                      <FontAwesomeIcon icon={faRemove} size="lg" />
                    </IconButton>
                  )}
                </ImageHolder>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container direction={'row'} justifyContent={'flex-start'}>
              <Grid item xs={12}>
                <FormControl variant="standard" sx={{ width: '100%', position: 'flex' }} error={!!errors.role_ids}>
                  <TextField
                    select
                    {...formRegistration.role_id}
                    value={roleId}
                    error={!!errors['role_id']}
                    label={t('role')}
                    title={errors.role_id?.message ?? t('role')}
                  >
                    {roles?.map((role: UserRole) => (
                      <MenuItem
                        key={role.id}
                        value={`${role.id}`}
                        title={role.name}
                        selected={role.id == user?.role?.id}
                      >
                        {role.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Box sx={{ display: 'inline-flex', marginTop: '20px' }}>
              <PrimaryButton
                sx={{ margin: '0 10px 10px 0' }}
                type="submit"
                variant="contained"
                // disabled={!isDirty}
              >
                {t('common:buttons.submit')}
              </PrimaryButton>
              <SecondaryButton onClick={closeForm}>{t('common:buttons.cancel')}</SecondaryButton>
            </Box>
          </Grid>
        </Grid>
      </form>
    </UserEditBox>
  )
}
