import { useContext, useDebugValue } from 'react'
import AuthContext from '../providers/AuthProvider'

const useAuth = () => {
  const context = useContext(AuthContext)

  if (context === undefined) {
    throw new Error('useAuth must be used within AuthContext')
  }

  useDebugValue(context.user, (user) => (user ? 'Logged In' : 'Logged Out'))
  return context
}

export default useAuth
