import { Box, CardActionArea, Grid, styled, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import useSurvey from '../../hooks/useSurvey'
import { axiosPrivate } from '../../services/apiClient'
import { fetchSurvey, fetchSurveyList } from '../../services/surveyApi'
import { Survey, SurveyList } from '../../types/surveyTypes'
import { CenteredRadarSpinner } from '../../_components/Spinner'
import { QuestionnairePaper } from './components/questionnairePaper'
import SurveyCard from './components/SurveyCard'
import { Role } from '../../types/authTypes'
import { ViewOnlyBadge } from '../../_components/Badge'
import { useWindowSize } from '../../hooks/useWindowSize'
import { useNeedHeightAdjustments } from '../../hooks/useNeedHeightAdjustments'
import { InfoCard, InfoCardContent } from '../../_components/cards/InfoCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faCalendar, faClipboardList, faCog, faUsers } from '@fortawesome/free-solid-svg-icons'
import theme from '../../theme'
import AdminTabPanel from './components/AdminTabPanel'
import { IconTab, IconTabs } from '../../_components/Tabs'
import { AdminIconTab, AdminIconTabs } from './components/AdminTabs'
import SurveyAdminPanel from './components/SurveyAdminPanel'
import { GridEventListener } from '@mui/x-data-grid'
import { Organisation, OrganisationList, UserList } from '../../types/userTypes'
import { fetchOrganisationList, getAllUsers, getOwnOrgUsers } from '../../services/userApi'
import OrganisationAdminPanel, { OrganisationUpdateListener } from './components/OrganisationAdminPanel'
import { AdminTypes } from '../../types/AdminTypes'
import { fetchAdminTypes } from '../../services/adminApi'
import UserAdminPanel from './components/UserAdminPanel'

export enum AdminMode {
  Surveys = 0,
  Organisations = 1,
  Users = 2,
}

const AdminInfoGrid = styled(Grid)({
  flexGrow: 1,
  flexDirection: 'column',
  textAlign: 'left',
  alignContent: 'start',
  padding: '20px 20px 20px 30px',
  height: '100%',
  margin: 0,
  [theme.breakpoints.up(1600)]: {
    alignContent: 'center',
    flexDirection: 'row',
  },
})

const AdminInfoGridItem = styled(Grid)({
  alignItems: 'left',
  display: 'inline-flex',
  flexDirection: 'row',
  [theme.breakpoints.up(1600)]: {
    display: 'flex',
    flexDirection: 'column',
  },
  '.MuiGrid-item': {
    padding: 0,
  },
})

const AdminActionArea = styled(CardActionArea)({
  color: theme.secondary1,
  backgroundColor: theme.white,
  maxWidth: '160px',
  padding: '20px',
  '@media (min-width:1600px)': {
    padding: '40px 20px',
  },
  borderRadius: '9px',
  height: '100%',
  textAlign: 'center',
})

type AdminParams = {
  area: keyof typeof AdminMode | undefined
  item: string | undefined
}

const AdminDashboard: React.FC = () => {
  const { area, item } = useParams<AdminParams>()
  const { user, token, isUserInRole } = useAuth()
  const { t } = useTranslation(['admin', 'common'])
  const { setSurvey } = useSurvey()
  const navigate = useNavigate()
  const { height } = useWindowSize()
  const needHeightAdjustments = useNeedHeightAdjustments(980)
  const lgScreen = useMediaQuery('(min-width:1600px)')
  const above1000Screen = useMediaQuery('(min-width:1000px)')
  const smScreen = useMediaQuery('(max-width:1080px)')
  const xsScreen = useMediaQuery('(max-width:740px)')
  const [mode, setMode] = useState<AdminMode>(area ? AdminMode[area] : AdminMode.Surveys)
  const [surveyList, setSurveyList] = useState<SurveyList>()
  const [organisationList, setOrganisationList] = useState<OrganisationList>()
  const [userList, setUserList] = useState<UserList>()

  const [seenTab, setSeenTab] = useState<boolean[]>([false, false, false])

  const { data: adminTypes } = useQuery<AdminTypes>(['adminTypes'], async () => await fetchAdminTypes(axiosPrivate), {
    enabled: !!token,
  })

  const getSurveyList = async () => {
    setSurveyList(await fetchSurveyList(axiosPrivate))
  }

  const getOrganisationList = async () => {
    setOrganisationList(await fetchOrganisationList(axiosPrivate))
  }

  const getUserList = async () => {
    setUserList(await getAllUsers(axiosPrivate))
  }

  const handleSurveysUpdate = () => {
    getSurveyList()
  }

  const handleOrganisationsUpdate = () => {
    getOrganisationList()
  }

  const handleOrganisationUpdate: OrganisationUpdateListener = {
    updated: (organisation: Organisation) => {
      if (!organisationList) return
      const i = organisationList.data.findIndex((o) => o.id == organisation.id)
      const data =
        i > -1
          ? organisation.deleted
            ? [...organisationList.data.slice(0, i), ...organisationList.data.slice(i + 1)]
            : [...organisationList.data.slice(0, i), organisation, ...organisationList.data.slice(i + 1)]
          : [...organisationList.data, organisation]
      setOrganisationList({
        data: data,
      })
    },
  }

  const paddingValue = useMemo(() => {
    let value = 0
    if (height && height <= 900) value = 40
    if (height && height > 900 && height < 1080) value = 60
    if (height && height >= 1080) value = 100
    return value
  }, [height && height < 900, height && height < 1080])

  useEffect(() => {
    if (!seenTab[mode]) {
      seenTab[mode] = true
      switch (mode) {
        case AdminMode.Organisations:
          getOrganisationList()
          break
        case AdminMode.Surveys:
          getSurveyList()
          break
        case AdminMode.Users:
          getUserList()
          break
      }
    }
    navigate(`/admin/${AdminMode[mode]}`)
  }, [mode])

  return (
    <Box
      sx={{
        paddingTop: `${paddingValue}px`,
        marginBottom: `${paddingValue}px`,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography variant="h4" sx={{ marginBottom: '20px' }}>
        {t('general')}
      </Typography>
      <Box mb={needHeightAdjustments ? '30px' : '60px'}>
        <AdminIconTabs variant="fullWidth" value={mode} onChange={(e, v) => setMode(v)}>
          <AdminIconTab
            icon={
              xsScreen ? (
                ''
              ) : (
                <FontAwesomeIcon
                  icon={faClipboardList}
                  size="2x"
                  width={lgScreen ? '17px' : '12px'}
                  height={lgScreen ? '21px' : '16px'}
                />
              )
            }
            label={t('surveys')}
            value={AdminMode.Surveys}
            smScreen={smScreen}
          />
          <AdminIconTab
            icon={
              xsScreen ? (
                ''
              ) : (
                <FontAwesomeIcon
                  icon={faBuilding}
                  size="2x"
                  width={lgScreen ? '17px' : '12px'}
                  height={lgScreen ? '21px' : '16px'}
                />
              )
            }
            label={t('organisations')}
            value={AdminMode.Organisations}
            smScreen={smScreen}
          />
          <AdminIconTab
            icon={
              xsScreen ? (
                ''
              ) : (
                <FontAwesomeIcon
                  icon={faUsers}
                  size="2x"
                  width={lgScreen ? '17px' : '12px'}
                  height={lgScreen ? '21px' : '16px'}
                />
              )
            }
            label={t('users')}
            value={AdminMode.Users}
            smScreen={smScreen}
          />
        </AdminIconTabs>
        {
          {
            [AdminMode.Organisations]: (
              <OrganisationAdminPanel
                organisationList={organisationList}
                adminTypes={adminTypes}
                handleOrganisationUpdate={handleOrganisationUpdate}
                handleOrganisationsUpdate={handleOrganisationsUpdate}
              />
            ),
            [AdminMode.Surveys]: (
              <SurveyAdminPanel
                surveyList={surveyList}
                organisationList={organisationList}
                handleSurveysUpdate={handleSurveysUpdate}
              />
            ),
            [AdminMode.Users]: <UserAdminPanel userList={userList} adminTypes={adminTypes} />,
          }[mode]
        }
      </Box>
    </Box>
  )
}

export default AdminDashboard
