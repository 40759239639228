import React from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import Dashboard from './pages/dashboard/dashboard'
import ForgotPassword from './pages/auth/forgotPassword'
import Login from './pages/auth/login'
import NotFound from './pages/errors/notFound'
import PasswordReset from './pages/auth/passwordReset'
import { Role } from './types/authTypes'
import Layout from './_components/layouts/Layout'
import LoginLayout from './_components/layouts/LoginLayout'
import RequireAuth from './_components/RequireAuth'
import MainLayout from './_components/layouts/MainLayout'
import Users from './pages/users/users'
import TierSelection from './pages/dashboard/tier'
import SurveyQuestions from './pages/surveyQuestions/surveyQuestions'
import SubmitReview from './pages/submission/review'
import Submit from './pages/submission/submit'
import AdminDashboard from './pages/admin/admin'
import SurveyView from './pages/admin/surveyView'
import { fetchSurvey } from './services/surveyApi'
import { axiosPrivate } from './services/apiClient'
import { useQuery } from 'react-query'
import { Survey } from './types/surveyTypes'
import useSurvey from './hooks/useSurvey'
import useAuth from './hooks/useAuth'
import ErrorLayout from './_components/layouts/ErrorLayout'
import Instructions from './pages/dashboard/instructions'
import SurveySubmittedSocketContainer from './_components/SurveySubmittedSocketContainer'
import SubmitNotification from './pages/submission/submitNotification'
import IdleTimeOutHandler from './_components/IdleTimeOutHandler'

const App = () => {
  const context = useAuth()
  const { setSurvey } = useSurvey()

  const {} = useQuery<Survey>(['survey'], async () => await fetchSurvey(axiosPrivate), {
    enabled: !!context.token,
    refetchOnMount: true,
    onSuccess: (responseData) => {
      setSurvey({ ...responseData })
    },
  })

  return (
    <Router>
      <IdleTimeOutHandler />
      <Routes>
        <Route element={<Layout />}>
          <Route element={<LoginLayout />}>
            <Route path="/login" element={<Login />} />
            <Route path="/password/email" element={<ForgotPassword />} />
            <Route path="/password/reset/:token" element={<PasswordReset />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[Role.Superadmin, Role.Admin, Role.Responder, Role.Viewer]} />}>
            <Route element={<MainLayout />}>
              <Route element={<RequireAuth allowedRoles={[Role.Superadmin]} />}>
                <Route path="/" element={<Navigate to="/survey" />} />
                <Route path="/survey" element={<Dashboard />} />
              </Route>
              <Route element={<RequireAuth allowedRoles={[Role.Admin, Role.Responder, Role.Viewer]} />}>
                <Route path="/" element={<Navigate to="/survey" />} />
                <Route path="/survey" element={<Dashboard />} />
                <Route element={<SurveySubmittedSocketContainer />}>
                  <Route path="/survey/:tier/:section/:question" element={<SurveyQuestions />} />
                </Route>
              </Route>
              <Route element={<RequireAuth allowedRoles={[Role.Admin]} />}>
                <Route path="/users" element={<Users />} />
              </Route>
              <Route element={<RequireAuth allowedRoles={[Role.Superadmin, Role.Admin]} />}>
                <Route path="/admin" element={<AdminDashboard />} />
                <Route path="/admin/survey/view" element={<SurveyView />} />
                <Route path="/admin/:area" element={<AdminDashboard />} />
                <Route path="/admin/:area/:item" element={<AdminDashboard />} />
              </Route>
            </Route>
            <Route element={<MainLayout sideImage footer={true} />}>
              <Route element={<RequireAuth allowedRoles={[Role.Admin, Role.Responder, Role.Viewer]} />}>
                <Route element={<SurveySubmittedSocketContainer />}>
                  <Route path="/tier/:number" element={<TierSelection />} />
                </Route>
              </Route>
            </Route>
            <Route element={<MainLayout sideImage footer={false} />}>
              <Route element={<RequireAuth allowedRoles={[Role.Admin]} />}>
                <Route element={<SurveySubmittedSocketContainer />}>
                  <Route path="/submit/review" element={<SubmitReview />} />
                </Route>
              </Route>
            </Route>
            <Route element={<MainLayout backgroundImage footer={false} />}>
              <Route element={<RequireAuth allowedRoles={[Role.Admin, Role.Responder, Role.Viewer]} />}>
                <Route path="/instructions" element={<Instructions />} />
              </Route>
            </Route>
            <Route element={<LoginLayout />}>
              <Route element={<RequireAuth allowedRoles={[Role.Admin]} />}>
                <Route path="/submit" element={<Submit />} />
              </Route>
              <Route element={<RequireAuth allowedRoles={[Role.Admin, Role.Responder, Role.Viewer]} />}>
                <Route path="/submit/notification" element={<SubmitNotification />} />
              </Route>
            </Route>
          </Route>

          <Route element={<ErrorLayout />}>
            <Route path="*" element={<NotFound />} />
          </Route>
        </Route>
      </Routes>
    </Router>
  )
}

export default App
