import React from 'react'
import { FormHelperText, MenuItem, Select, SelectProps, styled } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Control, FieldPath, FieldValues, useController } from 'react-hook-form'
import theme from '../theme'

const StyledSelect = styled(Select)({
  height: '100%',
  width: '100%',
  '& fieldset': {
    border: 'none',
    '&:hover': {
      border: 'none',
    },
  },
})

type SelectControlProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = SelectProps & {
  errorText?: string | undefined
  name: TName
  control: Control<TFieldValues>
}

export const SelectControl = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: SelectControlProps<TFieldValues, TName>,
) => {
  const {
    field: { onChange, onBlur, name, value, ref },
  } = useController({ name: props.name, control: props.control })

  return (
    <StyledSelect
      {...props}
      name={name}
      value={value}
      inputRef={ref}
      IconComponent={KeyboardArrowDownIcon}
      MenuProps={{
        style: {
          maxHeight: 300,
        },
      }}
      onChange={onChange}
      onBlur={onBlur}
    >
      {props.children}
    </StyledSelect>
  )
}

const StyledSimpleSelect = styled(Select)({
  width: '100%',
  height: '25px',
  fontSize: '12px',
  [theme.breakpoints.up(1600)]: {
    fontSize: '14px',
    height: '30px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.darkGreyBG,
  },
})

export const SelectOption = styled(MenuItem)({
  fontSize: '12px',
  [theme.breakpoints.up(1600)]: {
    fontSize: '14px',
  },
})

type SelectFormControlProps = SelectProps & {
  readOnly?: boolean
  errorText?: string | undefined
}

export const SelectFormControl = (props: SelectFormControlProps) => {
  return (
    <>
      <StyledSimpleSelect
        {...props}
        inputProps={{ readOnly: props.readOnly ?? false }}
        MenuProps={{
          style: {
            maxHeight: 300,
          },
        }}
        error={!!props.errorText}
      >
        {props.children}
      </StyledSimpleSelect>
      {!!props.errorText && <FormHelperText>{props.errorText}</FormHelperText>}
    </>
  )
}
