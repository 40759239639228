import { AxiosInstance } from 'axios'
import {
  DeleteUploadRequest,
  DownloadRequest,
  NodeRequest,
  NodeList,
  InputRequest,
  SubmitResponseRequest,
  Survey,
  SurveyList,
  UpdateUploadRequest,
  SurveyEditorResponse,
  AssignSurveyRequest,
} from '../types/surveyTypes'
import { AddUserRequest } from '../types/userTypes'
import { axiosPrivate } from './apiClient'
import { AdminTypes } from '../types/AdminTypes'

export const fetchSurvey = async (axiosPrivate: AxiosInstance, surveyId?: number): Promise<Survey> => {
  const response = await axiosPrivate.get<Survey>(surveyId !== undefined ? `/survey/${surveyId}` : '/survey')
  return response.data
}

export const assignSurvey = async (axiosPrivate: AxiosInstance, request: AssignSurveyRequest): Promise<Survey> => {
  const response = await axiosPrivate.post<Survey>('/survey/assign', request)
  return response.data
}

export const fetchSurveyList = async (axiosPrivate: AxiosInstance): Promise<SurveyList> => {
  const response = await axiosPrivate.get<SurveyList>('/survey/list')
  return response.data
}

export const fetchRootNodeTemplates = async (axiosPrivate: AxiosInstance): Promise<NodeList> => {
  const response = await axiosPrivate.get<NodeList>('/survey/templates')
  return response.data
}

export const submitInput = async (axiosPrivate: AxiosInstance, request: SubmitResponseRequest) => {
  const response = await axiosPrivate.post(`/survey/respond/${request.surveyId}/${request.inputId}`, request.data)
  return { response: response.data, subquestionId: request.subquestionId }
}

export const submitUpload = async (
  axiosPrivate: AxiosInstance,
  request: SubmitResponseRequest,
  progressCallBack: (progressEvent: ProgressEvent) => void,
) => {
  if (!!!request.data.file) return Promise.reject('No files uploaded')
  const formData = new FormData()
  formData.append('entered_as', 'upload')
  formData.append('file', request.data.file)
  const response = await axiosPrivate.post(`/survey/respond/${request.surveyId}/${request.inputId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent: ProgressEvent) => {
      progressCallBack && progressCallBack(progressEvent)
    },
  })
  return response.data
}

export const submitNode = async (axiosPrivate: AxiosInstance, request: NodeRequest) => {
  const response = await axiosPrivate.post(`/survey/submit-question/${request.surveyId}/${request.nodeId}`)
  return response.data
}

export const deleteUpload = async (axiosPrivate: AxiosInstance, request: DeleteUploadRequest) => {
  const response = await axiosPrivate.delete(`/survey/remove/${request.surveyId}/${request.inputId}`, {
    data: { file_id: request.file_id },
  })
  return response.data
}

export const updateUploadFileType = async (axiosPrivate: AxiosInstance, request: UpdateUploadRequest) => {
  const response = await axiosPrivate.post(
    `/survey/update-file-type/${request.surveyId}/${request.inputId}`,
    request.data,
  )
  return response.data
}

export const updateUploadDescription = async (axiosPrivate: AxiosInstance, request: UpdateUploadRequest) => {
  const response = await axiosPrivate.post(
    `/survey/update-file-description/${request.surveyId}/${request.inputId}`,
    request.data,
  )
  return response.data
}

export const lockUnlockSurvey = async (axiosPrivate: AxiosInstance, request: NodeRequest) => {
  const response = await axiosPrivate.post(`/survey/lock-unlock-node/${request.surveyId}/${request.nodeId}`)
  return response.data
}

export const downloadFile = async (axiosPrivate: AxiosInstance, request: DownloadRequest) => {
  await axiosPrivate.get(`/file/${request.id}`, { responseType: 'blob' }).then((response) => {
    const href = URL.createObjectURL(response.data)
    const link = document.createElement('a')
    link.href = href
    link.setAttribute('download', request.filename)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(href)
    return response.data
  })
}

export const editStarted = async (axiosPrivate: AxiosInstance, request: InputRequest) => {
  const response = await axiosPrivate.post(`/survey/edit-started/${request.surveyId}/${request.inputId}`)
  return response.data
}

export const editFinished = async (axiosPrivate: AxiosInstance, request: InputRequest) => {
  const response = await axiosPrivate.post(`/survey/edit-finished/${request.surveyId}/${request.inputId}`)
  return response.data
}

export const fetchEditorList = async (axiosPrivate: AxiosInstance, surveyId: number): Promise<SurveyEditorResponse> => {
  const response = await axiosPrivate.get<SurveyEditorResponse>(`/survey/get-survey-editor-list/${surveyId}`)
  return response.data
}

export const updateEditorList = async (axiosPrivate: AxiosInstance, request: InputRequest) => {
  const response = await axiosPrivate.post(`/survey/update-editor-list/${request.surveyId}/${request.inputId}`)
  return response.data
}

export const postAddSurvey = async (axiosPrivate: AxiosInstance, requestData: AssignSurveyRequest) => {
  const response = await axiosPrivate.post('/survey/add', requestData)
  return response.data
}
