import { faDownload, faFile } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, CircularProgress, IconButton, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import { useMutation } from 'react-query'
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate'
import { useErrorToast } from '../../../../hooks/useToast'
import { isAxiosError } from '../../../../services/axiosHelper'
import { downloadFile } from '../../../../services/surveyApi'
import theme from '../../../../theme'
import { DownloadRequest, FileProps } from '../../../../types/surveyTypes'

const UploadView = (upload: FileProps) => {
  const lgScreen = useMediaQuery('(min-width:1600px)')
  const axiosPrivate = useAxiosPrivate()

  const { mutateAsync: download, isLoading: uploadFileLoading } = useMutation(
    ['download'],
    async (request: DownloadRequest) => await downloadFile(axiosPrivate, request),
    {
      retry: false,
      onError: (error) => {
        if (isAxiosError(error) && error?.response?.statusText) {
          useErrorToast(error.response.statusText)
        }
      },
    },
  )

  const handleDownload = () => {
    if (upload.upload && !uploadFileLoading)
      download({ id: upload.upload?.id, filename: upload.upload.original_filename || upload.upload.filename })
  }

  return (
    <Box
      key={`upload-${upload.upload?.id}`}
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: lgScreen ? '25px' : '20px' }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <FontAwesomeIcon
          icon={faFile}
          color={theme.complimentary}
          width={lgScreen ? '13px' : '11px'}
          height={lgScreen ? '17px' : '15px'}
        />
        <Typography variant="subtitle2" ml="20px" mr="20px">
          {upload.upload?.original_filename || upload.upload?.filename}
        </Typography>
      </Box>
      <IconButton
        disableRipple
        sx={{ cursor: uploadFileLoading ? 'default' : 'pointer', color: theme.complimentary, p: 0, minHeight: '24px' }}
        onClick={handleDownload}
      >
        {uploadFileLoading && <CircularProgress color="inherit" size="16px" />}
        {!uploadFileLoading && <FontAwesomeIcon icon={faDownload} width="16px" height="16px" />}
      </IconButton>
    </Box>
  )
}

export default UploadView
