import Echo from 'laravel-echo'
import pusherJs from 'pusher-js'

declare global {
  interface Window {
    Echo: Echo | undefined
    Pusher: pusherJs
  }
}

window.Pusher = require('pusher-js')

export function createSocketConnection(token: string) {
  if (!window.Echo) {
    window.Echo = new Echo({
      broadcaster: 'pusher',
      cluster: 'mt1',
      key: process.env.REACT_APP_PUSHER_KEY,
      wsHost: process.env.REACT_APP_WS_HOST,
      authEndpoint: process.env.REACT_APP_WS_AUTH_ENDPOINT,
      auth: {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
      },
      wssPort: 443,
      disableStats: true,
      encrypted: true,
      forceTLS: true,
      enabledTransports: ['wss', 'ws'],
    })
  }
}

export function disconnectSockets() {
  window.Echo = undefined
}
