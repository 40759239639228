import { Box, Grid, styled, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { useNeedHeightAdjustments } from '../../../hooks/useNeedHeightAdjustments'
import useSurvey from '../../../hooks/useSurvey'
import { useErrorToast } from '../../../hooks/useToast'
import { sortInputs } from '../../../services/arrayHelper'
import { isAxiosError } from '../../../services/axiosHelper'
import { submitInput } from '../../../services/surveyApi'
import theme from '../../../theme'
import { Node, SubmitResponseRequest, InputType, SubmitResponseResponse } from '../../../types/surveyTypes'
import { ScrollableContainer } from '../../../_components/ScrollableContainer'
import QuestionInput from './components/QuestionInput'
import UploadView from './components/UploadView'
import { TabProps } from './TabProps'

const DocumentsBox = styled(Box)({
  borderLeft: `1px solid ${theme.centerBorder}`,
  padding: '20px 30px',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
})

interface QuestionsTabProps extends TabProps {
  questionNode: Node | undefined
  numeration: string
}

const AnswerQuestionsTab = ({
  tierId,
  sectionId,
  questionId,
  questionNode,
  numeration,
  questionTitleHeight,
  uploadsState,
  isCurrentlyEdited,
  isLocked,
  documentsLoadingCallback,
  startEditing,
  finishEditing,
}: QuestionsTabProps) => {
  const { t } = useTranslation(['surveyQuestions'])
  const axiosPrivate = useAxiosPrivate()
  const needHeightAdjustments = useNeedHeightAdjustments()
  const lgScreen = useMediaQuery('(min-width:1600px)')
  const [isScrollVisible, setIsScrollVisible] = useState(false)
  const questionsContainerRef = React.createRef<HTMLDivElement>()
  const { survey, setQuestionInput, setSubquestionInput, setResponse, setSubquestionResponse } = useSurvey()

  useEffect(() => {
    const element = questionsContainerRef.current
    setIsScrollVisible(element ? element.scrollHeight > element.clientHeight : false)
  }, [questionsContainerRef])

  const { mutate: submitResponse, isLoading } = useMutation<SubmitResponseResponse, unknown, SubmitResponseRequest>(
    async (request) => await submitInput(axiosPrivate, request),
    {
      onSuccess: (responseData) => {
        if (responseData) {
          const data = { tierId, sectionId, questionId, response: responseData.response }
          if (responseData.subquestionId) setSubquestionResponse({ ...data, subquestionId: responseData.subquestionId })
          else setResponse(data)
        }
      },
      onError: (error) => {
        if (isAxiosError(error) && error?.response?.statusText) {
          useErrorToast(error.response.statusText)
        }
      },
    },
  )

  const handleInputChange = (value: string, inputId: number) => {
    setQuestionInput({ tierId, sectionId, questionId, inputId, value })
  }

  const handleSubquestionInputChange = (value: string, subquestionId: number, inputId: number) => {
    setSubquestionInput({ tierId, sectionId, questionId, subquestionId, inputId, value })
  }

  const handleStartEditing = (inputId: number) => {
    startEditing({ surveyId: survey.id, inputId: inputId })
  }

  const handleFinishEditing = (inputId: number) => {
    finishEditing({ surveyId: survey.id, inputId: inputId })
  }

  const handleInputBlur = (value: string, inputId: number) => {
    submitResponse({ surveyId: survey.id, inputId, data: { entered_as: value } })
  }

  const handleSubQuestionInputBlur = (value: string, subquestionId: number, inputId: number) => {
    submitResponse({ surveyId: survey.id, inputId, subquestionId: subquestionId, data: { entered_as: value } })
  }

  const handleRadioChange = (value: string, inputId: number) => {
    setQuestionInput({ tierId, sectionId, questionId, inputId, value })
    submitResponse({ surveyId: survey.id, inputId, data: { entered_as: value } })
  }

  const handleSubQuestionRadioChange = (value: string, subquestionId: number, inputId: number) => {
    setSubquestionInput({ tierId, sectionId, questionId, subquestionId, inputId, value })
    submitResponse({ surveyId: survey.id, inputId, subquestionId: subquestionId, data: { entered_as: value } })
  }

  const hasUploadInput = questionNode?.inputs?.some((input) => input.type == InputType.FileUpload) || false

  const Question = ({ children }: { children: React.ReactNode }) => {
    return (
      <Typography
        variant={lgScreen ? 'h5' : 'h6'}
        sx={{ mb: lgScreen ? '30px' : '20px', paddingRight: hasUploadInput || isScrollVisible ? '40px' : 0 }}
      >
        {children}
      </Typography>
    )
  }

  useEffect(() => {
    if (isLoading) documentsLoadingCallback(true)
    else documentsLoadingCallback(false)
  }, [isLoading])

  return (
    <Box sx={{ position: 'relative', paddingTop: needHeightAdjustments ? '30px' : '50px' }}>
      <ScrollableContainer
        height={`calc(100vh - (${questionTitleHeight}px + 325px + ${needHeightAdjustments ? '0px' : '75px'}))`}
        ref={questionsContainerRef}
      >
        <Grid container>
          <Grid item md={hasUploadInput ? 8 : 12} sm={hasUploadInput ? 8 : 12}>
            <Question>
              {numeration} {questionNode?.title}
            </Question>
            {questionNode?.inputs &&
              sortInputs(questionNode.inputs)
                .filter((i) => i.type !== InputType.FileUpload)
                .map((input) => (
                  <QuestionInput
                    key={input.id}
                    input={input}
                    isLocked={isLocked}
                    isCurrentlyEdited={isCurrentlyEdited}
                    isScrollVisible={isScrollVisible}
                    hasUploadInput={hasUploadInput}
                    onInputChange={handleInputChange}
                    onStartEditing={handleStartEditing}
                    onFinishEditing={handleFinishEditing}
                    onInputBlur={handleInputBlur}
                    onRadioChange={handleRadioChange}
                  />
                ))}
            {questionNode?.children &&
              questionNode.children.map((subquestion, index) => (
                <Box key={subquestion.id}>
                  <Question>
                    {numeration}
                    {String.fromCharCode(index + 1 + 64).toLowerCase()} {subquestion.title}
                  </Question>
                  {subquestion?.inputs &&
                    sortInputs(subquestion.inputs)
                      .filter((i) => i.type !== InputType.FileUpload)
                      .map((input) => (
                        <QuestionInput
                          key={input.id}
                          input={input}
                          isLocked={isLocked}
                          isCurrentlyEdited={isCurrentlyEdited}
                          isScrollVisible={isScrollVisible}
                          hasUploadInput={hasUploadInput}
                          onInputChange={(value, inputId) =>
                            handleSubquestionInputChange(value, subquestion.id, inputId)
                          }
                          onStartEditing={handleStartEditing}
                          onFinishEditing={handleFinishEditing}
                          onInputBlur={(value, inputId) => handleSubQuestionInputBlur(value, subquestion.id, inputId)}
                          onRadioChange={(value, inputId) =>
                            handleSubQuestionRadioChange(value, subquestion.id, inputId)
                          }
                        />
                      ))}
                </Box>
              ))}
          </Grid>
          {hasUploadInput && (
            <Grid item md={4} sm={4}>
              <DocumentsBox>
                <Typography variant="h6" mb="5px">
                  {t('supporting_documents_include')}
                </Typography>
                <Typography variant="caption" mb="30px">
                  {t('supporting_documents_include_description')}
                </Typography>
                {!!!uploadsState.length && (
                  <Typography variant="caption" color={theme.darkGreyBG}>
                    {t('no_supporting_documents_provided')}
                  </Typography>
                )}
                {!!uploadsState.length &&
                  uploadsState
                    .filter((u) => !!u.upload)
                    .map((upload) => <UploadView key={`upload-${upload.upload?.id}`} {...upload} />)}
              </DocumentsBox>
            </Grid>
          )}
        </Grid>
      </ScrollableContainer>
    </Box>
  )
}

export default AnswerQuestionsTab
