import { faFileArrowUp, faHouse, faPencil } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  CircularProgress,
  FormControlLabel,
  Grid,
  styled,
  Switch,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material'
import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import useDialog from '../../hooks/useDialog'
import useMainLayoutPadding from '../../hooks/useMainLayoutPadding'
import { useNeedHeightAdjustments } from '../../hooks/useNeedHeightAdjustments'
import { useNumberParam } from '../../hooks/useNumberParam'
import { EventType, SurveyUpdatedProps, useSocket } from '../../hooks/useSocket'
import useSurvey from '../../hooks/useSurvey'
import { useErrorToast } from '../../hooks/useToast'
import { uploadsReducer } from '../../reducers/uploadsReducer'
import { isAxiosError } from '../../services/axiosHelper'
import {
  editFinished,
  editStarted,
  fetchEditorList,
  lockUnlockSurvey,
  submitNode,
  updateEditorList,
} from '../../services/surveyApi'
import theme from '../../theme'
import { FileProps, InputRequest, InputType, Node, NodeRequest, SurveyEditorResponse } from '../../types/surveyTypes'
import { User } from '../../types/userTypes'
import { EditingBadge, LockedBadge, ViewOnlyBadge } from '../../_components/Badge'
import { PrimaryButton, PrimaryIconButton, SecondaryButton } from '../../_components/Buttons'
import { CenteredRadarSpinner } from '../../_components/Spinner'
import { IconTab, IconTabs } from '../../_components/Tabs'
import TruncatedText from '../../_components/TruncatedText'
import AnswerQuestionsTab from './tabs/AnswerQuestionsTab'
import UploadDocumentsTab from './tabs/UploadDocumentsTab'

const NavigationFooter = styled(Box)({
  display: 'flex',
  width: '100%',
  left: 0,
  flexDirection: 'row',
  marginTop: '40px',
  marginBottom: '30px',
})

const LeftBox = styled(Box)({
  [theme.breakpoints.down(740)]: {
    display: 'flex',
    alignItems: 'center',
  },
})

const RightBox = styled(Box)({
  [theme.breakpoints.down(740)]: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column-reverse',
  },
})

const BadgeContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '30px',
  marginLeft: '10px',
  justifyContent: 'flex-end',
})

const SurveyQuestions = () => {
  const { t } = useTranslation(['surveyQuestions', 'common'])
  const queryClient = useQueryClient()
  const axiosPrivate = useAxiosPrivate()
  const { isViewer, isResponder, isAdmin, user, token } = useAuth()
  const navigate = useNavigate()
  const needHeightAdjustments = useNeedHeightAdjustments()
  const lgScreen = useMediaQuery('(min-width:1600px)')
  const smScreen = useMediaQuery('(max-width:1080px)')
  const xsScreen = useMediaQuery('(max-width:740px)')
  const footerPadding = useMainLayoutPadding()
  const questionTitleRef = React.createRef<HTMLDivElement>()
  const [questionTitleHeight, setQuestionTitleHeight] = useState<number>(100)
  const { tier, section, question } = useParams()
  const tierNumber = useNumberParam(tier)
  const sectionNumber = useNumberParam(section)
  const questionNumber = useNumberParam(question)
  const { survey, setResponse, setSubquestionResponse } = useSurvey()
  const [tabPreState, setTabPreState] = useState(0)
  const [tabState, setTabState] = useState(0)
  const [showTypeRequiredError, setShowTypeRequiredError] = useState<boolean>(false)
  const [documentsLoadingState, setDocumentsLoadingState] = useState<boolean>(false)
  const [locked, setLocked] = useState<boolean>(false)
  const [isCurrentlyEdited, setIsCurrentlyEdited] = useState<boolean>(false)
  const [currentlyEditedInputId, setCurrentlyEditedInputId] = useState<number>()
  const [currentEditor, setCurrentEditor] = useState<User>()
  const dialog = useDialog()
  const [stillEditing, setStillEditing] = useState<boolean>(false)
  const [updateEditorsTimerId, setUpdateEditorsTimerId] = useState<NodeJS.Timer>()
  const questionNodeIdRef = useRef<number>()

  const tierNode = useMemo(() => {
    if (tierNumber && survey?.root?.children?.length) {
      if (!!survey.submitted_at) navigate('/')
      if (survey.root.children.length >= tierNumber) {
        return survey.root.children[tierNumber - 1]
      }
    }
  }, [survey, tierNumber])

  const sectionNode = useMemo(() => {
    if (tierNode && sectionNumber && tierNode.children && tierNode.children.length >= sectionNumber) {
      return tierNode.children[sectionNumber - 1]
    }
  }, [tierNode, sectionNumber])

  const questionNode = useMemo(() => {
    if (sectionNode && questionNumber && sectionNode.children && sectionNode.children.length >= questionNumber) {
      const questionNode = sectionNode.children[questionNumber - 1]
      setLocked(questionNode && !!questionNode.locked_at)
      return questionNode
    }
  }, [sectionNode, questionNumber])

  const fileUploadInput = useMemo(() => {
    if (questionNode) {
      return questionNode.inputs?.find((input) => input.type == InputType.FileUpload)
    }
  }, [questionNode])

  const [uploadsState, setUploadsState] = useReducer(
    uploadsReducer,
    fileUploadInput?.response?.uploads?.map<FileProps>((upload) => {
      return {
        fileType: upload.file_type ?? null,
        uploadedFileId: upload.id,
        upload,
      }
    }) ?? [],
  )

  useEffect(() => {
    const resetUploads = async () => {
      setUploadsState({
        type: 'SET',
        payload: {
          uploadSet: fileUploadInput?.response?.uploads ?? undefined,
        },
        index: 0,
      })
    }
    resetUploads()
  }, [tierNumber, sectionNumber, questionNumber, fileUploadInput])

  useEffect(() => {
    const element = questionTitleRef.current
    setQuestionTitleHeight(element ? element.clientHeight : 100)
  }, [questionTitleRef])

  const { data: currentEditorsList } = useQuery<SurveyEditorResponse>({
    queryKey: ['fetchEditorList'],
    queryFn: () => fetchEditorList(axiosPrivate, survey.id),
    enabled: !!token && !!survey.id,
    onSuccess: (response) => {
      const editor = response?.data.find((e) => e.user_id !== user?.id && e.node_id === questionNode?.id)
      if (editor) {
        setIsCurrentlyEdited(true)
        setCurrentEditor({ id: editor.user_id, first_name: editor.first_name, last_name: editor.last_name, email: '' })
      }
    },
  })

  const { mutate: startEditing } = useMutation(
    async (request: InputRequest) => await editStarted(axiosPrivate, request),
  )

  const { mutate: finishEditing } = useMutation(
    async (request: InputRequest) => await editFinished(axiosPrivate, request),
  )

  const { mutate: updateEditors } = useMutation(
    async (request: InputRequest) => await updateEditorList(axiosPrivate, request),
  )

  useEffect(() => {
    if (stillEditing && currentlyEditedInputId) {
      const timerId = setInterval(
        () => updateEditors({ surveyId: survey.id, inputId: currentlyEditedInputId }),
        Number(process.env.REACT_APP_EDITORS_UPDATE_INTERVAL) || 20000,
      )
      setUpdateEditorsTimerId(timerId)
    } else clearInterval(updateEditorsTimerId)
  }, [stillEditing])

  const { mutate: submitQuestion, isLoading: isSubmitting } = useMutation(
    ['submitQuestion', questionNode?.id],
    async (request: NodeRequest) => await submitNode(axiosPrivate, request),
    {
      onSuccess: () => {
        goToNextQuestion()
      },
      onError: (error) => {
        if (isAxiosError(error) && error?.response?.statusText) {
          useErrorToast(error.response.statusText)
        }
      },
    },
  )

  // Handle next tab button --------------------------
  const [tabClickedState, setTabClickedState] = useState(false)

  useEffect(() => {
    if (!!!documentsLoadingState && !!tabClickedState) {
      setTabState(tabPreState)
      setTabClickedState(false)
    }
  }, [documentsLoadingState, tabClickedState === true])

  // Handle previous question button --------------------------
  const [previousPageClickedState, setpreviousPageClickedState] = useState(false)

  useEffect(() => {
    if (!!!documentsLoadingState && !!previousPageClickedState) handlePrevQuestionClick()
  }, [documentsLoadingState, previousPageClickedState === true])

  const handlePrevQuestionClick = () => {
    setpreviousPageClickedState(false)
    if (tierNumber && sectionNumber && questionNumber) {
      if (tierNode?.children && sectionNode?.children) {
        if (questionNumber === 1) {
          if (sectionNumber === 1) navigate('/')
          else navigate(`/tier/${tierNumber}`)
        } else {
          navigate(`/survey/${tierNumber}/${sectionNumber}/${questionNumber - 1}`)
          setTabState(0)
        }
      }
    }
  }

  // Handle next question button --------------------------
  const [nextPageClickedState, setNextPageClickedState] = useState(false)

  useEffect(() => {
    if (!!!documentsLoadingState && !!nextPageClickedState) handleNextQuestionClick()
  }, [documentsLoadingState, nextPageClickedState === true])

  const finish = () => {
    if ((isAdmin || isResponder) && questionNode && !locked) {
      submitQuestion({ surveyId: survey.id, nodeId: questionNode.id })
    } else {
      goToNextQuestion()
    }
  }

  const handleNextQuestionClick = async () => {
    setNextPageClickedState(false)
    if (tierNumber && sectionNumber && questionNumber && !locked) {
      let isValid = true
      if (uploadsState.some((upload) => !upload.fileType) && !isViewer) {
        useErrorToast(t('validation.tag_required'))
        setShowTypeRequiredError(true)
        isValid = false
      }
      questionNode?.inputs?.forEach(async (input) => {
        if (
          input.type !== InputType.FileUpload &&
          !input.required &&
          (!input.response || input.response.entered_as.length < 5)
        ) {
          isValid = false
          await dialog.openWarningDialog(t('validation.no_answer_provided'), () => finish())
        }
      })
      questionNode?.children?.forEach(async (subquestion) => {
        subquestion.inputs?.forEach(async (input) => {
          if (
            input.type !== InputType.FileUpload &&
            input.required &&
            (!input.response || input.response.entered_as.length < 5)
          ) {
            isValid = false
            await dialog.openWarningDialog(t('validation.no_answer_provided'), () => finish())
          }
        })
      })
      if (isValid) finish()
    } else finish()
  }

  const goToNextQuestion = () => {
    if (tierNode?.children && sectionNode?.children && questionNumber) {
      if (questionNumber === sectionNode.children.length) {
        if (sectionNumber === tierNode.children.length) navigate('/')
        else navigate(`/tier/${tierNumber}`)
      } else {
        navigate(`/survey/${tierNumber}/${sectionNumber}/${questionNumber + 1}`)
        setTabState(0)
      }
    }
  }

  const { mutateAsync: lockUnlockQuestion, isLoading: lockLoading } = useMutation(
    ['lockUnlockQuestion', questionNode?.id],
    async (request: NodeRequest) => await lockUnlockSurvey(axiosPrivate, request),
    {
      onSuccess: () => queryClient.invalidateQueries(['survey']),
      onError: (error) => {
        if (isAxiosError(error) && error?.response?.statusText) {
          useErrorToast(error.response.statusText)
        }
      },
    },
  )

  const handleLockUnlock = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (!checked) {
      toggleLock(false)
    } else {
      const noAnswers =
        questionNode?.inputs?.some((input) => input.type !== InputType.FileUpload && !input.response?.entered_as) ||
        questionNode?.children?.some((subquestion) =>
          subquestion.inputs?.some((sqInput) => sqInput.type !== InputType.FileUpload && !sqInput.response?.entered_as),
        )
      const noUploads =
        !!fileUploadInput &&
        !questionNode?.inputs?.some(
          (input) => input.type === InputType.FileUpload && input.response?.uploads?.length,
        ) &&
        !questionNode?.children?.some((subquestion) =>
          subquestion.inputs?.some(
            (sqInput) => sqInput.type === InputType.FileUpload && sqInput.response?.uploads?.length,
          ),
        )
      if (uploadsState.some((upload) => !upload.fileType) && !isViewer) {
        useErrorToast(t('validation.tag_required'))
        setShowTypeRequiredError(true)
      } else if (!noAnswers && !noUploads) toggleLock(true)
      else {
        if (noAnswers)
          dialog.openWarningDialog(t('no_answer_provided'), () => {
            if (noUploads) {
              dialog.openWarningDialog(t('no_files_uploaded'), () => {
                toggleLock(true)
              })
            } else {
              toggleLock(true)
            }
          })
        if (!noAnswers && noUploads) {
          dialog.openWarningDialog(t('no_files_uploaded'), () => toggleLock(true))
        }
      }
    }
  }

  const toggleLock = (lockValue: boolean) => {
    survey &&
      questionNode &&
      lockUnlockQuestion({ surveyId: survey.id, nodeId: questionNode?.id }).then(() => setLocked(lockValue))
  }

  ///sockets

  useEffect(() => {
    questionNodeIdRef.current = questionNode?.id
  }, [questionNode])

  useSocket({
    type: EventType.SurveyEditStart,
    callBack: (info: SurveyUpdatedProps) => {
      if (info.metadata.questionId == questionNodeIdRef.current) {
        console.log('SurveyEditStart', user)
        setCurrentlyEditedInputId(info.metadata.inputId)
        if (info.user.id == user?.id) setStillEditing(true)
        else {
          setIsCurrentlyEdited(true)
          setCurrentEditor(info.user)
        }
      }
    },
  })

  useSocket({
    type: EventType.SurveyEditFinish,
    callBack: (info: SurveyUpdatedProps) => {
      if (info.metadata.questionId == questionNodeIdRef.current) {
        console.log('SurveyEditFinish')
        setCurrentlyEditedInputId(undefined)
        if (info.user.id == user?.id) setStillEditing(false)
        else {
          setIsCurrentlyEdited(false)
          setCurrentEditor(undefined)
          if (info.input.response) {
            if (info.metadata.fileId) {
              const description =
                info.input.response?.uploads?.find((u) => u.id === info.metadata.fileId)?.description ?? undefined
              setUploadsState({
                type: 'UPDATE_FILE_BY_ID',
                id: info.metadata.fileId,
                payload: { description: description },
              })
            } else {
              const data = {
                tierId: info.metadata.tierId,
                sectionId: info.metadata.sectionId,
                questionId: info.metadata.questionId,
                response: { ...info.input.response, input_id: info.input.id },
              }
              if (info.metadata.subQuestionId)
                setSubquestionResponse({ ...data, subquestionId: info.metadata.subQuestionId })
              else setResponse(data)
            }
          }
        }
      }
    },
  })

  useSocket({
    type: EventType.SurveyLocked,
    callBack: (info: SurveyUpdatedProps) => {
      if (info.user.id != user?.id && info.metadata.questionId == questionNodeIdRef.current) {
        setLocked(true)
      }
    },
  })

  useSocket({
    type: EventType.SurveyUnLocked,
    callBack: (info: SurveyUpdatedProps) => {
      if (info.user.id != user?.id && info.metadata.questionId == questionNodeIdRef.current) {
        setLocked(false)
      }
    },
  })

  useSocket({
    type: EventType.SurveyInputResponseFileTypeUpdated,
    callBack: (info: SurveyUpdatedProps) => {
      if (info.user.id != user?.id && info.metadata.questionId == questionNodeIdRef.current && info.metadata.fileId) {
        const type = info.input.response?.uploads?.find((u) => u.id === info.metadata.fileId)?.file_type ?? undefined
        setUploadsState({ type: 'UPDATE_FILE_BY_ID', id: info.metadata.fileId, payload: { fileType: type } })
      }
    },
  })

  useSocket({
    type: EventType.SurveyInputResponseFileDeleted,
    callBack: (info: SurveyUpdatedProps) => {
      if (info.user.id != user?.id && info.metadata.questionId == questionNodeIdRef.current && info.metadata.fileId) {
        setUploadsState({ type: 'REMOVE_BY_ID', id: info.metadata.fileId })
      }
    },
  })

  ///sockets

  const isPrevQuestionDisabled =
    tierNumber && sectionNumber && questionNumber
      ? tierNumber === 1 && sectionNumber === 1 && questionNumber === 1
      : true
  const numeration = `${tierNumber}.${sectionNumber}.${questionNumber}`
  const isBadgeVisible = isViewer || ((isResponder || isAdmin) && (locked || isCurrentlyEdited))

  return (
    <Box pt={needHeightAdjustments ? '20px' : '40px'}>
      {(!tierNode || !sectionNode || !questionNode || isSubmitting) && (
        <Box mt="200px">
          <CenteredRadarSpinner />
        </Box>
      )}
      {!isSubmitting && tierNode && sectionNode && questionNode && (
        <>
          <Box ref={questionTitleRef} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Grid container>
              <Grid item md={isBadgeVisible ? 10 : 12} xs={isBadgeVisible ? 10 : 12} lg={isBadgeVisible ? 9 : 12}>
                <Box sx={{ marginBottom: needHeightAdjustments ? '25px' : '35px' }}>
                  <TruncatedText
                    title={`${t('common:tier')} ${tierNumber} ${tierNode.title} / T${tierNumber}.${sectionNumber} ${
                      sectionNode.title
                    }`}
                  >
                    <Typography variant="overline">
                      {t('common:tier')} {tierNumber} {tierNode.title} / T{tierNumber}.{sectionNumber}{' '}
                      {sectionNode.title}
                    </Typography>
                  </TruncatedText>
                </Box>
              </Grid>
              <Grid item md={2} xs={2} lg={3}>
                {isViewer && (
                  <BadgeContainer>
                    <ViewOnlyBadge />
                  </BadgeContainer>
                )}
                {isResponder && locked && (
                  <BadgeContainer>
                    <LockedBadge />
                  </BadgeContainer>
                )}
                {!isViewer && isCurrentlyEdited && currentEditor && (
                  <BadgeContainer>
                    <EditingBadge firstName={currentEditor.first_name} lastName={currentEditor.last_name} />
                  </BadgeContainer>
                )}
              </Grid>
            </Grid>
          </Box>
          <IconTabs
            variant="fullWidth"
            value={tabState}
            onChange={(event, value) => {
              setTabPreState(value)
              setTabClickedState(true)
            }}
          >
            <IconTab
              icon={
                xsScreen ? (
                  ''
                ) : (
                  <FontAwesomeIcon
                    icon={faPencil}
                    size="2x"
                    width={lgScreen ? '17px' : '12px'}
                    height={lgScreen ? '21px' : '16px'}
                  />
                )
              }
              label={t('answer_questions')}
              value={0}
              smScreen={smScreen}
            />
            <IconTab
              icon={
                xsScreen ? (
                  ''
                ) : (
                  <FontAwesomeIcon
                    icon={faFileArrowUp}
                    size="2x"
                    width={lgScreen ? '17px' : '12px'}
                    height={lgScreen ? '21px' : '16px'}
                  />
                )
              }
              label={
                <Tooltip title={!!fileUploadInput ? '' : t('tooltips.no_documents')} followCursor>
                  <div>{t('upload_documents')}</div>
                </Tooltip>
              }
              value={1}
              disabled={!!!fileUploadInput}
              smScreen={smScreen}
            />
          </IconTabs>
          {
            {
              0: (
                <AnswerQuestionsTab
                  tierId={tierNode.id}
                  sectionId={sectionNode.id}
                  questionId={questionNode.id}
                  questionNode={questionNode}
                  numeration={numeration}
                  questionTitleHeight={questionTitleHeight}
                  uploadsState={uploadsState}
                  isLocked={locked}
                  isCurrentlyEdited={isCurrentlyEdited}
                  documentsLoadingCallback={setDocumentsLoadingState}
                  startEditing={startEditing}
                  finishEditing={finishEditing}
                />
              ),
              1: (
                <UploadDocumentsTab
                  tierId={tierNode.id}
                  sectionId={sectionNode.id}
                  questionId={questionNode.id}
                  input={fileUploadInput}
                  isLocked={locked}
                  isCurrentlyEdited={isCurrentlyEdited}
                  showTypeRequiredError={showTypeRequiredError}
                  questionTitleHeight={questionTitleHeight}
                  documentsLoadingCallback={setDocumentsLoadingState}
                  uploadsState={uploadsState}
                  setUploadsStateCallback={setUploadsState}
                  startEditing={startEditing}
                  finishEditing={finishEditing}
                />
              ),
            }[tabState]
          }
          <NavigationFooter
            sx={{
              position: 'absolute',
              bottom: needHeightAdjustments ? '36px' : '60px',
              justifyContent: 'space-between',
              padding: `0 ${footerPadding}px`,
            }}
          >
            <LeftBox>
              <PrimaryIconButton variant="contained" onClick={() => navigate('/')} disabled={lockLoading}>
                {documentsLoadingState ? (
                  <CircularProgress size={'17.5px'} sx={{ color: theme.actionDisabled }} />
                ) : (
                  <FontAwesomeIcon icon={faHouse} size="lg" />
                )}
              </PrimaryIconButton>
              {isAdmin && (
                <Tooltip
                  title={
                    isCurrentlyEdited
                      ? t('tooltips.response_currently_edited')
                      : locked
                      ? t('tooltips.unlock')
                      : t('tooltips.lock')
                  }
                >
                  <FormControlLabel
                    control={
                      <Switch
                        disabled={lockLoading || isCurrentlyEdited}
                        checked={locked}
                        onChange={handleLockUnlock}
                      />
                    }
                    sx={{ fontSize: '16px', letterSpacing: '0.15px', textTransform: 'uppercase', ml: '15px' }}
                    label={
                      <>
                        {lockLoading ? (
                          <CircularProgress color="primary" size={'30px'} />
                        ) : locked ? (
                          t('unlock_page')
                        ) : (
                          t('lock_page')
                        )}
                      </>
                    }
                  />
                </Tooltip>
              )}
            </LeftBox>
            {tabState === 0 && (
              <RightBox
                sx={{
                  margin: isAdmin ? 'initial' : 'auto',
                  paddingRight: isAdmin ? 0 : '40px',
                }}
              >
                <SecondaryButton
                  variant="outlined"
                  sx={{ marginRight: xsScreen ? 0 : '10px' }}
                  disabled={isPrevQuestionDisabled || lockLoading}
                  onClick={(e) => {
                    e.preventDefault()
                    setpreviousPageClickedState(true)
                  }}
                >
                  {t('buttons.prev_question')}
                </SecondaryButton>
                <PrimaryButton
                  variant="contained"
                  onClick={(e) => {
                    e.preventDefault()
                    setNextPageClickedState(true)
                  }}
                  disabled={lockLoading || nextPageClickedState}
                  sx={{ width: xsScreen ? '100%' : 'initial', mb: xsScreen ? '10px' : 0 }}
                >
                  {documentsLoadingState ? (
                    t('buttons.auto_saving')
                  ) : (
                    <>
                      {(isViewer || (isResponder && locked)) && t('buttons.next_question')}
                      {(isAdmin || (isResponder && !locked)) && t('buttons.continue')}
                    </>
                  )}
                </PrimaryButton>
              </RightBox>
            )}
          </NavigationFooter>
        </>
      )}
    </Box>
  )
}

export default SurveyQuestions
