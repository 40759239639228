import {
  Card,
  Box,
  CardActionArea,
  CardContent,
  Typography,
  styled,
  useMediaQuery,
  Avatar,
  AvatarProps,
  Tooltip,
} from '@mui/material'
import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useLockUnlockSocket from '../../../hooks/useLockUnlockSocket'
import { useNeedHeightAdjustments } from '../../../hooks/useNeedHeightAdjustments'
import theme from '../../../theme'
import { User } from '../../../types/userTypes'
import { LockedPages } from '../../../_components/LockedPages'
import TruncatedText from '../../../_components/TruncatedText'

const layoutInRows = process.env.REACT_APP_LAYOUT_IN_ROWS == 'true'

const TierCard = styled(Card)({
  maxWidth: layoutInRows ? undefined : '223px',
  minHeight: layoutInRows ? undefined : '260px',
  boxShadow: theme.blurredBoxShadow,
  borderRadius: '9px',
})

const TierImage = styled(Box)({
  backgroundRepeat: 'no-repeat',
  backgroundSize: layoutInRows ? '33%' : 'cover',
  maxHeight: '200px',
})

const TierCardActionArea = styled(CardActionArea)({
  backgroundColor: theme.white,
  borderTopLeftRadius: '9px',
  borderTopRightRadius: '9px',
  marginLeft: layoutInRows ? '33%' : undefined,
  paddingLeft: layoutInRows ? '12px' : undefined,
})

const InfoBox = styled(Box)({
  [theme.breakpoints.up(1200)]: {
    display: 'inline-flex',
    alignItems: 'flex-end',
  },
})

const LockedPagesBox = styled(Box)({
  display: 'inline-flex',
  height: '47px',
  marginTop: '10px',
  paddingBottom: '10px',
  alignItems: 'flex-end',
  [theme.breakpoints.up(1200)]: {
    paddingBottom: 0,
  },
})

const UserAvatar = styled(Avatar)({
  height: '28px',
  width: '28px',
  backgroundColor: theme.palette.primary.main,
  border: `1px solid ${theme.white}`,
  fontSize: '12px',
  textTransform: 'uppercase',
  letterSpacing: '0.15px',
})

export interface TierInfoCardProps {
  id: number
  number: number
  title: string
  lockedQuestionsCount: number
  allQuestionsCount: number
  viewOnly: boolean
  editors?: User[]
}

export interface EditorBadgeProps extends AvatarProps {
  user?: User
  hiddenUsers?: User[]
}

const EditorBadge = ({ user, hiddenUsers, sx }: EditorBadgeProps) => {
  const tooltipTitle = user
    ? `${user.first_name} ${user.last_name}`
    : hiddenUsers?.map((u) => <Box key={u.id}>{`${u.first_name} ${u.last_name}`}</Box>) ?? ''

  return (
    <Tooltip title={tooltipTitle}>
      <UserAvatar sx={sx}>
        {!!!user && !!hiddenUsers?.length && `+${hiddenUsers.length}`}
        {!!!hiddenUsers && user && `${user.first_name[0]}${user.last_name[0]}`}
      </UserAvatar>
    </Tooltip>
  )
}

const TierInfoCard = ({
  id,
  number,
  title,
  lockedQuestionsCount,
  allQuestionsCount,
  viewOnly,
  editors,
}: TierInfoCardProps) => {
  const needHeightAdjustments = useNeedHeightAdjustments(980)
  const lgScreen = useMediaQuery('(min-width:1600px)')
  const navigate = useNavigate()
  const [lockedCount, setLockedCount] = useState<number>(lockedQuestionsCount)

  useLockUnlockSocket({
    tierId: id,
    lockedCallback: () => {
      setLockedCount(lockedCount + 1)
    },
    unlockedCallback: () => {
      setLockedCount(lockedCount - 1)
    },
  })

  const handleTierCardClick = () => {
    if (!viewOnly) navigate(`/tier/${number}`)
  }

  const cardContentHeight = useMemo(
    () => (needHeightAdjustments ? '200px' : lgScreen ? '270px' : '310px'),
    [needHeightAdjustments, lgScreen],
  )

  return (
    <TierCard sx={{ height: layoutInRows ? '120px' : needHeightAdjustments ? '260px' : '370px' }}>
      <TierImage
        sx={{
          backgroundImage: `url(/images/tiers/${number}.png)`,
          paddingTop: layoutInRows ? '0' : lgScreen ? (needHeightAdjustments ? '60px' : '100px') : '60px',
        }}
      >
        <TierCardActionArea
          sx={{ pointerEvents: viewOnly ? 'none' : 'auto', cursor: viewOnly ? 'default' : 'pointer' }}
          onClick={handleTierCardClick}
        >
          <CardContent
            sx={{
              height: cardContentHeight,
              borderRadius: '9px',
              padding: layoutInRows ? '0' : needHeightAdjustments ? '10px 20px 15px' : '45px 20px 20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ marginTop: layoutInRows ? '15px' : undefined }}>
              <Typography variant="overline">Tier {number}</Typography>
              <Box sx={{ marginTop: lgScreen ? '15px' : 0 }}>
                <TruncatedText title={title} lineClamp={3}>
                  <Typography variant="subtitle1" textOverflow={'ellipsis'}>
                    {title}
                  </Typography>
                </TruncatedText>
              </Box>
            </Box>
            <InfoBox sx={{ justifyContent: lockedCount ? 'space-between' : 'end' }}>
              {lockedCount ? (
                <LockedPagesBox>
                  <LockedPages lockedQuestionsCount={lockedCount} allQuestionsCount={allQuestionsCount} />
                </LockedPagesBox>
              ) : null}
              {!!editors?.length && (
                <Box sx={{ display: 'inline-flex' }}>
                  {[{ id: '123', first_name: 'Test', last_name: 'User', email: '' }].length <= 2 &&
                    editors.map((user) => <EditorBadge key={user.id} user={user} sx={{ mr: '4px' }} />)}
                  {editors.length === 3 &&
                    editors.map((user) => (
                      <EditorBadge key={user.id} user={user} sx={{ mr: lgScreen ? '-9px' : '-6px' }} />
                    ))}
                  {editors.length > 3 && (
                    <>
                      {editors.slice(0, 2).map((user) => (
                        <EditorBadge key={user.id} user={user} sx={{ mr: lgScreen ? '-9px' : '-6px' }} />
                      ))}
                      <EditorBadge hiddenUsers={editors.slice(2)} sx={{ mr: lgScreen ? '-9px' : '-6px' }} />
                    </>
                  )}
                </Box>
              )}
            </InfoBox>
          </CardContent>
        </TierCardActionArea>
      </TierImage>
    </TierCard>
  )
}

export default TierInfoCard
