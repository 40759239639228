import { Box, Typography } from '@mui/material'
import React from 'react'
import { useMutation } from 'react-query'
import { PasswordEmailErrorResponse, PasswordEmailRequest } from '../../types/authTypes'
import { useForm } from 'react-hook-form'
import { TextFieldControl } from '../../_components/TextField'
import { PrimaryButton } from '../../_components/Buttons'
import { ForgotPasswordSchema } from '../../validationSchemas/authSchemas'
import { yupResolver } from '@hookform/resolvers/yup'
import FormContainer from './components/FormContainer'
import { useTranslation } from 'react-i18next'
import { isAxiosError } from '../../services/axiosHelper'
import { useErrorToast } from '../../hooks/useToast'
import { postPasswordEmail } from '../../services/authApi'

const defaultValues = {
  email: '',
}

const ForgotPassword = () => {
  const { t } = useTranslation(['auth'])
  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    clearErrors,
  } = useForm<PasswordEmailRequest>({ resolver: yupResolver(ForgotPasswordSchema), defaultValues })

  const {
    mutate: sendPasswordEmail,
    isLoading,
    isSuccess,
  } = useMutation(async (requestData: PasswordEmailRequest) => await postPasswordEmail(requestData), {
    onError: (error: unknown) => {
      if (isAxiosError<string>(error) && error?.response?.data) {
        error.response.data && useErrorToast(error.response.data)
      }
      if (isAxiosError<PasswordEmailErrorResponse>(error) && error?.response?.data) {
        error.response.data.email && useErrorToast(error.response.data.email.join(' '))
      }
    },
  })

  const handleSendPasswordEmail = (formData: PasswordEmailRequest) => {
    sendPasswordEmail(formData)
  }

  const isDisabled = isLoading || isSubmitting

  return (
    <FormContainer>
      <form onSubmit={handleSubmit(handleSendPasswordEmail)}>
        <Box sx={{ textAlign: 'center' }}>
          <Typography sx={{ mb: '25px' }} variant="h4">
            {t('titles.reset_password')}
          </Typography>
          <Box width="400px">
            {isSuccess && <Typography variant="h5">{t('messages.email_sent')}</Typography>}
            {!isSuccess && (
              <TextFieldControl
                sx={{ mb: '20px' }}
                control={control}
                name="email"
                type="email"
                label={t('email')}
                autoComplete="email"
                disabled={isDisabled}
                errorText={errors.email?.message}
                onFocus={() => clearErrors('email')}
              />
            )}
          </Box>
          {!isSuccess && (
            <PrimaryButton
              type="submit"
              variant="contained"
              sx={{
                marginTop: '70px',
                marginBottom: '30px',
              }}
              disabled={isDisabled}
            >
              {t('buttons.send_link')}
            </PrimaryButton>
          )}
        </Box>
      </form>
    </FormContainer>
  )
}

export default ForgotPassword
