import { useMemo } from 'react'
import { useWindowSize } from './useWindowSize'

export function useNeedHeightAdjustments(heightThresholdProp?: number): boolean {
  const { height } = useWindowSize()

  const heightThreshold = heightThresholdProp || 1080

  const needHeightAdjustments = useMemo<boolean>(
    () => !!(height && height < heightThreshold),
    [height && height < heightThreshold],
  )

  return needHeightAdjustments
}
