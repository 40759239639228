import { Tab, styled, TabProps, Typography, TabsProps, Tabs } from '@mui/material'
import React from 'react'
import theme from '../theme'

export const IconTabs = styled((props: TabsProps) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />
))({
  minHeight: '37px',
  [theme.breakpoints.up(1600)]: {
    minHeight: '47px',
  },
  '& .MuiTabs-indicator': {
    display: 'none',
  },
  '& .MuiTabs-flexContainer': {
    display: 'block',
  },
})

interface IconTabProps extends TabProps {
  smScreen: boolean
}

export const IconTab = styled((props: IconTabProps) => {
  const { smScreen, ...basicProps } = props
  return (
    <Tab
      disableRipple
      {...basicProps}
      iconPosition="start"
      label={
        <Typography variant={smScreen ? 'h6' : 'h5'} ml={smScreen ? '0' : '15px'}>
          {props.label || ''}
        </Typography>
      }
    />
  )
})({
  border: `1px solid ${theme.palette.primary.main}`,
  color: theme.palette.primary.main,
  textTransform: 'none',
  minHeight: '37px',
  height: '37px',
  whiteSpace: 'nowrap',
  [theme.breakpoints.up(1600)]: {
    minHeight: '47px',
    height: '47px',
  },
  '&.Mui-selected': {
    color: theme.white,
    backgroundColor: theme.palette.primary.main,
    transition: 'all 300ms ease-in-out',
  },
  ':first-of-type': {
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
    width: '66%',
  },
  ':last-child': {
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
    width: '34%',
  },
  '&.Mui-disabled': {
    pointerEvents: 'auto',
  },
})
