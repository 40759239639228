import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  styled,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { DataGrid, GridColDef, GridEventListener, GridRenderCellParams } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { useNeedHeightAdjustments } from '../../../hooks/useNeedHeightAdjustments'
import useSurvey from '../../../hooks/useSurvey'
import { useErrorToast, useSuccessToast } from '../../../hooks/useToast'
import { sortInputs } from '../../../services/arrayHelper'
import { getFilenameFromContentDisposition, isAxiosError } from '../../../services/axiosHelper'
import {
  fetchSurvey,
  fetchRootNodeTemplates,
  postAddSurvey,
  submitInput,
  assignSurvey,
} from '../../../services/surveyApi'
import theme from '../../../theme'
import {
  Node,
  NodeList,
  SubmitResponseRequest,
  InputType,
  SubmitResponseResponse,
  SurveyList,
  Survey,
  AssignSurveyRequest,
} from '../../../types/surveyTypes'
import { ScrollableContainer } from '../../../_components/ScrollableContainer'
import { CenteredRadarSpinner, RadarSpinner } from '../../../_components/Spinner'
import { AddUserRequest, UpdateUserRequest, Organisation, OrganisationList, User } from '../../../types/userTypes'
import { Root } from 'react-dom/client'
import { axiosPrivate } from '../../../services/apiClient'
import { PrimaryButton, SecondaryButton } from '../../../_components/Buttons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faCheck, faDownload, faSave } from '@fortawesome/free-solid-svg-icons'
import { SelectControl } from '../../../_components/Select'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { AddUserSchema } from '../../../validationSchemas/userSchema'
import { AddSurveySchema } from '../../../validationSchemas/surveySchema'
import { fetchOrganisationList, postEditUser } from '../../../services/userApi'
import { StyledDialog } from '../../../_components/Dialog'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import i18n from '../../../i18n'
import {Moment} from 'moment'

interface SurveyAdminPanelProps {
  surveyList: SurveyList | undefined
  organisationList: OrganisationList | undefined
  handleSurveysUpdate?: () => void
}

interface NewAssignProps {
  organisationId: number | undefined
  nodeId: number | undefined
  startDate: Date | undefined
  endDate: Date | undefined
}

const SurveyAdminPanel = ({ surveyList, organisationList, handleSurveysUpdate }: SurveyAdminPanelProps) => {
  const { t } = useTranslation(['admin'])
  const axiosPrivate = useAxiosPrivate()
  const needHeightAdjustments = useNeedHeightAdjustments()
  const lgScreen = useMediaQuery('(min-width:1600px)')

  const { survey, setSurvey } = useSurvey()

  const [selectedSurvey, setSelectedSurvey] = useState<Survey>()
  const [downloading, setDownloading] = useState<number | null>(null)
  const [populating, setPopulating] = useState<number | null>(null)
  const [assigning, setAssigning] = useState<boolean>(false)
  const [newAssign, setNewAssign] = useState<NewAssignProps>({
    organisationId: undefined,
    nodeId: undefined,
    startDate: undefined,
    endDate: undefined,
  })
  const [updating, setUpdating] = useState(false)

  const queryClient = useQueryClient()

  const { data: rootNodeTemplates } = useQuery<NodeList>(
    ['rootNodeTemplates'],
    async () => await fetchRootNodeTemplates(axiosPrivate),
  )

  const downloadSurvey = (surveyId?: number) => {
    if (!surveyId) surveyId = survey.id
    setDownloading(surveyId)
    axiosPrivate
      .get(`/survey/export/${surveyId}`, {
        responseType: 'blob',
      })
      .then((response) => {
        const href = URL.createObjectURL(response.data)
        const a = document.createElement('a')
        a.href = href
        a.setAttribute('download', getFilenameFromContentDisposition(response) ?? 'survey.xlsx')
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        URL.revokeObjectURL(href)
      })
      .finally(() => {
        setDownloading(null)
      })
  }

  const StyledSelect = styled(Select)({
    width: '100%',
    height: '25px',
    fontSize: '12px',
    [theme.breakpoints.up(1600)]: {
      fontSize: '14px',
      height: '30px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.darkGreyBG,
    },
  })

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', flex: 8 },
    {
      field: 'organisation',
      headerName: 'Organisation',
      valueFormatter: (organisation: Organisation) => organisation.name,
    },
    { field: 'name', headerName: 'Name', flex: 20 },
    { field: 'root', headerName: 'Root', valueFormatter: (root: Node) => root.title, flex: 20 },
    { field: 'starts_at', headerName: 'Start', flex: 10 },
    { field: 'ends_at', headerName: 'End', flex: 10 },
    {
      field: 'submitted_by_user',
      headerName: 'Submitted by',
      valueFormatter: (user: User) => (user ? `${user.first_name} ${user.last_name} (${user.email})` : ''),
      flex: 15,
    },
    { field: 'submitted_at', headerName: 'Submitted', flex: 10 },
    {
      field: 'locked_by_user',
      headerName: 'Locked by',
      valueFormatter: (user: User) => (user ? `${user.first_name} ${user.last_name} (${user.email})` : ''),
      flex: 15,
    },
    { field: 'locked_at', headerName: 'Locked', flex: 10 },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: (params: GridRenderCellParams<Survey>) => {
        const rowSurvey = params.row

        return (
          <>
            <IconButton
              size="small"
              onClick={() => downloadSurvey(rowSurvey.id)}
              disabled={downloading !== null}
              title={downloading == rowSurvey.id ? 'Downloading...' : 'Download this survey'}
            >
              <FontAwesomeIcon icon={downloading == rowSurvey.id ? faDownload : faSave} size="1x" />
            </IconButton>
            {survey != null && survey.id == rowSurvey.id ? (
              <IconButton size="small" sx={{ fontWeight: 'bold', color: theme.palette.success.main }}>
                <FontAwesomeIcon icon={faCheck} size="1x" />
              </IconButton>
            ) : (
              <IconButton
                size="small"
                onClick={() => activateSurvey(rowSurvey)}
                disabled={downloading !== null || populating !== null}
                title="Activate this survey"
              >
                <FontAwesomeIcon icon={faCheck} size="1x" />
              </IconButton>
            )}
          </>
        )
      },
      flex: 10,
    },
  ]

  const rows: Array<Survey> = surveyList?.data ?? []

  const populate = async (rowSurvey: Survey) => {
    if (!rowSurvey.root.children) {
      if (survey && survey.id == rowSurvey.id) {
        rowSurvey.root = survey.root
      } else {
        setPopulating(rowSurvey.id)
        await fetchSurvey(axiosPrivate, rowSurvey.id)
          .then((data) => {
            rowSurvey.root = data.root
          })
          .finally(() => {
            setPopulating(null)
          })
      }
    }
  }

  const handleRowClick: GridEventListener<'rowClick'> = async (event) => {
    if (updating) return

    setAssigning(false)
    if (!surveyList) return
    const survey = surveyList.data.find((survey) => survey.id == event.row.id)
    if (survey) {
      setSelectedSurvey(survey)
      populate(survey)
    }
  }

  const activateSurvey = async (survey: Survey) => {
    await populate(survey)
    setSurvey(survey)
  }

  const { mutate: addSurvey, isLoading } = useMutation<unknown, unknown, AssignSurveyRequest>({
    mutationFn: async (requestData) => await postAddSurvey(axiosPrivate, requestData),
    onSuccess: () => {
      queryClient.invalidateQueries(['surveyList'])
      useSuccessToast(t('messages.survey_created'))
      setAssigning(false)
    },
    onError: () => {
      useErrorToast(`${t('messages.survey_create_error')} ${t('common:contact_support')}`)
    },
  })

  const performSurveyAssignment = async (confirm?: boolean) => {
    if (confirm) {
      if (newAssign.nodeId && newAssign.organisationId && newAssign.startDate) {
        const data: AssignSurveyRequest = {
          node_id: newAssign.nodeId,
          organisation_id: newAssign.organisationId,
          starts_at: newAssign.startDate,
          ends_at: newAssign.endDate,
        }

        setUpdating(true)
        await assignSurvey(axiosPrivate, data)
          .then((data) => {
            useSuccessToast(`${t('messages.survey_assigned')}`)
            if (handleSurveysUpdate) handleSurveysUpdate()
            setAssigning(false)
          })
          .catch((error) => {
            useErrorToast(error)
          })
          .finally(() => {
            setUpdating(false)
          })
      }

      return
    }
    performSurveyAssignment(true)
  }

  return (
    <Box sx={{ position: 'relative', paddingTop: needHeightAdjustments ? '30px' : '50px' }}>
      <Grid container>
        {assigning && updating ? (
          <Grid item sx={{ width: '100%' }}>
            <CenteredRadarSpinner />
            <Typography
              variant="subtitle1"
              sx={{ marginTop: '-10px', textAlign: 'center', color: theme.palette.text.secondary }}
            >
              {t('assigning_survey')}
            </Typography>
            <Typography variant="subtitle2" sx={{ textAlign: 'center', color: theme.palette.text.secondary }}>
              {t('assigning_survey_wait')}
            </Typography>
          </Grid>
        ) : surveyList ? (
          <>
            <Grid item sx={{ width: '100%' }}>
              <DataGrid
                columns={columns}
                rows={rows}
                onRowClick={handleRowClick}
                initialState={{
                  pagination: { paginationModel: { pageSize: 5 } },
                }}
                pageSizeOptions={[5, 10, 15, 20]}
              />
            </Grid>
            <Grid item sx={{ width: '100%', textAlign: 'right', padding: '10px 50px' }}>
              <IconButton
                size="medium"
                sx={{ border: `1px solid ${theme.palette.primary.main}` }}
                onClick={() => {
                  setNewAssign({
                    nodeId: undefined,
                    organisationId: undefined,
                    startDate: undefined,
                    endDate: undefined,
                  })
                  setAssigning(true)
                }}
                disabled={assigning}
                title="Assign a survey"
              >
                <FontAwesomeIcon icon={faAdd} size="1x" />
              </IconButton>
            </Grid>
            {assigning && !updating ? (
              <Grid item sx={{ width: '100%' }} key="assigning-survey">
                <Grid item sx={{ width: '100%' }}>
                  <Typography variant="h4">{t('assign_survey')}</Typography>
                  <FormControl variant={'standard'} sx={{ width: '100%', paddingTop: '20px' }} key="node_id">
                    <InputLabel id="node_id">{t('template')}*</InputLabel>
                    <StyledSelect
                      onChange={(e) => setNewAssign({ ...newAssign, nodeId: parseInt(`${e.target.value}`) })}
                      labelId="node_id"
                      name="node_id"
                      defaultValue={newAssign?.nodeId ?? ''}
                    >
                      {rootNodeTemplates?.data.map((node) => (
                        <MenuItem key={node.id} value={node.id}>
                          {node.title}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                  </FormControl>
                </Grid>
                <Grid item sx={{ width: '100%', paddingTop: '30px' }}>
                  <FormControl variant={'standard'} sx={{ width: '100%', paddingTop: '20px' }} key="organisation_id">
                    <InputLabel id="organisation_id">{t('organisation')}*</InputLabel>
                    <StyledSelect
                      onChange={(e) => setNewAssign({ ...newAssign, organisationId: parseInt(`${e.target.value}`) })}
                      labelId="organisation_id"
                      name="organisation_id"
                      defaultValue={newAssign?.organisationId ?? ''}
                    >
                      {organisationList?.data.map((organisation) => (
                        <MenuItem key={organisation.id} value={organisation.id}>
                          {organisation.name} {organisation.type ? ` (${organisation.type.name})` : ''}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                  </FormControl>
                </Grid>
                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={i18n.language}>
                  <Grid item>
                    <FormControl variant={'standard'} sx={{ width: '100%', paddingTop: '20px' }} key="start_date">
                      <DatePicker
                        label={`${t('start_date')}*`}
                        format="YYYY-MM-DD"
                        name="starts_at"
                        onChange={(d: Moment | null) => setNewAssign({ ...newAssign, startDate: d?.toDate() })}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl variant={'standard'} sx={{ width: '100%', paddingTop: '20px' }} key="end_date">
                      <DatePicker
                        label={`${t('end_date')}`}
                        format="YYYY-MM-DD"
                        name="ends_at"
                        onChange={(d: Moment | null) => setNewAssign({ ...newAssign, endDate: d?.toDate() })}
                      />
                    </FormControl>
                  </Grid>
                </LocalizationProvider>
                <Grid item sx={{ width: '100%', padding: '10px 0 0 0' }}>
                  <PrimaryButton
                    onClick={() => performSurveyAssignment()}
                    disabled={updating || !(newAssign?.organisationId && newAssign.nodeId && newAssign.startDate)}
                    sx={{ marginRight: '10px' }}
                  >
                    Assign
                  </PrimaryButton>
                  <SecondaryButton disabled={updating} onClick={() => setAssigning(false)}>
                    Cancel
                  </SecondaryButton>
                </Grid>
              </Grid>
            ) : null}
            {selectedSurvey && !assigning ? (
              <>
                <Grid item sx={{ width: '100%', paddingTop: '30px' }}>
                  {selectedSurvey.organisation?.image ? (
                    <Box
                      component="img"
                      src={`/images/organisation/${selectedSurvey.organisation.image}`}
                      sx={{ maxHeight: '60px', float: 'right' }}
                    />
                  ) : null}
                  <Typography variant="h4" sx={{ marginTop: '30px' }}>
                    {selectedSurvey.name} {populating == selectedSurvey.id ? '(fetching details...)' : ''}
                  </Typography>
                </Grid>
                <Grid item sx={{ width: '100%', paddingTop: '30px' }}>
                  {populating === selectedSurvey.id ? (
                    <RadarSpinner />
                  ) : (
                    <>
                      <Typography variant="h6" sx={{ marginTop: '30px' }}>
                        {selectedSurvey.root.children?.length ? (
                          <>
                            {`${selectedSurvey.root.children?.length} ${selectedSurvey.root.children[0].type}${
                              selectedSurvey.root.children?.length > 1 ? 's:' : ':'
                            }`}
                            <ol>
                              {selectedSurvey.root.children.map((node) => {
                                return <ul>{node.title}</ul>
                              })}
                            </ol>
                          </>
                        ) : (
                          'No nodes'
                        )}
                      </Typography>
                    </>
                  )}
                </Grid>
              </>
            ) : null}
          </>
        ) : (
          <Grid item sx={{ width: '100%' }}>
            <CenteredRadarSpinner />
            <Typography
              variant="subtitle1"
              sx={{ marginTop: '-10px', textAlign: 'center', color: theme.palette.text.secondary }}
            >
              {t('loading_surveys')}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default SurveyAdminPanel
