import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  styled,
  Typography,
} from '@mui/material'
import { StyledChip } from '../../../_components/Chip'
import { faEllipsisVertical, faPencil, faTrash } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import theme from '../../../theme'
import { Role, UserRole } from '../../../types/authTypes'
import { User } from '../../../types/userTypes'
import { useMutation, useQueryClient } from 'react-query'
import { deleteUser } from '../../../services/userApi'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import useDialog from '../../../hooks/useDialog'
import TruncatedText from '../../../_components/TruncatedText'

const UserCard = styled(Paper)({
  minHeight: '125px',
  padding: '30px 40px',
  dispaly: 'flex',
  flexDireciton: 'row',
  justifyContent: 'space-between',
  borderRadius: '9px',
  boxShadow: theme.blurredBoxShadow,
  display: 'inline-flex',
  width: '100%',
})

const InfoGridItem = styled(Grid)({
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexWrap: 'nowrap',
})

const InfoBox = styled(Box)({
  dispaly: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

const UserAvatar = styled(Avatar)({
  height: '54px',
  width: '54px',
  marginRight: '20px',
  backgroundColor: theme.palette.primary.main,
})

const EditMenu = styled(Menu)({
  '& .MuiPaper-root': {
    boxShadow: theme.buttonBoxShadow,
    borderRadius: '4px 0px 4px 4px',
  },
})

interface UserPaperProps {
  user: User
  callBack: (user?: User) => void
}

const shortenRoleName = (role: UserRole) => {
  switch (role.id) {
    case Role.Superadmin:
      return 'Super Admin'
    case Role.Admin:
      return 'Admin'
    case Role.Responder:
      return 'Responder'
    case Role.Viewer:
      return 'Viewer'
  }
}

export const UserPaper: React.FC<UserPaperProps> = ({ user, callBack }) => {
  const { t } = useTranslation(['users'])
  const queryClient = useQueryClient()
  const axiosPrivate = useAxiosPrivate()
  const dialog = useDialog()

  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLButtonElement) | null>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const { mutate: deleteMutate } = useMutation<unknown, unknown, string>({
    mutationKey: 'DeleteUser',
    mutationFn: async (id) => await deleteUser(axiosPrivate, id),
    onSuccess: () => {
      queryClient.refetchQueries(['GetOwnOrgUsers'])
    },
    onError: (error) => console.error(error),
  })

  const handleDeleteButton = () => {
    dialog.openDeleteDialog(`${user.first_name} ${user.last_name}`, () => deleteMutate(user.id))
  }

  return (
    <UserCard elevation={3}>
      <Grid container direction={'row'} justifyContent={'space-between'} sx={{ height: '100%', alignSelf: 'center' }}>
        <InfoGridItem item xs={9} container>
          <UserAvatar>
            {user.first_name && user.first_name[0]}
            {user.last_name && user.last_name[0]}
          </UserAvatar>
          <InfoBox>
            <Grid item>
              <TruncatedText title={`${user.first_name} ${user.last_name}`}>
                <Typography variant={'h6'}>
                  {user.first_name} {user.last_name}
                </Typography>
              </TruncatedText>
            </Grid>
            <Grid item>
              {!!user.job_title && (
                <TruncatedText title={user.job_title}>
                  <Typography variant="body2">{user.job_title}</Typography>
                </TruncatedText>
              )}
              <TruncatedText title={user.email}>
                <Typography variant="caption" sx={{ wordBreak: 'break-all' }}>
                  {user.email}
                </Typography>
              </TruncatedText>
            </Grid>
          </InfoBox>
        </InfoGridItem>
        <Grid item xs={3} container justifyContent={'flex-end'} alignItems={'flex-start'}>
          <StyledChip label={user.role ? shortenRoleName(user.role) : 'null'} />
        </Grid>
      </Grid>
      <Box sx={{ marginRight: '-30px', marginTop: '-20px' }}>
        <IconButton
          sx={{ width: '5px', height: '10px', color: theme.palette.primary.main }}
          disableRipple
          onClick={(event) => handleClick(event)}
        >
          <FontAwesomeIcon size="xs" icon={faEllipsisVertical} />
        </IconButton>
        <EditMenu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem
            onClick={() => {
              callBack(user)
              handleClose()
            }}
          >
            <ListItemIcon>
              <FontAwesomeIcon color={theme.palette.primary.main} icon={faPencil} />
            </ListItemIcon>
            <ListItemText sx={{ color: theme.palette.primary.main }}>{t('edit_details')}</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleDeleteButton()
              callBack()
              handleClose()
            }}
          >
            <ListItemIcon>
              <FontAwesomeIcon color={theme.palette.error.main} icon={faTrash} />
            </ListItemIcon>
            <ListItemText sx={{ color: theme.palette.error.main }}>{t('delete_user')}</ListItemText>
          </MenuItem>
        </EditMenu>
      </Box>
    </UserCard>
  )
}
