import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import useAuth from '../hooks/useAuth'
import { CenteredRadarSpinner } from './Spinner'

const RequireAuth = ({ allowedRoles }: { allowedRoles: number[] }) => {
  const context = useAuth()
  const location = useLocation()

  return context?.user?.roles && allowedRoles?.filter((a) => context?.user?.roles?.map((r) => r.id).includes(a)) ? (
    <Outlet />
  ) : context.user ? (
    <Navigate to="/" />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  )
}

export default RequireAuth
