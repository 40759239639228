import { yupResolver } from '@hookform/resolvers/yup'
import { Box, FormControl, FormHelperText, Grid, InputLabel, MenuItem, TextField, Typography } from '@mui/material'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { postEditUser } from '../../../services/userApi'
import { UpdateUserRequest, User } from '../../../types/userTypes'
import { AddUserSchema } from '../../../validationSchemas/userSchema'
import { PrimaryButton, SecondaryButton } from '../../../_components/Buttons'
import { SelectControl } from '../../../_components/Select'
import { TextFieldControl } from '../../../_components/TextField'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { UserEditorBox } from './addUserForm'
import { useErrorToast, useSuccessToast } from '../../../hooks/useToast'

interface EditUserFormProps {
  user: User
  closeEditor: () => void
}

export const EditUserForm: React.FC<EditUserFormProps> = ({ user, closeEditor }) => {
  const { t } = useTranslation(['users', 'common'])
  const axiosPrivate = useAxiosPrivate()
  const queryClient = useQueryClient()

  const {
    control,
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
    clearErrors,
  } = useForm<UpdateUserRequest>({
    resolver: yupResolver(AddUserSchema),
    defaultValues: { ...user, role_ids: [] },
  })

  const { mutate: editUser, isLoading } = useMutation<unknown, unknown, UpdateUserRequest>({
    mutationFn: async (requestData) => await postEditUser(axiosPrivate, user.id, requestData),
    onSuccess: () => {
      queryClient.invalidateQueries(['GetOwnOrgUsers'])
      queryClient.invalidateQueries(['GetEditUser', user.id])
      useSuccessToast(t('messages.user_updated'))
      closeEditor()
    },
    onError: () => {
      useErrorToast(`${t('messages.user_update_error')} ${t('common:contact_support')}`)
    },
  })

  const handleUserSubmit = (formData: UpdateUserRequest) => {
    editUser(formData)
  }

  const isDisabled = isLoading || isSubmitting

  return (
    <UserEditorBox>
      <form onSubmit={handleSubmit(handleUserSubmit, (error) => console.error(error))}>
        <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography variant="h5">
            {t('editing')} {user.first_name} {user.last_name}
          </Typography>
          <Box sx={{ display: 'inline-flex' }}>
            <SecondaryButton onClick={closeEditor}>{t('cancel')}</SecondaryButton>
            <PrimaryButton sx={{ marginLeft: '10px' }} type="submit" variant="contained">
              {t('save')}
            </PrimaryButton>
          </Box>
        </Grid>
        <Grid
          container
          spacing={'20px'}
          direction={'row'}
          justifyContent={'flex-start'}
          alignItems={'center'}
          sx={{ paddingTop: '20px' }}
        >
          <Grid item xs={4}>
            <TextFieldControl
              control={control}
              name="first_name"
              type="text"
              label={t('first_name')}
              disabled={isDisabled}
              autoComplete="first_name"
              errorText={errors.first_name?.message}
              onFocus={() => clearErrors('first_name')}
            />
          </Grid>
          <Grid item xs={4}>
            <TextFieldControl
              control={control}
              name="last_name"
              type="text"
              label={t('last_name')}
              disabled={isDisabled}
              autoComplete="last_name"
              errorText={errors.last_name?.message}
              onFocus={() => clearErrors('last_name')}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={'20px'}
          direction={'row'}
          justifyContent={'flex-start'}
          alignItems={'center'}
          sx={{ paddingTop: '20px' }}
        >
          <Grid item xs={4}>
            <TextFieldControl
              control={control}
              name="job_title"
              type="text"
              label={t('job_title')}
              disabled={isDisabled}
              autoComplete="job_title"
              errorText={errors.job_title?.message}
              onFocus={() => clearErrors('job_title')}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl variant={'standard'} sx={{ width: '100%' }} error={!!errors.role}>
              <InputLabel id="user_role">{t('role')}</InputLabel>
              <SelectControl
                {...register('role')}
                control={control}
                name="role"
                labelId={'user_role'}
                disabled={isDisabled}
                autoComplete="role"
                errorText={errors.role?.message}
                onFocus={() => clearErrors('role')}
              >
                <MenuItem value={''}>
                  <em>{t('none')}</em>
                </MenuItem>
                <MenuItem value={2}>{t('admin_desc')}</MenuItem>
                <MenuItem value={3}>{t('editor_desc')}</MenuItem>
                <MenuItem value={4}>{t('viewer_desc')}</MenuItem>
              </SelectControl>
              <FormHelperText sx={!!!errors.role ? { display: 'none' } : {}}>Required</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={'20px'}
          direction={'row'}
          justifyContent={'flex-start'}
          alignItems={'center'}
          sx={{ paddingTop: '20px' }}
        >
          <Grid item xs={6}>
            <TextField
              sx={{ width: '100%' }}
              variant={'standard'}
              disabled
              type="email"
              label={t('email')}
              value={user.email}
            />
          </Grid>
        </Grid>
      </form>
    </UserEditorBox>
  )
}
