import { Box, FormControlLabel, styled } from '@mui/material'

export const ImageHolder = styled(Box)({
  alignSelf: 'center',
  textAlign: 'center',
  alignItems: 'center',
  position: 'relative',
  padding: '3px',
  border: '1px solid #cccccc',
  borderRadius: '3px',
  maxHeight: '132px',
  height: '132px',
  marginTop: '-52px',
  img: {
    objectFit: 'cover',
    maxHeight: '90%',
    maxWidth: '90%',
  },
})

export const ThumbnailHolder = styled(Box)({
  alignSelf: 'center',
  textAlign: 'center',
  position: 'relative',
  padding: '3px',
  border: '1px solid #cccccc',
  borderRadius: '3px',
  maxHeight: '58px',
  height: '58px',
  img: {
    margin: 0,
    objectFit: 'cover',
    maxHeight: '50px',
  },
})

export const HiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

export const MissingImage = styled('p')({
  height: '100%',
  display: 'inline-block',
  color: 'darkgray',
})

export const ImageMimeType = /image\/(png|jpg|jpeg)/i

export const AdminFormControlLabel = styled(FormControlLabel)({
  '& .MuiTypography-root': {
    fontSize: '12px',
    textTransform: 'capitalize',
    display: 'inline-block',
    // letterSpacing: '0.15px',
  },
})
