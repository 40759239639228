import React, { createContext, ReactNode } from 'react'
import { useCookies } from 'react-cookie'
import { Role } from '../types/authTypes'
import { User } from '../types/userTypes'

export interface AuthContext {
  token: string | undefined
  setToken: (token: string | undefined) => void
  user: User | undefined
  setUser: (user: User | undefined) => void
  isUserInRole: (role: Role) => boolean
  isViewer: boolean
  isResponder: boolean
  isAdmin: boolean
  isSuperAdmin: boolean
}

const AuthStateContext = createContext<AuthContext | undefined>(undefined)

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [cookies, setCookie] = useCookies(['user', 'token'])

  const token: string | undefined = cookies['token'] === 'undefined' ? undefined : cookies['token']
  const user: User | undefined = cookies['user']

  const setToken = (token: string | undefined) =>
    setCookie('token', token, { path: '/', sameSite: 'strict', secure: true })
  const setUser = (user: User | undefined) => setCookie('user', user, { path: '/', sameSite: 'strict', secure: false })

  const isUserInRole = (role: Role) => {
    return user?.roles?.find((r) => r.id == role.valueOf()) != undefined
  }

  return (
    <AuthStateContext.Provider
      value={{
        token,
        setToken,
        user,
        setUser,
        isUserInRole,
        isViewer: isUserInRole(Role.Viewer),
        isResponder: isUserInRole(Role.Responder),
        isAdmin: isUserInRole(Role.Admin),
        isSuperAdmin: isUserInRole(Role.Superadmin),
      }}
    >
      {children}
    </AuthStateContext.Provider>
  )
}

export default AuthStateContext
