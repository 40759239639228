import styled from '@emotion/styled'
import { Box } from '@mui/material'
import React from 'react'
import theme from '../theme'

export const ScrollableContainer = styled(Box)({
  width: '100%',
  minHeight: '200px',
  overflowY: 'auto',
  overflowX: 'hidden',
  '&::-webkit-scrollbar': {
    width: 8,
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: `inset 0 0 6px #D9D9D9`,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.primary.main,
  },
})
