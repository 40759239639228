import { yupResolver } from '@hookform/resolvers/yup'
import { Box, FormControl, FormHelperText, Grid, InputLabel, MenuItem, styled, Typography } from '@mui/material'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { postAddUser } from '../../../services/userApi'
import theme from '../../../theme'
import { AddUserRequest } from '../../../types/userTypes'
import { AddUserSchema } from '../../../validationSchemas/userSchema'
import { PrimaryButton, SecondaryButton } from '../../../_components/Buttons'
import { SelectControl } from '../../../_components/Select'
import { TextFieldControl } from '../../../_components/TextField'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { useErrorToast, useSuccessToast } from '../../../hooks/useToast'

export const UserEditorBox = styled(Box)({
  width: '100%',
  padding: '40px',
  border: `1px solid ${theme.palette.secondary.main}`,
  borderRadius: '8px',
})

interface AddUserFormProps {
  closeEditor: () => void
}

const defaultValues = {
  first_name: '',
  last_name: '',
  email: '',
  job_title: '',
  role_ids: [],
}

export const AddUserForm: React.FC<AddUserFormProps> = ({ closeEditor }) => {
  const { t } = useTranslation(['users', 'common'])
  const queryClient = useQueryClient()
  const axiosPrivate = useAxiosPrivate()

  const {
    control,
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
    clearErrors,
  } = useForm<AddUserRequest>({ resolver: yupResolver(AddUserSchema), defaultValues })

  const { mutate: addUser, isLoading: isLoadingAddUser } = useMutation<unknown, unknown, AddUserRequest>({
    mutationKey: 'PostAddUser',
    mutationFn: async (user) => await postAddUser(axiosPrivate, user),
    onSuccess: () => {
      queryClient.invalidateQueries(['GetOwnOrgUsers'])
      useSuccessToast(t('messages.user_created'))
      closeEditor()
    },
    onError: (error) => {
      console.error('Something went wrong?')
      queryClient.invalidateQueries(['GetOwnOrgUsers'])
      useErrorToast(`${t('messages.user_create_error')} ${t('common:contact_support')}`)
    },
  })

  const handleUserSubmit = (formData: AddUserRequest) => {
    addUser(formData)
  }

  const isDisabled = isSubmitting || isLoadingAddUser

  return (
    <UserEditorBox>
      <form onSubmit={handleSubmit(handleUserSubmit, (error) => console.error(error))}>
        <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography variant="h5">{t('add_new_user')}</Typography>
          <Box sx={{ display: 'inline-flex' }}>
            <SecondaryButton onClick={closeEditor}>{t('cancel')}</SecondaryButton>
            <PrimaryButton sx={{ marginLeft: '10px' }} type="submit" variant="contained">
              {t('add_user')}
            </PrimaryButton>
          </Box>
        </Grid>
        <Grid
          container
          spacing={'20px'}
          direction={'row'}
          justifyContent={'flex-start'}
          alignItems={'center'}
          sx={{ paddingTop: '20px' }}
        >
          <Grid item xs={4}>
            <TextFieldControl
              control={control}
              name="first_name"
              type="text"
              label={t('first_name')}
              disabled={isDisabled}
              autoComplete="first_name"
              errorText={errors.first_name?.message}
              onFocus={() => clearErrors('first_name')}
            />
          </Grid>
          <Grid item xs={4}>
            <TextFieldControl
              control={control}
              name="last_name"
              type="text"
              label={t('last_name')}
              disabled={isDisabled}
              autoComplete="last_name"
              errorText={errors.last_name?.message}
              onFocus={() => clearErrors('last_name')}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={'20px'}
          direction={'row'}
          justifyContent={'flex-start'}
          alignItems={'center'}
          sx={{ paddingTop: '20px' }}
        >
          <Grid item xs={4}>
            <TextFieldControl
              control={control}
              name="job_title"
              type="text"
              label={t('job_title')}
              disabled={isDisabled}
              autoComplete="job_title"
              errorText={errors.job_title?.message}
              onFocus={() => clearErrors('job_title')}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl variant={'standard'} sx={{ width: '100%' }} error={!!errors.role}>
              <InputLabel id="user_role">{t('role')}</InputLabel>
              <SelectControl
                {...register('role')}
                control={control}
                name="role"
                labelId={'user_role'}
                disabled={isDisabled}
                autoComplete="role"
                errorText={errors.role?.message}
                onFocus={() => clearErrors('role')}
              >
                <MenuItem value={-1}>
                  <em>{t('none')}</em>
                </MenuItem>
                <MenuItem value={2}>{t('admin_desc')}</MenuItem>
                <MenuItem value={3}>{t('editor_desc')}</MenuItem>
                <MenuItem value={4}>{t('viewer_desc')}</MenuItem>
              </SelectControl>
              <FormHelperText sx={!!!errors.role ? { display: 'none' } : {}}>Required</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={'20px'}
          direction={'row'}
          justifyContent={'flex-start'}
          alignItems={'center'}
          sx={{ paddingTop: '20px' }}
        >
          <Grid item xs={6}>
            <TextFieldControl
              control={control}
              name="email"
              type="email"
              label={t('email')}
              disabled={isDisabled}
              autoComplete="email"
              errorText={errors.email?.message}
              onFocus={() => clearErrors('email')}
            />
          </Grid>
        </Grid>
      </form>
    </UserEditorBox>
  )
}
