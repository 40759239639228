import React from 'react'
import { Avatar, Box, styled, Tooltip, Typography, useMediaQuery } from '@mui/material'
import theme from '../theme'
import { useTranslation } from 'react-i18next'
import { faEye, faLock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const IconBadge = styled(Box)({
  backgroundColor: theme.complimentary,
  color: theme.white,
  textTransform: 'uppercase',
  borderRadius: '13px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'default',
})

export const ViewOnlyBadge = () => {
  const { t } = useTranslation(['common'])
  const xsScreen = useMediaQuery('(max-width:1080px)')
  return (
    <IconBadge padding={xsScreen ? '15px 13px' : '12px 15px'} flexDirection={xsScreen ? 'column' : 'row'}>
      <FontAwesomeIcon icon={faEye} size={xsScreen ? '2x' : 'lg'} />
      <Typography
        variant="h6"
        sx={xsScreen ? { mt: '15px' } : { ml: '10px' }}
        textAlign={xsScreen ? 'center' : 'start'}
      >
        {t('view_only')}
      </Typography>
    </IconBadge>
  )
}

export const LockedBadge = () => {
  const { t } = useTranslation(['surveyQuestions'])
  const lgScreen = useMediaQuery('(min-width:1600px)')
  const xsScreen = useMediaQuery('(max-width:1000px)')
  return (
    <Tooltip title={t('tooltips.page_locked')}>
      <IconBadge
        padding={lgScreen ? '15px 30px' : xsScreen ? '25px 10px' : '10px 25px'}
        flexDirection={xsScreen ? 'column' : 'row'}
      >
        <FontAwesomeIcon icon={faLock} size={lgScreen ? '2x' : 'lg'} />
        <Box sx={xsScreen ? { mt: '15px' } : { ml: '20px' }}>
          <Typography
            variant={lgScreen ? 'h6' : 'subtitle2'}
            textAlign={xsScreen ? 'center' : 'start'}
            width={xsScreen ? 'min-content' : 'unset'}
          >
            {t('page_locked')}
          </Typography>
          {lgScreen && (
            <Typography variant="caption" letterSpacing="0.4px" textTransform="none">
              {t('page_locked_description')}
            </Typography>
          )}
        </Box>
      </IconBadge>
    </Tooltip>
  )
}

interface EditingBadgeProps {
  firstName: string
  lastName: string
}

export const EditingBadge = ({ firstName, lastName }: EditingBadgeProps) => {
  const { t } = useTranslation(['surveyQuestions'])
  const lgScreen = useMediaQuery('(min-width:1600px)')
  const xsScreen = useMediaQuery('(max-width:1000px)')

  return (
    <IconBadge padding={lgScreen ? '17px 16px' : '7px 9px'} flexDirection={xsScreen ? 'column' : 'row'}>
      <Tooltip title={`${firstName} ${lastName}`} followCursor>
        <Avatar sx={{ bgcolor: theme.white, color: theme.complimentary }}>
          <Typography variant="h6" textTransform="uppercase">{`${firstName[0]}${lastName[0]}`}</Typography>
        </Avatar>
      </Tooltip>
      <Box sx={{ ml: '15px' }}>
        <Typography variant="h6" letterSpacing="0.15px">
          {t('currently_editing')}
        </Typography>
      </Box>
    </IconBadge>
  )
}
