import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, ButtonBase, Grid, styled, Typography } from '@mui/material'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import useSurvey from '../../hooks/useSurvey'
import { AuthContext } from '../../providers/AuthProvider'
import { SponsorLogo } from '../Images'
import DefaultContainer from './DefaultContainer'
import NavMenu from './NavMenu'
import SideImageContainer from './SideImageContainer'

const ImageBackground = styled(Box)({
  position: 'relative',
  width: '100%',
  minHeight: '100vh',
  padding: '90px 100px 100px',
  '&::before': {
    width: '100%',
    minHeight: '100%',
    backgroundImage: `url(/images/login-background.png)`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    filter: 'opacity(0.5)',
    content: '" "',
    position: 'absolute',
    left: 0,
    top: 0,
    boxSizing: 'border-box',
    zIndex: 0,
  },
})

interface OrganizationLogo {
  url: string
  width: string | undefined
  height: string | undefined
  marginTopValue: number | undefined
}

interface MainLayoutProps {
  footer?: boolean | undefined
  backgroundImage?: boolean | undefined
  sideImage?: boolean | undefined
}

export interface ContainerProps {
  companyLogoContent: React.ReactNode
  drawerToggleContent: React.ReactNode
  footerContent: React.ReactNode
  hasFooter: boolean
}

interface CompanyLogoProps {
  organizationLogo: OrganizationLogo
  context: AuthContext
}

const CompanyLogoContent = ({ organizationLogo, context }: CompanyLogoProps) => {
  const navigate = useNavigate()
  const { survey } = useSurvey()

  return (
    <>
      <ButtonBase
        sx={{
          height: organizationLogo?.height,
          width: organizationLogo?.width,
          mt: `${organizationLogo?.marginTopValue}px`,
        }}
        onClick={() => navigate('/')}
        disableRipple
      >
        <Box
          component="img"
          src={organizationLogo?.url}
          sx={{ maxHeight: organizationLogo?.height ?? '60px', maxWidth: organizationLogo?.width ?? '200px' }}
        />
      </ButtonBase>
      <Typography variant="overline" ml="20px">
        {survey
          ? `${survey.name} ${
              survey.starts_at ? `| ${moment(survey.starts_at).format('MMMM YYYY')}` : ''
            } ${`| ${context.user?.organisation?.handle}-${survey.id}`}`
          : ''}
      </Typography>
    </>
  )
}

interface DrawerToggleContentProps {
  toggleDrawer: () => void
}

const DrawerToggleContent = ({ toggleDrawer }: DrawerToggleContentProps) => {
  return (
    <Box sx={{ cursor: 'pointer' }}>
      <FontAwesomeIcon icon={faBars} onClick={toggleDrawer} />
    </Box>
  )
}

const FooterContent = () => {
  const { t } = useTranslation(['common'])
  return (
    <Grid container sx={{ width: '100%' }}>
      <Grid item xs={4} />
      <Grid item container xs={4} direction={'row'} justifyContent={'center'} flexWrap="nowrap" whiteSpace="nowrap">
        <Typography variant="subtitle2" sx={{ mr: '10px' }}>
          {t('footer.sponsored_by')}
        </Typography>
        <SponsorLogo component="img" src="/images/sponsor-logo.png" />
      </Grid>
    </Grid>
  )
}

const MainLayout = ({ footer, backgroundImage, sideImage }: MainLayoutProps) => {
  const context = useAuth()
  const [organizationLogo, setOrganizationLogo] = useState<OrganizationLogo>()
  const [open, setOpen] = useState<boolean>(false)

  const toggleDrawer = () => {
    setOpen(!open)
  }

  useEffect(() => {
    let logoURL = ''
    let width: string | undefined = '180px'
    let height: string | undefined = '60px'
    let marginTopValue = -28
    if (context.user?.organisation?.thumbnail) {
      logoURL = `/images/organisation/${context.user.organisation.thumbnail}`
      width = context.user.organisation.thumbnail_width ? `${context.user.organisation.thumbnail_width}px` : undefined
      height = context.user.organisation.thumbnail_height
        ? `${context.user.organisation.thumbnail_height}px`
        : undefined

      if (context.user?.organisation?.thumbnail_height && context.user?.organisation?.thumbnail_height > 60) {
        marginTopValue += context.user.organisation.thumbnail_height - 60
      }
    } else {
      switch (context?.user?.organisation?.handle) {
        case 'qantas':
          logoURL = '/images/organisation/Qantas-horizontal-300px.png'
          marginTopValue = -25
          break
        case 'pia':
          logoURL = '/images/organisation/PIA-Horizontal-300px.png'
          marginTopValue = -20
          break
        case 'ba':
          logoURL = '/images/organisation/BA-Horizontal-300px.png'
          break
        case 'sirius':
        default:
          logoURL = '/images/sa-logo.png'
          width = '130px'
          height = '58px'
          marginTopValue = -15
          break
      }
    }
    setOrganizationLogo({ url: logoURL, width, height, marginTopValue })
  }, [context?.user?.organisation?.handle])

  return organizationLogo ? (
    <>
      {sideImage ? (
        <SideImageContainer
          companyLogoContent={<CompanyLogoContent organizationLogo={organizationLogo} context={context} />}
          drawerToggleContent={<DrawerToggleContent toggleDrawer={toggleDrawer} />}
          footerContent={<FooterContent />}
          hasFooter={footer ?? true}
        />
      ) : (
        <>
          {backgroundImage && (
            <ImageBackground>
              <DefaultContainer
                companyLogoContent={<CompanyLogoContent organizationLogo={organizationLogo} context={context} />}
                drawerToggleContent={<DrawerToggleContent toggleDrawer={toggleDrawer} />}
                footerContent={<FooterContent />}
                hasFooter={footer ?? true}
                backgroundImage={backgroundImage ?? false}
              />
            </ImageBackground>
          )}
          {!backgroundImage && (
            <DefaultContainer
              companyLogoContent={<CompanyLogoContent organizationLogo={organizationLogo} context={context} />}
              drawerToggleContent={<DrawerToggleContent toggleDrawer={toggleDrawer} />}
              footerContent={<FooterContent />}
              hasFooter={footer ?? true}
              backgroundImage={backgroundImage ?? false}
            />
          )}
        </>
      )}
      <NavMenu open={open} toggleDrawer={toggleDrawer} />
    </>
  ) : null
}

export default MainLayout
