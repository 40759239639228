import { Box, Typography } from '@mui/material'
import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useNeedHeightAdjustments } from '../../../hooks/useNeedHeightAdjustments'
import theme from '../../../theme'
import { SponsorLogo } from '../../../_components/Images'

const FormContainer = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation(['auth', 'common'])
  const needHeightAdjustments = useNeedHeightAdjustments()

  return (
    <Box sx={{ alignItems: 'center', height: '100vh' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          component="img"
          sx={{ height: '119px', width: '264px', marginBottom: '45px' }}
          alt="Sirius Aviation Logo"
          src="/images/sa-logo.png"
        />
        <Typography sx={{ mb: '25px' }} variant="h3">
          {t('titles.welcome')}
        </Typography>
        {children}
        <Box sx={{ display: 'inline-flex', position: 'fixed', bottom: needHeightAdjustments ? '25px' : '100px' }}>
          <Box mr="25px" display="inline-flex">
            <Typography sx={{ mr: '10px' }}>{t('common:footer.sponsored_by')}</Typography>
            <SponsorLogo component="img" mt="3px" src="/images/sponsor-logo.png" />
          </Box>
          {/* <Typography mr="25px" color={theme.tertiary1}>
            |
          </Typography>
          <Typography mr="25px">{t('footer.GDPR')}</Typography>
          <Typography mr="25px">{t('footer.privacy_policy')}</Typography>
          <Typography>{t('footer.T&Cs')}</Typography> */}
        </Box>
      </Box>
    </Box>
  )
}

export default FormContainer
