import { faCalendar, faCircleInfo, faEye, faGear, faPen, faSave, faUsersGear } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Card, CardActionArea, CardContent, Grid, styled, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import { useNeedHeightAdjustments } from '../../hooks/useNeedHeightAdjustments'
import useSurvey from '../../hooks/useSurvey'
import { useWindowSize } from '../../hooks/useWindowSize'
import theme from '../../theme'
import { PrimaryButton, SecondaryButton } from '../../_components/Buttons'
import TierInfoCard, { TierInfoCardProps } from './components/TierInfoCard'
import { Node, SurveyEditor, SurveyEditorResponse } from '../../types/surveyTypes'
import { Role } from '../../types/authTypes'
import { ViewOnlyBadge } from '../../_components/Badge'
import { CenteredRadarSpinner } from '../../_components/Spinner'
import moment from 'moment'
import { EventType, SurveyUpdatedProps, useSocket } from '../../hooks/useSocket'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import { getOwnOrgUsers } from '../../services/userApi'
import { useQuery } from 'react-query'
import { GetOwnOrgUsersResponse, User } from '../../types/userTypes'
import { fetchEditorList } from '../../services/surveyApi'
import { InfoCard, InfoCardContent } from '../../_components/cards/InfoCard'
import { getFilenameFromContentDisposition } from '../../services/axiosHelper'

const layoutInRows = process.env.REACT_APP_LAYOUT_IN_ROWS == 'true'

const UsersCountGrid = styled(Grid)({
  flexGrow: 1,
  flexDirection: 'column',
  textAlign: 'center',
  alignContent: 'start',
  padding: '20px 20px 20px 30px',
  height: '100%',
  margin: 0,
  [theme.breakpoints.up(1600)]: {
    alignContent: 'center',
    flexDirection: 'row',
  },
})

const UsersCountGridItem = styled(Grid)({
  alignItems: 'center',
  display: 'inline-flex',
  flexDirection: 'row',
  [theme.breakpoints.up(1600)]: {
    display: 'flex',
    flexDirection: 'column',
  },
  '.MuiGrid-item': {
    padding: 0,
  },
})

const ManageUsersActionArea = styled(CardActionArea)({
  color: theme.secondary1,
  backgroundColor: theme.white,
  maxWidth: '160px',
  padding: '20px',
  '@media (min-width:1600px)': {
    padding: '40px 20px',
  },
  borderRadius: '9px',
  height: '100%',
  textAlign: 'center',
})

const BadgeContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'end',
  marginTop: '-40px',
})

const Dashboard = () => {
  const { user, token, isUserInRole } = useAuth()
  const { t } = useTranslation(['dashboard', 'common'])
  const axiosPrivate = useAxiosPrivate()
  const navigate = useNavigate()
  const { height } = useWindowSize()
  const needHeightAdjustments = useNeedHeightAdjustments(980)
  const lgScreen = useMediaQuery('(min-width:1600px)')
  const above1000Screen = useMediaQuery('(min-width:1000px)')
  const { survey } = useSurvey()
  const [isSurveySubmitted, setIsSurveySubmitted] = useState<boolean>()
  const [downloading, setDownloading] = useState<boolean>(false)
  const [currentEditorsList, setCurrentEditorsList] = useState<SurveyEditor[]>()
  const [editorToAdd, setEditorToAdd] = useState<SurveyEditor>()
  const [editorToRemove, setEditorToRemove] = useState<SurveyEditor>()

  const paddingValue = useMemo(() => {
    let value = 0
    if (height && height <= 900) value = 40
    if (height && height > 900 && height < 1080) value = 60
    if (height && height >= 1080) value = 100
    return value
  }, [height && height < 900, height && height < 1080])

  const tiers = useMemo(() => {
    if (!!survey.root && !!survey.root.children && survey.root.children.length > 0) {
      const tiers = survey.root.children.map<TierInfoCardProps>((tier: Node, tierIndex) => {
        const allQuestions = tier.children
          ? tier.children
              .flatMap((s) => s.children)
              .filter((q) => !!q?.inputs?.length || !!q?.children?.filter((sq) => !!sq.inputs?.length).length)
          : []
        const allQuestionsCount = allQuestions.length
        const lockedQuestionsCount = allQuestions.filter((q) => !!q?.locked_at).length
        return {
          id: tier.id,
          number: tierIndex + 1,
          title: tier.title,
          lockedQuestionsCount,
          allQuestionsCount,
          viewOnly: !!survey.submitted_at || !!isSurveySubmitted,
        }
      })
      return tiers
    } else return []
  }, [survey, isSurveySubmitted])

  useEffect(() => {
    if (survey.submitted_at) setIsSurveySubmitted(!!survey.submitted_at)
  }, [survey.submitted_at])

  const { data: allUsers } = useQuery<GetOwnOrgUsersResponse>({
    queryKey: 'GetOwnOrgUsers',
    queryFn: () => getOwnOrgUsers(axiosPrivate),
    enabled: !!token,
    onError: (error) => console.error(error),
  })

  const editors = useMemo(() => {
    return allUsers?.data.filter((user) => user.roles?.find((r) => r.id === Role.Responder.valueOf()))
  }, [allUsers])

  const viewers = useMemo(() => {
    return allUsers?.data.filter((user) => user.roles?.find((r) => r.id === Role.Viewer.valueOf()))
  }, [allUsers])

  const {} = useQuery<SurveyEditorResponse>({
    queryKey: ['fetchEditorList'],
    queryFn: () => fetchEditorList(axiosPrivate, survey.id),
    enabled: !!token && !!survey.id,
    onSuccess: (response) => {
      setCurrentEditorsList(response.data)
    },
  })

  useEffect(() => {
    if (editorToAdd) setCurrentEditorsList([...(currentEditorsList || []), editorToAdd])
    setEditorToAdd(undefined)
  }, [editorToAdd])

  useEffect(() => {
    if (editorToRemove)
      setCurrentEditorsList(
        currentEditorsList?.filter(
          (e) => e.user_id !== editorToRemove.user_id && e.node_id !== editorToRemove.node_id,
        ) || [],
      )
    setEditorToRemove(undefined)
  }, [editorToRemove])

  const dueDate = useMemo(() => {
    const endAtMoment = moment(survey.ends_at)
    const startAtMoment = moment(survey.starts_at)
    if (survey.ends_at !== null) {
      return endAtMoment
    } else if (survey.starts_at !== null) {
      return startAtMoment.add(1, 'week')
    } else {
      return moment().add(1, 'week')
    }
  }, [survey.starts_at, survey.ends_at])

  const dueDateCardColor = useMemo(() => {
    if (dueDate) {
      const ONE_DAY = 1000 * 3600 * 24
      const FIVE_DAYS = ONE_DAY * 5
      const differenceInDays = Math.abs(moment().diff(dueDate))
      if (differenceInDays > FIVE_DAYS) return theme.palette.primary.main
      if (differenceInDays <= FIVE_DAYS && differenceInDays > ONE_DAY) return theme.palette.warning.main
      if (differenceInDays <= ONE_DAY) return theme.palette.error.main
    }
  }, [dueDate])

  const handleInstructionsClick = () => {
    navigate('instructions')
  }

  const handleManageUsersClick = () => {
    navigate('/users')
  }

  const handleAdminClick = () => {
    navigate('/admin')
  }

  useSocket({
    type: EventType.SurveySubmitted,
    callBack: (info: SurveyUpdatedProps) => {
      if (info.user.id != user?.id) {
        setIsSurveySubmitted(true)
      }
    },
  })

  useSocket({
    type: EventType.SurveyEditStart,
    callBack: (info: SurveyUpdatedProps) => {
      if (info.user.id !== user?.id) {
        setEditorToAdd({
          survey_id: info.metadata.surveyId,
          tier_id: info.metadata.tierId,
          node_id: info.metadata.questionId,
          user_id: info.user.id,
          first_name: info.user.first_name,
          last_name: info.user.last_name,
        })
      }
    },
  })

  useSocket({
    type: EventType.SurveyEditFinish,
    callBack: (info: SurveyUpdatedProps) => {
      if (info.user.id !== user?.id) {
        setEditorToRemove({
          survey_id: info.metadata.surveyId,
          tier_id: info.metadata.tierId,
          node_id: info.metadata.questionId,
          user_id: info.user.id,
          first_name: info.user.first_name,
          last_name: info.user.last_name,
        })
      }
    },
  })

  return (
    <Box
      sx={{
        paddingTop: `${paddingValue}px`,
        marginBottom: `${paddingValue}px`,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {isUserInRole(Role.Viewer) && (
        <BadgeContainer>
          <ViewOnlyBadge />
        </BadgeContainer>
      )}
      <Typography variant="h4" sx={{ marginBottom: '20px' }}>
        {t('welcome')}, {user?.first_name}!
      </Typography>
      <Box mb={needHeightAdjustments ? '30px' : '60px'}>
        <Grid container sx={{ flexGrow: 1 }} spacing={2}>
          <Grid item xs={12} sm={above1000Screen ? 4 : 6} md={4}>
            <InfoCard sx={{ backgroundColor: dueDateCardColor ?? 'black' }}>
              <InfoCardContent>
                <FontAwesomeIcon icon={faCalendar} size={lgScreen ? '3x' : '2x'} />
                <Box sx={{ ml: '20px' }}>
                  <Typography variant="overline" lineHeight="18px">
                    {t('evaluation_due_by')}
                  </Typography>
                  <Typography variant={lgScreen ? 'h4' : 'h6'}>{dueDate.format('DD/MM/YYYY')}</Typography>
                </Box>
              </InfoCardContent>
            </InfoCard>
          </Grid>
          {isUserInRole(Role.Admin) && (
            <Grid item xs={12} sm={above1000Screen ? 4 : 6} md={4}>
              <InfoCard
                sx={{
                  background: `linear-gradient(to right, ${theme.secondary1} 90%, ${theme.white})`,
                  boxShadow: theme.blurredBoxShadow,
                  padding: 0,
                }}
              >
                <InfoCardContent sx={{ width: '100%', height: '100%', padding: 0 }}>
                  <UsersCountGrid container>
                    <UsersCountGridItem item md={6}>
                      <FontAwesomeIcon icon={faPen} size={lgScreen ? '2x' : '1x'} width={lgScreen ? '30px' : '18px'} />
                      <Typography variant="h6" ml={lgScreen ? 0 : '15px'} mt={lgScreen ? '5px' : 0} whiteSpace="nowrap">
                        {editors &&
                          editors.length >= 0 &&
                          `${editors.length} ${
                            editors.length > 1 || editors.length === 0 ? t('editors') : t('editor')
                          }`}
                      </Typography>
                    </UsersCountGridItem>
                    <UsersCountGridItem item md={6}>
                      <FontAwesomeIcon icon={faEye} size={lgScreen ? '2x' : '1x'} width={lgScreen ? '30px' : '18px'} />
                      <Typography variant="h6" ml={lgScreen ? 0 : '15px'} mt={lgScreen ? '5px' : 0} whiteSpace="nowrap">
                        {viewers &&
                          viewers.length >= 0 &&
                          `${viewers.length} ${
                            viewers.length > 1 || viewers.length === 0 ? t('viewers') : t('viewer')
                          }`}
                      </Typography>
                    </UsersCountGridItem>
                  </UsersCountGrid>
                </InfoCardContent>
                <ManageUsersActionArea onClick={handleManageUsersClick}>
                  <FontAwesomeIcon icon={faUsersGear} size="2x" />
                  <Typography variant="h6" mt="5px">
                    {t('manage_users')}
                  </Typography>
                </ManageUsersActionArea>
              </InfoCard>
            </Grid>
          )}
          {isUserInRole(Role.Admin) ? (
            <Grid item xs={12} sm={above1000Screen ? 4 : 6} md={4}>
              <InfoCard
                sx={{
                  background: `linear-gradient(to right, ${theme.secondary2} 90%, ${theme.white})`,
                  boxShadow: theme.blurredBoxShadow,
                  padding: 0,
                }}
              >
                <InfoCardContent sx={{ width: '100%', height: '100%', padding: 0 }}>
                  <UsersCountGrid container>
                    <UsersCountGridItem item md={6}></UsersCountGridItem>
                    <UsersCountGridItem item md={6}></UsersCountGridItem>
                  </UsersCountGrid>
                </InfoCardContent>
                <ManageUsersActionArea onClick={handleAdminClick}>
                  <FontAwesomeIcon icon={faGear} size="2x" />
                  <Typography variant="h6" mt="5px">
                    {t('administration')}
                  </Typography>
                </ManageUsersActionArea>
              </InfoCard>
            </Grid>
          ) : (
            <Grid item xs={12} sm={above1000Screen ? 4 : 6} md={4}>
              <InfoCard sx={{ backgroundColor: theme.secondary2, p: 0 }}>
                <CardActionArea sx={{ height: '100%', p: '20px 20px 20px 30px' }} onClick={handleInstructionsClick}>
                  <CardContent sx={{ padding: 0, display: 'inline-flex', alignItems: 'center' }}>
                    <FontAwesomeIcon icon={faCircleInfo} size={lgScreen ? '3x' : '2x'} />
                    <Box sx={{ ml: '20px' }}>
                      <Typography variant={lgScreen ? 'h5' : 'h6'}>{t('instructions')}</Typography>
                      <Typography variant={lgScreen ? 'subtitle1' : 'subtitle2'}>
                        {t('instructions_description')}
                      </Typography>
                    </Box>
                  </CardContent>
                </CardActionArea>
              </InfoCard>
            </Grid>
          )}
        </Grid>
      </Box>
      <Typography variant="h4" sx={{ marginBottom: '20px' }}>
        {survey?.subtype ?? t('Survey')} {t('progress')}
      </Typography>
      {(!tiers.length || !currentEditorsList) && <CenteredRadarSpinner />}
      {!!tiers.length && !!currentEditorsList && (
        <>
          <Box mb="30px">
            <Grid container sx={{ flexGrow: 1 }} spacing={2}>
              {tiers.map((tier, index) => {
                return (
                  <Grid
                    item
                    xs={layoutInRows ? 12 : 6}
                    sm={layoutInRows ? 12 : above1000Screen ? 2 : 3}
                    md={layoutInRows ? 12 : 2}
                    key={`tierCard-${index}`}
                  >
                    <TierInfoCard
                      {...tier}
                      editors={currentEditorsList
                        ?.filter((e) => e.tier_id === tier.id)
                        ?.map((e) => ({ id: e.user_id, first_name: e.first_name, last_name: e.last_name, email: '' }))}
                    />
                  </Grid>
                )
              })}
            </Grid>
          </Box>
          <Grid container style={{ position: 'relative' }}>
            {isUserInRole(Role.Admin) && !isSurveySubmitted && (
              <Box>
                <PrimaryButton variant="contained" onClick={() => navigate('/submit/review')}>
                  {t('common:buttons.submit')}
                </PrimaryButton>
              </Box>
            )}
          </Grid>
        </>
      )}
    </Box>
  )
}

export default Dashboard
