import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, styled, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useAuth from '../../hooks/useAuth'
import { useNeedHeightAdjustments } from '../../hooks/useNeedHeightAdjustments'
import { useWindowSize } from '../../hooks/useWindowSize'
import { getMonthName } from '../../services/dateHelper'
import theme from '../../theme'

const SubmitContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  flexDirection: 'column',
  justifyContent: 'center',
})

const SubmitNotification = () => {
  const { t } = useTranslation(['submission', 'common'])
  const needHeightAdjustments = useNeedHeightAdjustments()
  const { width } = useWindowSize()
  const { user } = useAuth()
  const [paddingValue, setPaddingValue] = useState<number>(190)

  useEffect(() => {
    let value = 0
    if (width) {
      if (width <= 1000) value = 50
      else if (width > 1000 && width <= 1280) value = 80
      else if (width > 1280 && width <= 1400) value = 100
      else if (width > 1400 && width <= 1600) value = 120
      else if (width > 1600 && width <= 1700) value = 160
      else if (width > 1700) value = 190
    }
    setPaddingValue(value)
  }, [
    width && width < 1000,
    width && width < 1280,
    width && width < 1400,
    width && width < 1600,
    width && width < 1700,
  ])

  const now = new Date()

  return (
    <SubmitContainer padding={`${needHeightAdjustments ? '60px' : '90px'} ${paddingValue}px 0`}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          margin: 'auto',
          pt: needHeightAdjustments ? '60px' : '90px',
        }}
      >
        <FontAwesomeIcon icon={faCircleCheck} color={theme.palette.success.main} size="7x" />
        <Typography variant="h3" color={theme.palette.success.main} textAlign="center" mt="30px" mb="45px">
          {t('ISAT_complete')}
        </Typography>
        <Typography variant="h4" textAlign="center" mb="20px">
          ISAT {t('evaluation')} {user?.organisation?.name || ''} {getMonthName(now)} {now.getFullYear()}{' '}
          {t('ISAT_complete_description')}
        </Typography>
        <Typography variant="h4" textAlign="center" mb="45px">
          {t('ISAT_complete_thank_you')}
        </Typography>
      </Box>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Box
          component="img"
          sx={{ height: '53px', width: '116px', margin: `${needHeightAdjustments ? '60px' : '90px'} 0` }}
          alt="Sirius Aviation Logo"
          src="/images/sa-logo.png"
        />
      </Box>
    </SubmitContainer>
  )
}

export default SubmitNotification
