import { Box, Tooltip } from '@mui/material'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useAuth from '../hooks/useAuth'

interface DisabledComponentTooltipProps {
  isLocked: boolean
  isCurrentlyEdited: boolean
  disabled: boolean
  children: React.ReactElement<any, any>
}

export const DisabledComponentTooltip = ({
  isLocked,
  isCurrentlyEdited,
  disabled,
  children,
}: DisabledComponentTooltipProps) => {
  const { t } = useTranslation(['surveyQuestions'])
  const { isViewer } = useAuth()

  const title = useMemo(() => {
    if (isViewer) return t('tooltips.no_permission')
    if (isLocked) return t('tooltips.response_locked')
    if (isCurrentlyEdited) return t('tooltips.response_currently_edited')
    return ''
  }, [isViewer, isCurrentlyEdited, isLocked])

  return (
    <Tooltip title={title} disableHoverListener={!disabled} followCursor>
      <Box width="100%">{children}</Box>
    </Tooltip>
  )
}
