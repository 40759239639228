export interface Permission {
  id?: number
  name: string
  guard_name: string
}

export interface UserRole {
  name: string
  id: Role
  permissions?: Permission[]
}

export enum Role {
  Superadmin = 1,
  Admin = 2,
  Responder = 3,
  Viewer = 4,
}

export interface LoginRequest {
  email: string
  password: string
}

export interface LoginErrorResponse {
  email: string[]
  password: string[]
}

export interface LoginResponse {
  access_token: string
}

export interface PasswordEmailRequest {
  email: string
}

export interface PasswordEmailErrorResponse {
  email: string[]
}

export interface VerifyResetTokenRequest {
  token: string
}

export interface ResetPasswordRequest {
  token: string
  password: string
  password_confirmation: string
}
