import * as yup from 'yup'
import i18n from '../i18n'

const organisation_type_id = {
  organisation_type_id: yup.number().required(i18n.t('common:validation.required')),
}

const name = {
  name: yup.string().required(i18n.t('common:validation.required')),
}

const handle = {
  handle: yup.string().required(i18n.t('common:validation.required')),
}

const image = {
  image: yup.string().nullable().notRequired(),
  image_width: yup.number().min(0).notRequired(),
  image_height: yup.number().min(0).notRequired(),
}

const thumbnail = {
  thumbnail: yup.string().nullable().notRequired(),
  thumbnail_width: yup.number().min(0).notRequired(),
  thumbnail_height: yup.number().min(0).notRequired(),
}

const favicon = {
  favivon: yup.string().nullable().notRequired(),
}

const url_slug = {
  url_slug: yup.string().nullable().notRequired(),
}

export const UpdateOrganisationSchema = yup.object().shape({
  ...organisation_type_id,
  ...name,
  ...handle,
  ...image,
  ...thumbnail,
  ...favicon,
  ...url_slug,
})
