import { useContext } from 'react'
import { SurveyContext } from '../providers/SurveyProvider'

const useSurvey = () => {
  const context = useContext(SurveyContext)

  if (context === undefined) {
    throw new Error('useSurvey must be used within SurveyContext')
  }

  return context
}

export default useSurvey
