import { Box, Grid, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import theme from '../../theme'
import { ScrollableContainer } from '../../_components/ScrollableContainer'

const Instructions = () => {
  const { t } = useTranslation(['instructions'])
  const mdScreen = useMediaQuery('(min-width:1200px)')
  const lgScreen = useMediaQuery('(min-width:1600px)')

  const Title = ({ children }: { children: React.ReactNode }) => (
    <Grid container mb="20px">
      <Grid item md={mdScreen ? 7 : 12}>
        <Typography variant="h4" color={theme.palette.primary.main}>
          {children}
        </Typography>
      </Grid>
    </Grid>
  )

  const Subtitle = ({ children }: { children: React.ReactNode }) => (
    <Grid container mb="10px">
      <Grid item md={mdScreen ? 7 : 12}>
        <Typography variant="h6" color={theme.complimentary}>
          {children}
        </Typography>
      </Grid>
    </Grid>
  )

  const Content = ({ image, children }: { image?: string; children: React.ReactNode }) => (
    <Grid container mb="30px">
      <Grid item md={mdScreen ? 7 : 12}>
        <Typography variant="body1">{children}</Typography>
      </Grid>
      {!!image && (
        <Grid
          item
          md={mdScreen ? 5 : 12}
          sx={{
            p: mdScreen ? '0 20px' : 0,
            mt: mdScreen ? 0 : '10px',
            display: 'flex',
            justifyContent: mdScreen ? 'center' : 'flex-start',
          }}
        >
          <Box
            component="img"
            src={`/images/instructions/${image}`}
            width={lgScreen ? 'fit-content' : '350px'}
            height={'fit-content'}
          />
        </Grid>
      )}
    </Grid>
  )

  return (
    <ScrollableContainer height="calc(100vh - 380px)" mt="50px" mb="50px" pr="20px">
      <Title>{t('titles.welcome_to_ISAT')}</Title>
      <Content>{t('content.welcome_to_ISAT')}</Content>
      <Title>{t('titles.what_is_ISAT')}</Title>
      <Content>
        <Box mb="10px">{t('content.what_is_ISAT_1')}</Box>
        <Box>{t('content.what_is_ISAT_2')}</Box>
      </Content>
      <Title>{t('titles.using_the_tool_securely')}</Title>
      <Content>
        <>{t('content.using_the_tool_securely_start')}</> <strong>{t('content.using_the_tool_securely_bold')}</strong>{' '}
        <>{t('content.using_the_tool_securely_end')}</>
      </Content>
      <Title>{t('titles.how_to_navigate')}</Title>
      <Subtitle>{t('subtitles.tiers_sections_questions')}</Subtitle>
      <Content>
        <Box mb="10px">{t('content.tiers_sections_questions_1')}</Box>
        <Box>{t('content.tiers_sections_questions_2')}</Box>
      </Content>
      <Subtitle>{t('subtitles.hamburger_menu')}</Subtitle>
      <Content>
        <>{t('content.hamburger_menu_start')}</> <strong>{t('content.hamburger_menu_bold')}</strong>{' '}
        <>{t('content.hamburger_menu_end')}</>
      </Content>
      <Subtitle>{t('subtitles.navigating_the_evaluation')}</Subtitle>
      <Content image="tier-navigation.png">
        <Box mb="10px">
          <>{t('content.navigating_the_evaluation_1_start')}</>{' '}
          <strong>{t('content.navigating_the_evaluation_1_bold')}</strong>{' '}
          <>{t('content.navigating_the_evaluation_1_end')}</>
        </Box>
        <Box mb="10px">
          <strong>{t('content.navigating_the_evaluation_2_bold')}</strong>{' '}
          <>{t('content.navigating_the_evaluation_2_end')}</>
        </Box>
        <Box mb="10px">{t('content.navigating_the_evaluation_3')}</Box>
        <Box>{t('content.navigating_the_evaluation_4')}</Box>
      </Content>
      <Subtitle>{t('subtitles.completing_the_evaluation')}</Subtitle>
      <Content image="tabs.png">
        <Box mb="10px">
          <>{t('content.completing_the_evaluation_1_start')}</>{' '}
          <strong>{t('content.completing_the_evaluation_1_bold')}</strong>
          <>{t('content.completing_the_evaluation_1_end')}</>
        </Box>
        <Box mb="10px">{t('content.completing_the_evaluation_2')}</Box>
        <Box>
          <>{t('content.completing_the_evaluation_3_start')}</>{' '}
          <strong>{t('content.completing_the_evaluation_3_bold_1')}</strong>{' '}
          <>{t('content.completing_the_evaluation_3_middle')}</>{' '}
          <strong>{t('content.completing_the_evaluation_3_bold_2')}</strong>{' '}
          <>{t('content.completing_the_evaluation_3_end')}</>
        </Box>
      </Content>
      <Subtitle>{t('subtitles.uploading_supporting_documents')}</Subtitle>
      <Content image="file-uploads.png">
        <Box mb="10px">
          <>{t('content.uploading_supporting_documents_1_start')}</>{' '}
          <strong>{t('content.uploading_supporting_documents_1_bold_1')}</strong>
          <>{t('content.uploading_supporting_documents_1_middle')}</>{' '}
          <strong>{t('content.uploading_supporting_documents_1_bold_2')}</strong>
          <>{t('content.uploading_supporting_documents_1_end')}</>
        </Box>
        <Box>{t('content.uploading_supporting_documents_2')}</Box>
        <Box mb="10px">
          <ul>
            <li>{t('content.uploading_supporting_documents_2_option_1')}</li>
            <li>{t('content.uploading_supporting_documents_2_option_2')}</li>
            <li>{t('content.uploading_supporting_documents_2_option_3')}</li>
          </ul>
        </Box>
        <Box>{t('content.uploading_supporting_documents_3')}</Box>
      </Content>
      <Subtitle>{t('subtitles.page_locking')}</Subtitle>
      <Content image="lock-page.png">
        <>{t('content.page_locking_start')}</> <strong>{t('content.page_locking_bold_1')}</strong>{' '}
        <>{t('content.page_locking_middle')}</> <strong>{t('content.page_locking_bold_2')}</strong>{' '}
        <>{t('content.page_locking_end')}</>
      </Content>
      <Subtitle>{t('subtitles.ISAT_submission')}</Subtitle>
      <Content>
        <>{t('content.ISAT_submission_start')}</> <strong>{t('content.ISAT_submission_bold_1')}</strong>{' '}
        <>{t('content.ISAT_submission_middle')}</> <strong>{t('content.ISAT_submission_bold_2')}</strong>{' '}
        <>{t('content.ISAT_submission_end')}</>
      </Content>
    </ScrollableContainer>
  )
}

export default Instructions
