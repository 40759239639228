import { Box, Card, CardActionArea, CardProps, Collapse, styled, Typography, useMediaQuery } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import theme from '../../../theme'
import { Node } from '../../../types/surveyTypes'
import { LockedPages } from '../../../_components/LockedPages'

const StyledCard = styled(Card)({
  backgroundColor: theme.primaryBG,
  boxShadow: theme.buttonBoxShadow,
  borderRadius: '9px',
  position: 'relative',
  transition: 'all 0.5s ease-in-out',
})

const StyledCardActionArea = styled(CardActionArea)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '13px 27px',
  minHeight: '73px',
})

interface ReviewCardProps extends CardProps {
  prefix: string
  title: string
  progress: number
  lockedQuestionsCount: number
  allQuestionsCount: number
  onCardClick: () => void
}

const ReviewCard = ({
  prefix,
  title,
  progress,
  lockedQuestionsCount,
  allQuestionsCount,
  onCardClick,
  ...props
}: ReviewCardProps) => {
  return (
    <StyledCard {...props}>
      <StyledCardActionArea onClick={onCardClick}>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Typography variant="h5" sx={{ mr: '50px', display: 'flex', alignItems: 'center' }}>
            {prefix}
          </Typography>
          <Typography variant="h6">{title}</Typography>
        </Box>
        <LockedPages allQuestionsCount={allQuestionsCount} lockedQuestionsCount={lockedQuestionsCount} />
      </StyledCardActionArea>
    </StyledCard>
  )
}

interface TierInfoProps {
  tier: Node
  tierNumber: number
  tiersCount: number
  noExpandedCards: boolean
  expansionCallback: (expanded: boolean) => void
}

const TierInfo = ({ tier, tierNumber, tiersCount, noExpandedCards, expansionCallback }: TierInfoProps) => {
  const { t } = useTranslation(['common', 'submission'])
  const [expanded, setExpanded] = useState<boolean>(false)
  const needWidthAdjustments = useMediaQuery('(min-width:1600px)')

  const handleCardClick = () => {
    const value = !expanded
    setExpanded(value)
    expansionCallback(value)
  }

  const allQuestions = tier.children
    ? tier.children
        .flatMap((s) => s.children)
        .filter((q) => !!q?.inputs?.length || !!q?.children?.filter((sq) => !!sq.inputs?.length).length)
    : []
  const allQuestionsCount = allQuestions.length
  const lockedQuestionsCount = allQuestions.filter((q) => !!q?.locked_at).length

  return (
    <>
      <ReviewCard
        sx={{
          zIndex: tiersCount - (tierNumber - 1),
          mb: noExpandedCards || tiersCount === tierNumber ? '15px' : '-10px',
        }}
        prefix={`${t('tier')} ${tierNumber}`}
        title={tier.title}
        progress={tier.progress}
        allQuestionsCount={allQuestionsCount}
        lockedQuestionsCount={lockedQuestionsCount}
        onCardClick={handleCardClick}
      />
      <Collapse in={expanded}>
        {tier.children &&
          tier.children.map((section, index) => {
            const sectionQuestions = section.children
              ? section.children.filter(
                  (q) => !!q?.inputs?.length || !!q?.children?.filter((sq) => !!sq.inputs?.length).length,
                )
              : []
            const lockedSectionQuestionsCount = sectionQuestions.filter((q) => !!q?.locked_at).length
            return (
              <Box key={section.id} sx={{ marginY: '35px', marginX: needWidthAdjustments ? '60px' : '15px' }}>
                <ReviewCard
                  prefix={`${tierNumber}.${index + 1}`}
                  title={section.title}
                  progress={section.progress}
                  allQuestionsCount={sectionQuestions?.length}
                  lockedQuestionsCount={lockedSectionQuestionsCount}
                  onCardClick={handleCardClick}
                />
              </Box>
            )
          })}
      </Collapse>
    </>
  )
}

export default TierInfo
