import {
  FormControl,
  Grid,
  MenuItem,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Typography,
  useMediaQuery,
} from '@mui/material'
import React, { useState } from 'react'
import useAuth from '../../../../hooks/useAuth'
import { Input, InputType } from '../../../../types/surveyTypes'
import { DisabledComponentTooltip } from '../../../../_components/DisabledComponentTooltip'
import { MultilineTextField } from '../../../../_components/MultilineTextField'
import { RadioFormControlLabel } from '../../../../_components/Radio'

interface QuestionInputProps {
  input: Input
  isLocked: boolean
  isCurrentlyEdited: boolean
  isScrollVisible: boolean
  hasUploadInput: boolean
  onInputChange: (value: string, inputId: number) => void
  onStartEditing: (inputId: number) => void
  onFinishEditing: (inputId: number) => void
  onInputBlur: (value: string, inputId: number) => void
  onRadioChange: (value: string, inputId: number) => void
}

const QuestionInput = ({
  input,
  isLocked,
  isCurrentlyEdited,
  isScrollVisible,
  hasUploadInput,
  onInputChange,
  onStartEditing,
  onFinishEditing,
  onInputBlur,
  onRadioChange,
}: QuestionInputProps) => {
  const { isViewer } = useAuth()
  const lgScreen = useMediaQuery('(min-width:1600px)')
  const [focusState, setFocussedState] = useState<string>()

  const handleOnFocus = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
    setFocussedState(e.target.value)
    onStartEditing(input.id)
  }

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onRadioChange(e.target.value, input.id)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onInputChange(e.target.value, input.id)
  }

  const handleSelectChange = (e: SelectChangeEvent) => {
    onRadioChange(e.target.value, input.id)
  }

  const handleInputBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (focusState !== e.target.value) onInputBlur(e.target.value, input.id)
    else onFinishEditing(input.id)
  }

  const disabled = isViewer || isLocked || isCurrentlyEdited
  const adminBorder = { borderBottom: '1px solid #cc0000', borderRight: '1px solid #cc0000', borderRadius: '4px' }

  return (
    <Grid
      container
      key={`${input.id}-${input.type}`}
      sx={{
        mb: lgScreen ? '20px' : '20px',
        paddingRight: hasUploadInput || isScrollVisible ? '40px' : 0,
      }}
    >
      {(input.title || input.restricted) && (
        <Typography
          variant={lgScreen ? 'subtitle1' : 'subtitle1'}
          sx={{
            mb: lgScreen ? '10px' : '5px',
            width: '100%',
          }}
        >
          {input.title ? `${input.title}:` : ''}
          {input.restricted && (
            <Typography
              variant="caption"
              sx={{ float: 'right', marginTop: '5px', marginBottom: '-5px', color: '#cc0000' }}
            >
              Restricted
            </Typography>
          )}
        </Typography>
      )}

      {input.type == InputType.TextLong && (
        <DisabledComponentTooltip disabled={disabled} isLocked={isLocked} isCurrentlyEdited={isCurrentlyEdited}>
          <MultilineTextField
            sx={input.restricted ? adminBorder : null}
            rows={5}
            value={input.response?.entered_as ?? ''}
            disabled={disabled}
            onFocus={handleOnFocus}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
          />
        </DisabledComponentTooltip>
      )}
      {input.type == InputType.Boolean && (
        <DisabledComponentTooltip disabled={disabled} isLocked={isLocked} isCurrentlyEdited={isCurrentlyEdited}>
          <RadioGroup row value={input.response?.entered_as} onChange={handleRadioChange}>
            {input.metadata?.values &&
              Object.entries(input.metadata.values).map((value) => (
                <RadioFormControlLabel
                  key={`${input.type}-${input.id}-${value[0]}`}
                  value={value[0]}
                  label={value[0]}
                  disabled={disabled}
                />
              ))}
          </RadioGroup>
        </DisabledComponentTooltip>
      )}
      {input.type == InputType.SingleSelect && (
        <DisabledComponentTooltip disabled={disabled} isLocked={isLocked} isCurrentlyEdited={isCurrentlyEdited}>
          <FormControl fullWidth>
            <Select
              sx={input.restricted ? { width: '100%', ...adminBorder } : { width: '100%' }}
              value={input.response?.entered_as ?? ''}
              onChange={handleSelectChange}
              title={input.title ?? null}
              displayEmpty={true}
            >
              {input.options &&
                input.options.map((option) => (
                  <MenuItem key={option.handle} value={option.handle} title={option.description ?? option.title}>
                    {option.title}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </DisabledComponentTooltip>
      )}
    </Grid>
  )
}

export default QuestionInput
