import { faHouse } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, styled } from '@mui/material'
import React from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import useMainLayoutPadding from '../../hooks/useMainLayoutPadding'
import { useNeedHeightAdjustments } from '../../hooks/useNeedHeightAdjustments'
import { PrimaryIconButton } from '../Buttons'
import { ContainerProps } from './MainLayout'

interface DefaultContainerProps extends ContainerProps {
  backgroundImage: boolean
}

const DefaultContainer = ({
  companyLogoContent,
  drawerToggleContent,
  footerContent,
  hasFooter,
  backgroundImage,
}: DefaultContainerProps) => {
  const needHeightAdjustments = useNeedHeightAdjustments()
  const paddingValue = useMainLayoutPadding()
  const navigate = useNavigate()

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        minHeight: backgroundImage ? 'calc(100vh - 190px)' : '100vh',
        background: 'white',
        borderRadius: backgroundImage ? '6px' : 0,
      }}
    >
      {backgroundImage ? <Box sx={{ position: 'fixed', right: '35px', top: '35px' }}>{drawerToggleContent}</Box> : null}
      <Box sx={{ width: '100%', display: 'flex', padding: '35px 30px 0', justifyContent: 'space-between' }}>
        <Box>{companyLogoContent}</Box>
        {backgroundImage ? (
          <PrimaryIconButton variant="contained" sx={{ marginRight: '25px' }} onClick={() => navigate('/')}>
            <FontAwesomeIcon icon={faHouse} size="lg" />
          </PrimaryIconButton>
        ) : (
          <Box>{drawerToggleContent}</Box>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: '100%',
          height: '100%',
          paddingLeft: backgroundImage ? '100px' : `${paddingValue}px`,
          paddingRight: backgroundImage ? '55px' : `${paddingValue}px`,
        }}
      >
        <Outlet />
        {hasFooter && (
          <Box sx={{ marginBottom: needHeightAdjustments ? '20px' : '40px', width: '100%' }}>{footerContent}</Box>
        )}
      </Box>
    </Box>
  )
}

export default DefaultContainer
