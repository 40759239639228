import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useNeedHeightAdjustments } from '../../hooks/useNeedHeightAdjustments'
import useSurvey from '../../hooks/useSurvey'
import { PrimaryButton, SecondaryButton } from '../../_components/Buttons'
import { ScrollableContainer } from '../../_components/ScrollableContainer'
import { CenteredRadarSpinner } from '../../_components/Spinner'
import TierInfo from './components/TierInfo'

const SubmitReview = () => {
  const { t } = useTranslation(['submission', 'common'])
  const needHeightAdjustments = useNeedHeightAdjustments()
  const navigate = useNavigate()
  const { survey } = useSurvey()
  const [expandedCardsCount, setExpandedCardsCount] = useState<number>(0)

  useEffect(() => {
    if (survey.submitted_at) navigate('/submit')
  }, [survey.submitted_at])

  const handleExpansion = (expanded: boolean) => {
    setExpandedCardsCount(expanded ? expandedCardsCount + 1 : expandedCardsCount - 1)
  }

  return (
    <>
      {!survey.root.children && <CenteredRadarSpinner />}
      {survey.root.children && !survey.submitted_at && (
        <>
          <Typography variant="h3" sx={{ marginBottom: '5px' }}>
            {t('submission')}
          </Typography>
          <Typography variant="subtitle1" sx={{ marginBottom: '30px' }}>
            {t('click_to_expand')}
          </Typography>
          <Box pt="5px">
            <ScrollableContainer height={`calc(100vh - ${needHeightAdjustments ? 460 : 580}px)`} pr="20px">
              {survey.root.children?.map((tier, index) => (
                <TierInfo
                  key={tier.id}
                  tier={tier}
                  tierNumber={index + 1}
                  tiersCount={survey.root.children?.length ?? 0}
                  noExpandedCards={expandedCardsCount === 0}
                  expansionCallback={handleExpansion}
                />
              ))}
            </ScrollableContainer>
          </Box>
          <Box position="relative">
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                left: 0,
                flexDirection: 'row',
                mt: '50px',
                mb: needHeightAdjustments ? '100px' : '180px',
              }}
            >
              <PrimaryButton variant="contained" sx={{ marginRight: '10px' }} onClick={() => navigate('/submit')}>
                {t('common:buttons.submit')}
              </PrimaryButton>
              <SecondaryButton variant="outlined" onClick={() => navigate('/')}>
                {t('common:buttons.cancel')}
              </SecondaryButton>
            </Box>
          </Box>
        </>
      )}
    </>
  )
}

export default SubmitReview
