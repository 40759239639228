import React from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import useAuth from '../hooks/useAuth'
import { useSocket, EventType, SurveyUpdatedProps } from '../hooks/useSocket'

const SurveySubmittedSocketContainer = () => {
  const { user } = useAuth()
  const navigate = useNavigate()

  useSocket({
    type: EventType.SurveySubmitted,
    callBack: (info: SurveyUpdatedProps) => {
      if (info.user.id != user?.id) {
        navigate('/submit/notification')
      }
    },
  })
  return <Outlet />
}

export default SurveySubmittedSocketContainer
