import React from 'react'
import useAuth from './useAuth'
import { useSocket, EventType, SurveyUpdatedProps } from './useSocket'

interface LockUnlockSocketProps {
  tierId?: number
  sectionId?: number
  questionId?: number
  lockedCallback: () => void
  unlockedCallback: () => void
}

const useLockUnlockSocket = ({
  tierId,
  sectionId,
  questionId,
  lockedCallback: incrementCallback,
  unlockedCallback: decrementCallback,
}: LockUnlockSocketProps) => {
  const { user } = useAuth()

  useSocket({
    type: EventType.SurveyLocked,
    callBack: (info: SurveyUpdatedProps) => {
      if (
        info.user.id != user?.id &&
        (!!!tierId || tierId === info.metadata.tierId) &&
        (!!!sectionId || sectionId === info.metadata.sectionId) &&
        (!!!questionId || questionId === info.metadata.questionId)
      ) {
        incrementCallback()
      }
    },
  })

  useSocket({
    type: EventType.SurveyUnLocked,
    callBack: (info: SurveyUpdatedProps) => {
      if (
        info.user.id != user?.id &&
        (!!!tierId || tierId === info.metadata.tierId) &&
        (!!!sectionId || sectionId === info.metadata.sectionId) &&
        (!!!questionId || questionId === info.metadata.questionId)
      ) {
        decrementCallback()
      }
    },
  })
}

export default useLockUnlockSocket
