import * as yup from 'yup'
import i18n from '../i18n'
import { email } from './commonSchema'

const first_name = {
  first_name: yup.string().required(i18n.t('common:validation.required')),
}

const last_name = {
  last_name: yup.string().required(i18n.t('common:validation.required')),
}

const job_title = {
  job_title: yup.string().required(i18n.t('common:validation.required')),
}

const avatar = {
  avatar: yup.string().nullable().notRequired(),
}

const role_id = {
  role: yup.number().required(i18n.t('common:validation.required')).integer().notRequired(),
}

const roles = {
  roles: yup
    .array()
    .of(yup.object().shape({ id: yup.number().required(i18n.t('common:validation.required')).integer() }))
    .notRequired(),
}

const organisation_id = {
  organisation_id: yup.number().required(i18n.t('common:validation.required')),
}

export const AddUserSchema = yup.object().shape({
  ...first_name,
  ...last_name,
  ...email,
  ...job_title,
  ...avatar,
  ...role_id,
  ...roles,
  ...organisation_id,
})

export const UpdateUserSchema = yup.object().shape({
  ...first_name,
  ...last_name,
  ...job_title,
  ...avatar,
  ...role_id,
  ...roles,
  ...organisation_id,
})
