import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import COMMON_EN from './_locales/en/common.json'
import AUTH_EN from './_locales/en/auth.json'
import DASHBOARD_EN from './_locales/en/dashboard.json'
import USERS_EN from './_locales/en/users.json'
import NAVIGATION_EN from './_locales/en/navigation.json'
import SURVEY_QUESTIONS_EN from './_locales/en/surveyQuestions.json'
import SUBMISSION_EN from './_locales/en/submission.json'
import ADMIN_EN from './_locales/en/admin.json'
import INSTRUCTIONS_EN from './_locales/en/instructions.json'

i18n.use(initReactI18next).init({
  debug: true,
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      common: COMMON_EN,
      auth: AUTH_EN,
      dashboard: DASHBOARD_EN,
      users: USERS_EN,
      navigation: NAVIGATION_EN,
      surveyQuestions: SURVEY_QUESTIONS_EN,
      submission: SUBMISSION_EN,
      admin: ADMIN_EN,
      instructions: INSTRUCTIONS_EN,
    },
  },
})

export default i18n
