import { Card, CardContent, styled } from '@mui/material'
import theme from '../../theme'

export const InfoCard = styled(Card)({
  height: '95px',
  [theme.breakpoints.up('md')]: {
    maxWidth: '466px',
  },
  borderRadius: '9px',
  alignItems: 'center',
  display: 'flex',
  color: theme.white,
  boxShadow: 'none',
  padding: '20px 20px 20px 30px',
  '@media (min-width:1600px)': {
    height: '134px',
  },
})

export const InfoCardContent = styled(CardContent)({
  padding: 0,
  display: 'inline-flex',
  alignItems: 'center',
  ':last-child': {
    paddingBottom: 0,
  },
})
