import * as yup from 'yup'
import i18n from '../i18n'
import { email } from './commonSchema'

const password = {
  password: yup
    .string()
    .required(i18n.t('common:validation.required'))
    .min(6, i18n.t('auth:validation.min_password_length')),
}

export const LoginSchema = yup.object().shape({
  ...email,
  ...password,
})

export const ForgotPasswordSchema = yup.object().shape({
  ...email,
})

export const PasswordResetSchema = yup.object().shape({
  ...password,
  password_confirmation: yup
    .string()
    .required()
    .test('passwords-match', i18n.t('auth:validation.password_mismatch'), function (value) {
      return this.parent.password === value
    }),
})
