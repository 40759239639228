import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { useWindowSize } from '../../hooks/useWindowSize'
import NavMenu from './NavMenu'

const ErrorLayout = () => {
  const [showBackgroundImage, setShowBackgroundImage] = useState<boolean>(true)
  const { width } = useWindowSize()
  const [open, setOpen] = useState<boolean>(false)

  useEffect(() => {
    setShowBackgroundImage(!!(width && width > 1024))
  }, [width && width > 1024])

  const toggleDrawer = () => {
    setOpen(!open)
  }

  return (
    <Box
      sx={{
        width: '100%',
        display: 'inline-flex',
      }}
    >
      <Box
        sx={{
          width: showBackgroundImage ? '50%' : '0',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundImage: showBackgroundImage
            ? 'linear-gradient(to left, #FFFFFF 3.29%, rgba(255, 255, 255, 0) 93.16%), url(/images/login-background.png)'
            : 'none',
          filter: 'grayscale(100%)',
          opacity: 0.2,
        }}
      />
      <Box sx={{ width: showBackgroundImage ? '50%' : '100%' }}>
        <Box sx={{ cursor: 'pointer', justifyContent: 'flex-end', display: 'flex', padding: '35px 30px 0 0' }}>
          <FontAwesomeIcon icon={faBars} onClick={toggleDrawer} />
        </Box>
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            mt: '-50px',
            padding: '35px 30px',
            textAlign: 'center',
          }}
        >
          <Outlet />
        </Box>
      </Box>
      <NavMenu open={open} toggleDrawer={toggleDrawer} />
    </Box>
  )
}

export default ErrorLayout
