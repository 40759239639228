import { faCheck, faFile, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Grid,
  IconButton,
  LinearProgress,
  SelectChangeEvent,
  styled,
  Typography,
  useMediaQuery,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { camelCaseToCapitalCase } from '../../../../services/stringHelper'
import theme from '../../../../theme'
import { FileProps, FileType } from '../../../../types/surveyTypes'
import { DisabledComponentTooltip } from '../../../../_components/DisabledComponentTooltip'
import { SelectFormControl, SelectOption } from '../../../../_components/Select'
import { FileTextField } from '../../../../_components/TextField'
import TruncatedText from '../../../../_components/TruncatedText'

const StyledLinearProgress = styled(LinearProgress)({
  backgroundColor: theme.actionDisabledBG,
  '& .MuiLinearProgress-bar': {
    backgroundColor: theme.complimentary,
  },
})

const RowGrid = styled(Grid)({
  display: 'flex',
  flexDirection: 'row',
  marginBottom: '10px',
  [theme.breakpoints.up(1600)]: {
    marginBottom: '20px',
  },
})

const RemoveIcon = styled(FontAwesomeIcon)({
  width: '20px',
  '&.svg-inline--fa': {
    height: '20px',
  },
})

interface FileRowProps {
  disabled: boolean
  isLocked: boolean
  isCurrentlyEdited: boolean
  fileProps: FileProps
  typeCallback: (type: FileType) => void
  descriptionCallback: (description: string) => void
  descriptionFocusCallback: () => void
  descriptionBlurCallback: () => void
  removeCallback: () => void
  onFinishEditing: () => void
}

const FileRow = ({
  disabled,
  isLocked,
  isCurrentlyEdited,
  fileProps,
  typeCallback,
  descriptionCallback,
  descriptionFocusCallback,
  descriptionBlurCallback,
  removeCallback,
  onFinishEditing,
}: FileRowProps) => {
  const { t } = useTranslation(['surveyQuestions', 'common'])
  const lgScreen = useMediaQuery('(min-width:1600px)')
  const [descriptionFocusState, setDescriptionFocusState] = useState<string>()

  const componentDisabled =
    disabled ||
    (fileProps.isNew !== undefined &&
      fileProps.isUploaded !== undefined &&
      !!fileProps.isNew &&
      !!!fileProps.isUploaded)

  const [descriptionState, setDescriptionState] = useState(fileProps.description ?? fileProps.upload?.description ?? '')

  useEffect(() => {
    if (fileProps.description) setDescriptionState(fileProps.description)
  }, [fileProps.description])

  const handleSelectChange = (event: SelectChangeEvent<unknown>) => {
    if ((event.target.value as number) > 0) {
      typeCallback(event.target.value as FileType)
    }
  }

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    descriptionCallback(event.target.value)
    setDescriptionState(event.target.value)
  }

  const handleDescriptionFocus = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setDescriptionFocusState(event.target.value)
    descriptionFocusCallback()
  }

  const handleDescriptionBlur = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (descriptionFocusState !== event.target.value) descriptionBlurCallback()
    else onFinishEditing()
  }

  return (
    <RowGrid container>
      <Grid item md={3} sx={{ position: 'relative', pr: ' 40px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
          <FontAwesomeIcon
            icon={faFile}
            color={theme.complimentary}
            width={lgScreen ? '13px' : '11px'}
            height={lgScreen ? '17px' : '15px'}
          />
          <TruncatedText
            title={fileProps.file?.name ?? fileProps.upload?.original_filename ?? fileProps.upload?.filename ?? ''}
          >
            <Typography variant={lgScreen ? 'h6' : 'subtitle2'} ml="20px">
              {fileProps.file?.name || fileProps.upload?.original_filename || fileProps.upload?.filename}
            </Typography>
          </TruncatedText>
        </Box>
      </Grid>
      <Grid item md={2} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', pr: ' 40px' }}>
        <DisabledComponentTooltip
          disabled={componentDisabled}
          isLocked={isLocked}
          isCurrentlyEdited={isCurrentlyEdited}
        >
          <SelectFormControl value={fileProps.fileType ?? 0} onChange={handleSelectChange} disabled={componentDisabled}>
            <SelectOption value={0} disabled>
              {t('common:select_option')}
            </SelectOption>
            {Object.keys(FileType)
              .filter((type) => isNaN(Number(type)))
              .map((type, index) => (
                <SelectOption key={type} value={Object.keys(FileType)[index]}>
                  {camelCaseToCapitalCase(type)}
                </SelectOption>
              ))}
          </SelectFormControl>
        </DisabledComponentTooltip>
      </Grid>
      <Grid item md={4} sx={{ display: 'flex', alignItems: 'center', pr: ' 40px' }}>
        <DisabledComponentTooltip
          disabled={componentDisabled}
          isLocked={isLocked}
          isCurrentlyEdited={isCurrentlyEdited}
        >
          <FileTextField
            value={descriptionState}
            disabled={componentDisabled}
            onChange={handleDescriptionChange}
            onFocus={handleDescriptionFocus}
            onBlur={handleDescriptionBlur}
          />
        </DisabledComponentTooltip>
      </Grid>
      <Grid item md={2} sx={{ width: '100%', margin: 'auto' }}>
        {!fileProps.upload && !fileProps.isUploaded && fileProps.progress !== undefined && (
          <StyledLinearProgress variant="determinate" value={fileProps.progress} />
        )}
        {fileProps.isNew && fileProps.isUploaded && (
          <Box
            sx={{
              color: theme.palette.success.main,
              display: 'flex',
              alignItems: 'center',
              bottom: '10px',
            }}
          >
            <FontAwesomeIcon icon={faCheck} />
            <Typography variant={lgScreen ? 'h6' : 'subtitle1'} ml="10px">
              {t('file_uploaded')}
            </Typography>
          </Box>
        )}
      </Grid>
      <Grid item md={1} sx={{ display: 'flex', justifyContent: 'flex-end', pr: '20px' }}>
        {fileProps.upload?.id && (
          <DisabledComponentTooltip
            disabled={componentDisabled}
            isLocked={isLocked}
            isCurrentlyEdited={isCurrentlyEdited}
          >
            <IconButton disableRipple sx={{ cursor: 'pointer' }} disabled={componentDisabled} onClick={removeCallback}>
              <RemoveIcon icon={faXmark} color={disabled ? theme.actionDisabledBG : theme.actionActive} />
            </IconButton>
          </DisabledComponentTooltip>
        )}
      </Grid>
    </RowGrid>
  )
}

export default FileRow
