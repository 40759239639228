import { AxiosInstance } from 'axios'
import { LoginRequest, PasswordEmailRequest, ResetPasswordRequest, VerifyResetTokenRequest } from '../types/authTypes'
import axios from './apiClient'

export const login = async (requestData: LoginRequest) => {
  const response = await axios.post('/auth/login', requestData)
  return response.data
}

export const logout = async (axiosPrivate: AxiosInstance) => {
  const response = await axiosPrivate.delete('/auth/logout')
  return response.data
}

export const fetchMe = async (axiosPrivate: AxiosInstance) => {
  const response = await axiosPrivate.get('/auth/me')
  return response.data
}

export const postPasswordEmail = async (requestData: PasswordEmailRequest) => {
  const response = await axios.post('/auth/password/email', requestData)
  return response.data
}

export const postVerifyToken = async (requestData: VerifyResetTokenRequest) => {
  const response = await axios.post('/auth/password/reset', requestData)
  return response.data
}

export const postResetPassword = async (requestData: ResetPasswordRequest) => {
  const response = await axios.post('/auth/password/resetsubmit', requestData)
  return response.data
}
