import { useContext } from 'react'
import { DialogStateContext, UseDialogProps } from '../providers/DialogProvider'

const useDialog = (): UseDialogProps => {
  const dialog = useContext(DialogStateContext)

  if (dialog) return dialog
  else throw new Error('Something went terribly wrong')
}

export default useDialog
