import { Box, styled } from '@mui/material'
import React, { useMemo } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { useNeedHeightAdjustments } from '../../hooks/useNeedHeightAdjustments'
import { useWindowSize } from '../../hooks/useWindowSize'
import { ContainerProps } from './MainLayout'

const Header = styled(Box)({
  width: '100%',
  display: 'flex',
  padding: '35px 30px 0',
  justifyContent: 'space-between',
})

const SideImageContainer = ({ companyLogoContent, drawerToggleContent, footerContent, hasFooter }: ContainerProps) => {
  const { width } = useWindowSize()
  const { number } = useParams()
  const needHeightAdjustments = useNeedHeightAdjustments()

  const showBackgroundImage = useMemo<boolean>(() => !!width && width > 1200, [width && width > 1200])

  const imageURL = useMemo<string>(() => {
    let url = '/images/login-background.png'
    if (number) {
      const tierNumber = parseInt(number)
      if (!isNaN(tierNumber) && tierNumber >= 1 && tierNumber <= 6) url = `/images/tiers/${number}.png`
    }
    return url
  }, [number])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', minHeight: '100vh' }}>
      <Header>
        <Box>{companyLogoContent}</Box>
        <Box>{drawerToggleContent}</Box>
      </Header>
      <Box sx={{ display: 'inline-flex', height: '100%' }}>
        <Box
          sx={{
            width: showBackgroundImage ? '33%' : 0,
            height: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundImage: showBackgroundImage
              ? `linear-gradient(to bottom, #FFFFFF 5.49%, rgba(217, 217, 217, 0) 39.13%), url(${imageURL})`
              : 'none',
          }}
        />
        <Box sx={{ maxWidth: showBackgroundImage ? '67%' : '100%', width: '100%' }}>
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              width: '100%',
              flexGrow: 1,
              paddingTop: needHeightAdjustments ? '50px' : '100px',
              paddingRight: '60px',
              paddingLeft: '60px',
            }}
          >
            <Box height="100%">
              <Outlet />
            </Box>
            {hasFooter && (
              <Box sx={{ paddingBottom: needHeightAdjustments ? '20px' : '40px', width: '100%', maxWidth: '990px' }}>
                {footerContent}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default SideImageContainer
