import {
  faArrowRightFromBracket,
  faChevronDown,
  faChevronUp,
  faHouse,
  faQuestionCircle,
  faTools,
  faXmark,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Collapse,
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import useDialog from '../../hooks/useDialog'
import useSurvey from '../../hooks/useSurvey'
import { logout } from '../../services/authApi'
import { disconnectSockets } from '../../services/socketService'
import theme from '../../theme'
import { Node } from '../../types/surveyTypes'
import { ScrollableContainer } from '../ScrollableContainer'
import { Role } from '../../types/authTypes'

interface NavMenuProps {
  open: boolean
  toggleDrawer: () => void
}

interface NavOptionProps {
  number: number
  path: string
  text: string
  expandOptions?: NavOptionProps[]
}

interface NavIconOptionProps {
  path: string
  text: string
  icon: IconDefinition
  onClick?: () => void
}

const NavMenu = (props: NavMenuProps) => {
  const { t } = useTranslation(['navigation'])
  const navigate = useNavigate()
  const context = useAuth()
  const axiosPrivate = useAxiosPrivate()
  const { survey } = useSurvey()
  const [surveyMenu, setSurveyMenu] = useState<NavOptionProps[]>([])
  const [cookies, setCookie] = useCookies()
  const dialog = useDialog()
  const { user, isUserInRole } = useAuth()

  useEffect(() => {
    if (!!survey.root && !!survey.root.children && survey.root.children.length > 0) {
      const menu = survey.root.children.map<NavOptionProps>((tier: Node, tierIndex) => {
        const tierNumber = tierIndex + 1
        return {
          number: tierNumber,
          path: `/tier/${tierNumber}`,
          text: tier.title,
          expandOptions: tier.children?.map((section: Node, sectionIndex) => ({
            number: sectionIndex + 1,
            path: `/survey/${tierNumber}/${sectionIndex + 1}/1`,
            text: section.title,
          })),
        }
      })
      setSurveyMenu(menu)
    }
  }, [survey.root && survey.root.children && survey.root.children.length])

  const { mutate: logoutUser } = useMutation(async () => await logout(axiosPrivate), {
    onSuccess: () => {
      context?.setToken(undefined)
      context?.setUser(undefined)
      setCookie('user', undefined, { path: '/' })
      disconnectSockets()
      navigate('/login')
    },
  })

  const handleOptionClick = (path: string) => {
    props.toggleDrawer()
    if (location.pathname === path) return
    if (path === '/logout') logoutUser()
    else navigate(path)
  }

  const handleHelpClick = () => {
    dialog.openHelpDialog(
      'Would you like to send an email to ISAT support?',
      `s3@s3-systems.co.uk?subject=${survey.name}-${survey.id}`,
    )
  }

  const NavOption = (props: NavOptionProps) => {
    const [expanded, setExpanded] = useState<boolean>(false)

    const handleExpandToggle = () => {
      setExpanded(!expanded)
    }

    return (
      <Box sx={{ marginBottom: expanded ? '40px' : '45px' }}>
        <ListItemButton sx={{ width: '100%', padding: '0 10px' }} key={props.path}>
          <ListItemText
            primary={
              <Typography variant="h6">
                Tier {props.number} {props.text}
              </Typography>
            }
            onClick={() => handleOptionClick(props.path)}
          />
          <ListItemIcon sx={{ justifyContent: 'end' }} onClick={handleExpandToggle}>
            {expanded ? (
              <FontAwesomeIcon icon={faChevronUp} color={theme.black} />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} color={theme.black} />
            )}
          </ListItemIcon>
        </ListItemButton>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <List component="div">
            {props.expandOptions &&
              props.expandOptions.map((option) => (
                <ListItemButton
                  key={option.path}
                  sx={{ padding: '0 10px' }}
                  onClick={() => handleOptionClick(option.path)}
                >
                  <ListItemText
                    primary={
                      <Typography variant="body2">
                        {props.number}.{option.number} {option.text}
                      </Typography>
                    }
                  />
                </ListItemButton>
              ))}
          </List>
        </Collapse>
      </Box>
    )
  }

  const NavIconOption = (props: NavIconOptionProps) => {
    return (
      <ListItemButton
        sx={{ width: '100%', padding: '0 10px', marginBottom: '10px' }}
        key={props.path}
        onClick={props.onClick ? props.onClick : () => handleOptionClick(props.path)}
      >
        <ListItemIcon sx={{ marginRight: '10px', minWidth: 'auto' }}>
          <FontAwesomeIcon icon={props.icon} size="lg" color={theme.palette.primary.main} width="17px" />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography variant="h6" sx={{ textTransform: 'uppercase', letterSpacing: '0.15px' }}>
              {props.text}
            </Typography>
          }
        />
      </ListItemButton>
    )
  }

  return (
    <Drawer
      PaperProps={{
        sx: { width: '500px', padding: '35px 35px 0 35px' },
      }}
      anchor="right"
      variant="temporary"
      open={props.open}
    >
      <Box sx={{ textAlign: 'end', marginBottom: '40px', cursor: 'pointer' }}>
        <FontAwesomeIcon icon={faXmark} size="lg" color={theme.black} onClick={props.toggleDrawer} />
      </Box>
      <List sx={{ width: '100%', color: theme.black, padding: '0 15px' }}>
        <ListSubheader component="div" sx={{ marginBottom: '20px', padding: '0 10px' }}>
          <Typography variant="h5" color={theme.black}>
            {t('quick_select')}
          </Typography>
        </ListSubheader>
        <Box position="relative">
          <ScrollableContainer>
            {surveyMenu.map((option, index) => (
              <NavOption key={`nav-option-${index}`} {...option} />
            ))}
          </ScrollableContainer>
        </Box>
        <Box sx={{ position: 'fixed', bottom: '30px', width: '100%', maxWidth: '400px' }}>
          {isUserInRole(Role.Superadmin) && (
            <>
              <NavIconOption path="/admin" text={t('admin')} icon={faTools} />
              <NavIconOption path="/survey" text={t('active_survey')} icon={faTools} />
            </>
          )}
          <Divider sx={{ marginBottom: '25px' }} />
          <NavIconOption path="/" text={t('back_to_dashboard')} icon={faHouse} />
          <NavIconOption path="" onClick={handleHelpClick} text={t('help')} icon={faQuestionCircle} />
          <NavIconOption path="/logout" text={t('logout')} icon={faArrowRightFromBracket} />
        </Box>
      </List>
    </Drawer>
  )
}

export default NavMenu
