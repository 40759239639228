import { faPaperclip } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, CircularProgress, Grid, styled, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import useAuth from '../../../hooks/useAuth'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { useNeedHeightAdjustments } from '../../../hooks/useNeedHeightAdjustments'
import useSurvey from '../../../hooks/useSurvey'
import { useErrorToast } from '../../../hooks/useToast'
import { UploadsAction } from '../../../reducers/uploadsReducer'
import { isAxiosError } from '../../../services/axiosHelper'
import { deleteUpload, submitUpload, updateUploadDescription, updateUploadFileType } from '../../../services/surveyApi'
import theme from '../../../theme'
import {
  DeleteUploadRequest,
  FileType,
  Input,
  Response,
  SubmitResponseRequest,
  UpdateUploadRequest,
} from '../../../types/surveyTypes'
import { PrimaryButton } from '../../../_components/Buttons'
import { DisabledComponentTooltip } from '../../../_components/DisabledComponentTooltip'
import { ScrollableContainer } from '../../../_components/ScrollableContainer'
import FileRow from './components/FileRow'
import { TabProps } from './TabProps'

const FileTableHeader = styled(Grid)({
  borderTop: `0.5px solid ${theme.actionDisabled}`,
  borderBottom: `0.5px solid ${theme.actionDisabled}`,
  padding: '10px 0',
  [theme.breakpoints.up(1600)]: {
    padding: '25px 0',
    marginTop: '30px',
    marginBottom: '30px',
  },
  display: 'flex',
  flexDirection: 'row',
  marginTop: '20px',
  marginBottom: '20px',
})

interface UploadTabProps extends TabProps {
  input: Input | undefined
  showTypeRequiredError: boolean
  setUploadsStateCallback: (uploadsAction: UploadsAction) => void
}

const UploadDocumentsTab = ({
  tierId,
  sectionId,
  questionId,
  input,
  isLocked,
  isCurrentlyEdited,
  showTypeRequiredError,
  questionTitleHeight,
  uploadsState,
  documentsLoadingCallback,
  setUploadsStateCallback,
  startEditing,
  finishEditing,
}: UploadTabProps) => {
  const { t } = useTranslation(['surveyQuestions', 'common'])
  const needHeightAdjustments = useNeedHeightAdjustments()
  const lgScreen = useMediaQuery('(min-width:1600px)')
  const xsScreen = useMediaQuery('(max-width:740px)')
  const { isViewer, isResponder, isAdmin } = useAuth()
  const { survey, setResponse } = useSurvey()
  const axiosPrivate = useAxiosPrivate()
  const queryClient = useQueryClient()

  const extensions = useMemo(
    () => input?.metadata?.allow.extension.map((e) => `.${e}`).join(', '),
    [JSON.stringify(input?.metadata?.allow.extension)],
  )

  const disabled = isViewer || ((isResponder || isAdmin) && (isLocked || isCurrentlyEdited))

  // Add file ------------------------------------------
  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files)
      if (
        // Check duplicates
        filesArray.some((file) =>
          uploadsState.some(
            (upload) => upload.upload?.original_filename === file.name || upload.upload?.filename === file.name,
          ),
        )
      )
        useErrorToast(t('validation.duplicate_filenames'))
      else if (
        // Check allowed extensions
        filesArray.some((file) => {
          const re = /(?:.([^.]+))?$/
          const format = re.exec(file.name)
          return !input?.metadata?.allow.extension.some((ext) => format && format[1] === ext)
        })
      ) {
        useErrorToast(t('validation.invalid_format'))
      } else {
        const startIndex = uploadsState.length
        if (input) startEditing({ surveyId: survey.id, inputId: input.id })
        Promise.all(
          Array.from(e.target.files).map(async (file, index) => {
            setUploadsStateCallback({ type: 'ADD_LOADING', payload: { file } })
            await uploadFile({
              surveyId: survey.id,
              inputId: input?.id ?? 0,
              data: { entered_as: 'upload', file },
              index: startIndex + index,
            }).then((response) => {
              setUploadsStateCallback({
                type: 'ADD_LOADED',
                index: startIndex + index,
                payload: response.uploads
                  ? {
                      upload: response.uploads[response.uploads?.findIndex((up) => up.original_filename === file.name)],
                    }
                  : {},
              })
            })
          }),
        )
        queryClient.invalidateQueries(['survey'])
      }

      e.target.files = null
    }
  }

  const updateProgress = (progressEvent: ProgressEvent, index: number) => {
    setUploadsStateCallback({
      type: 'PROGRESS',
      index,
      progress: Math.round((progressEvent.loaded * 100) / progressEvent.total),
      payload: {},
    })
  }

  interface UploadRequest extends SubmitResponseRequest {
    index: number
  }
  const { mutateAsync: uploadFile, isLoading: uploadFileLoading } = useMutation<Response, unknown, UploadRequest>(
    ['upload'],
    async ({ surveyId, inputId, data: { file }, index }) =>
      await submitUpload(axiosPrivate, { surveyId, inputId, data: { entered_as: 'upload', file } }, (progressEvent) =>
        updateProgress(progressEvent, index),
      ),
    {
      retry: false,
      onError: (error) => {
        if (isAxiosError(error) && error?.response?.statusText) {
          useErrorToast(`${t('validation.file_not_uploaded')} ${t('common:contact_support')}`)
        }
      },
    },
  )

  // Edit file ------------------------------------------
  const { mutateAsync: updateFileType, isLoading: updateFileLoading } = useMutation<
    Response,
    unknown,
    UpdateUploadRequest
  >(['updateUploadFileType'], async (request) => await updateUploadFileType(axiosPrivate, request), {
    onSuccess: (responseData) => {
      if (responseData) setResponse({ tierId, sectionId, questionId, response: responseData })
    },
    onError: (error) => {
      if (isAxiosError(error) && error?.response?.statusText) {
        useErrorToast(error.response.statusText)
      }
    },
  })

  const handleFileTypeSelect = async (type: FileType, index: number) => {
    if (!disabled) {
      const fileId = uploadsState[index].upload?.id ?? 0
      if (fileId) {
        setUploadsStateCallback({
          type: 'EDIT',
          index,
          payload: { fileType: type },
        })
        updateFileType({
          surveyId: survey.id,
          inputId: input?.id ?? 0,
          data: { file_id: fileId, file_type: Number(type) },
        })
      }
    }
  }

  const { mutateAsync: updateFileDescription, isLoading: descriptionLoading } = useMutation<
    Response,
    unknown,
    UpdateUploadRequest
  >(['updateUploadDescription'], async (request) => await updateUploadDescription(axiosPrivate, request), {
    onSuccess: (responseData) => {
      if (responseData) setResponse({ tierId, sectionId, questionId, response: responseData })
    },
    onError: (error) => {
      if (isAxiosError(error) && error?.response?.statusText) {
        useErrorToast(error.response.statusText)
      }
    },
  })

  const handleDescriptionChange = async (description: string, index: number) => {
    if (!disabled) {
      const uploadId = uploadsState[index].upload?.id ?? 0
      if (uploadId) {
        setUploadsStateCallback({
          type: 'EDIT',
          index,
          payload: { description },
        })
      }
    }
  }

  const handleOnFocusChange = () => {
    if (input?.id) startEditing({ surveyId: survey.id, inputId: input.id })
  }

  const handleFinishEditing = () => {
    if (input?.id) finishEditing({ surveyId: survey.id, inputId: input.id })
  }

  const handleOnBlurChange = async (index: number) => {
    if (!disabled) {
      const fileId = uploadsState[index].upload?.id ?? 0
      const description = uploadsState[index].description ?? undefined
      if (fileId && description) {
        await updateFileDescription({
          surveyId: survey.id,
          inputId: input?.id ?? 0,
          data: { file_id: fileId, description },
        })
      }
    }
  }

  // Remove file ------------------------------------------
  const { mutateAsync: removeFile, isLoading: removeFileLoading } = useMutation<Response, unknown, DeleteUploadRequest>(
    ['removeUpload'],
    async (request) => await deleteUpload(axiosPrivate, request),
    {
      onError: (error) => {
        if (isAxiosError(error) && error?.response?.statusText) {
          useErrorToast(error.response.statusText)
        }
      },
    },
  )

  const handleRemoveUpload = async (index: number) => {
    const fileProps = uploadsState[index]
    if (fileProps) {
      removeFile({ surveyId: survey.id, inputId: input?.id ?? 0, file_id: fileProps.upload?.id ?? 0 }).then(() =>
        setUploadsStateCallback({
          type: 'REMOVE',
          index,
          payload: {},
        }),
      )
      queryClient.invalidateQueries(['survey'])
    }
  }

  // Misc ------------------------------------------
  useEffect(() => {
    if (descriptionLoading || uploadFileLoading || updateFileLoading || removeFileLoading)
      documentsLoadingCallback(true)
    else documentsLoadingCallback(false)
  }, [descriptionLoading, uploadFileLoading, updateFileLoading, removeFileLoading])

  return (
    <Box sx={{ position: 'relative', paddingTop: needHeightAdjustments ? '30px' : '50px' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Box>
          <Typography variant="h4">{t('upload_documents')}</Typography>
          <Typography variant="caption">
            {t('accepted_file_types')}: {extensions}
          </Typography>
          <Typography variant="subtitle2" mt="10px">
            {t('upload_documents_description')}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <DisabledComponentTooltip disabled={disabled} isLocked={isLocked} isCurrentlyEdited={isCurrentlyEdited}>
            <PrimaryButton
              variant="contained"
              component="label"
              disabled={disabled || !!queryClient.isFetching(['survey'])}
            >
              <Box sx={{ display: 'flex', justifyContent: 'center', height: '17.5px', width: '17.5px' }}>
                {!!queryClient.isFetching(['survey']) ? (
                  <CircularProgress size={'17.5px'} sx={{ color: theme.actionDisabled }} />
                ) : (
                  <FontAwesomeIcon icon={faPaperclip} size="lg" />
                )}
              </Box>
              {!xsScreen && <Box sx={{ marginLeft: '15px', lineHeight: '30px' }}>{t('buttons.upload_files')}</Box>}
              <input
                hidden
                accept={extensions}
                //multiple={input?.metadata?.allow.multiple}
                multiple={true}
                type="file"
                disabled={disabled}
                onChange={handleUpload}
              />
            </PrimaryButton>
          </DisabledComponentTooltip>
        </Box>
      </Box>

      {!!uploadsState.length && (
        <Box>
          <FileTableHeader container>
            <Grid item md={3}>
              <Typography variant={lgScreen ? 'h6' : 'subtitle2'}>{t('file_name')}</Typography>
            </Grid>
            <Grid item md={2} sx={{ display: 'flex', flexDirection: 'row' }}>
              <Typography variant={lgScreen ? 'h6' : 'subtitle2'}>{t('file_type')}</Typography>
              {showTypeRequiredError && (
                <Typography
                  variant="caption"
                  color={theme.palette.error.main}
                  letterSpacing={0.15}
                  sx={{ ml: '10px', display: 'flex', pt: '6px' }}
                >
                  ({t('common:validation.required')})
                </Typography>
              )}
            </Grid>
            <Grid item md={4}>
              <Typography variant={lgScreen ? 'h6' : 'subtitle2'}>{t('file_description')}</Typography>
            </Grid>
          </FileTableHeader>
          <ScrollableContainer
            height={`calc(100vh - (${questionTitleHeight}px + ${lgScreen ? '550px' : '480px'} + ${
              needHeightAdjustments ? '0px' : '60px'
            }))`}
          >
            {uploadsState?.map((fileProps, index) => (
              <FileRow
                fileProps={fileProps}
                key={index}
                disabled={disabled || !!queryClient.isFetching(['survey'])}
                isLocked={isLocked}
                isCurrentlyEdited={isCurrentlyEdited}
                typeCallback={(type) => handleFileTypeSelect(type, index)}
                descriptionCallback={(description) => handleDescriptionChange(description, index)}
                descriptionFocusCallback={handleOnFocusChange}
                descriptionBlurCallback={() => handleOnBlurChange(index)}
                removeCallback={() => handleRemoveUpload(index)}
                onFinishEditing={handleFinishEditing}
              />
            ))}
          </ScrollableContainer>
        </Box>
      )}
    </Box>
  )
}

export default UploadDocumentsTab
