import axios, { AxiosError, AxiosResponse } from 'axios'

export function isAxiosError<ResponseType>(error: unknown): error is AxiosError<ResponseType> {
  return axios.isAxiosError(error)
}

export function getFilenameFromContentDisposition(response: AxiosResponse<Response>): string | undefined {
  const disposition = response.headers['content-disposition']
  if (disposition) {
    const i = disposition.indexOf('filename')
    if (i > -1) {
      const filename = disposition.substring(i).split(';')[0]
      return filename.substring(filename.indexOf('=') + 1).trim()
    }
  }
}
