import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Checkbox, FormControlLabel, styled, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import { useNeedHeightAdjustments } from '../../hooks/useNeedHeightAdjustments'
import useSurvey from '../../hooks/useSurvey'
import { useErrorToast } from '../../hooks/useToast'
import { useWindowSize } from '../../hooks/useWindowSize'
import { isAxiosError } from '../../services/axiosHelper'
import { getMonthName } from '../../services/dateHelper'
import { submitNode } from '../../services/surveyApi'
import theme from '../../theme'
import { NodeRequest } from '../../types/surveyTypes'
import { PrimaryButton, SecondaryButton } from '../../_components/Buttons'
import { CenteredRadarSpinner } from '../../_components/Spinner'

const SubmitContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  flexDirection: 'column',
  justifyContent: 'center',
})

const AgreeFormControlLabel = styled(FormControlLabel)({
  '& .MuiTypography-root': {
    fontSize: '16px',
    letterSpacing: '0.15px',
  },
})

const Submit = () => {
  const { t } = useTranslation(['submission', 'common'])
  const needHeightAdjustments = useNeedHeightAdjustments()
  const navigate = useNavigate()
  const axiosPrivate = useAxiosPrivate()
  const { width } = useWindowSize()
  const { user } = useAuth()
  const { survey } = useSurvey()
  const [checked, setChecked] = useState<boolean>(false)
  const [paddingValue, setPaddingValue] = useState<number>(190)
  const [submitted, setSubmitted] = useState<boolean>(false)

  useEffect(() => {
    let value = 0
    if (width) {
      if (width <= 1000) value = 50
      else if (width > 1000 && width <= 1280) value = 80
      else if (width > 1280 && width <= 1400) value = 100
      else if (width > 1400 && width <= 1600) value = 120
      else if (width > 1600 && width <= 1700) value = 160
      else if (width > 1700) value = 190
    }
    setPaddingValue(value)
  }, [
    width && width < 1000,
    width && width < 1280,
    width && width < 1400,
    width && width < 1600,
    width && width < 1700,
  ])

  const { mutate: submitSurvey, isLoading } = useMutation(
    async (request: NodeRequest) => await submitNode(axiosPrivate, request),
    {
      onSuccess: () => {
        setSubmitted(true)
      },
      onError: (error) => {
        setChecked(false)
        if (isAxiosError(error) && error?.response?.statusText) {
          useErrorToast(`${t('messages.submission_error')} ${t('common:contact_support')}`)
        }
      },
    },
  )

  useEffect(() => {
    setSubmitted(!!survey.submitted_at)
  }, [survey.submitted_at])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setChecked(checked)
  }

  const handleSubmit = () => {
    submitSurvey({ surveyId: survey.id, nodeId: survey.root.id })
  }

  const now = new Date()

  return (
    <SubmitContainer padding={`${needHeightAdjustments ? '60px' : '90px'} ${paddingValue}px 0`}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          margin: 'auto',
          pt: needHeightAdjustments ? '60px' : '90px',
        }}
      >
        {isLoading && <CenteredRadarSpinner />}
        {!isLoading && !submitted && (
          <>
            <Typography variant="h3" color={theme.palette.primary.main} textAlign="center" mb="35px">
              {t('submission')}
            </Typography>
            <Typography variant="h5" textAlign="center" mb="45px">
              {t('submission_agreement')}
            </Typography>
            <AgreeFormControlLabel
              label={t('agreement_checkbox')}
              control={<Checkbox value={checked} onChange={handleChange} />}
            />
            <Box mt="50px">
              <PrimaryButton variant="contained" sx={{ mr: '20px' }} disabled={!checked} onClick={handleSubmit}>
                {t('common:buttons.submit')}
              </PrimaryButton>
              <SecondaryButton onClick={() => navigate('/')}>{t('common:buttons.cancel')}</SecondaryButton>
            </Box>
          </>
        )}
        {!isLoading && submitted && (
          <>
            <FontAwesomeIcon icon={faCircleCheck} color={theme.palette.success.main} size="7x" />
            <Typography variant="h3" color={theme.palette.success.main} textAlign="center" mt="30px" mb="45px">
              {t('submission_successful')}
            </Typography>
            <Typography variant="h4" textAlign="center" mb="45px">
              ISAT {t('evaluation')} {user?.organisation?.name || ''} {getMonthName(now)} {now.getFullYear()}{' '}
              {t('submission_successful_description')}
            </Typography>
          </>
        )}
      </Box>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Box
          component="img"
          sx={{ height: '53px', width: '116px', margin: `${needHeightAdjustments ? '60px' : '90px'} 0` }}
          alt="Sirius Aviation Logo"
          src="/images/sa-logo.png"
        />
      </Box>
    </SubmitContainer>
  )
}

export default Submit
