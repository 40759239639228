import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InputAdornment, IconButton } from '@mui/material'
import React, { useState } from 'react'
import { FieldValues, FieldPath } from 'react-hook-form'
import { TextFieldControl, TextFieldControlProps } from '../../../_components/TextField'

const PasswordTextFieldControl = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: TextFieldControlProps<TFieldValues, TName>,
) => {
  const [showPassword, setShowPassword] = useState<boolean>(false)

  const handlePasswordVisibilityClick = () => {
    setShowPassword(!showPassword)
  }

  const handlePasswordMouseEvents = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  return (
    <TextFieldControl
      {...props}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handlePasswordVisibilityClick}
              onMouseDown={handlePasswordMouseEvents}
              onMouseUp={handlePasswordMouseEvents}
            >
              <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} size="xs" />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}

export default PasswordTextFieldControl
