import { Button, styled } from '@mui/material'
import theme from '../theme'

export const PrimaryButton = styled(Button)({
  textTransform: 'uppercase',
  fontSize: '13px',
  height: '30px',
  padding: '4px 10px',
  [theme.breakpoints.up(1200)]: {
    fontSize: '14px',
    height: '36px',
    padding: '5px 15px',
  },
  [theme.breakpoints.up(1600)]: {
    fontSize: '16px',
    height: '42px',
    padding: '10px 20px',
  },
  borderRadius: '4px',
  boxShadow: theme.buttonBoxShadow,
  '&.Mui-disabled': {
    color: theme.actionDisabled,
    backgroundColor: theme.actionDisabledBG,
  },
}) as typeof Button

export const PrimaryButtonDisabledDark = styled(PrimaryButton)({
  '&.Mui-disabled': {
    color: theme.white,
    backgroundColor: theme.actionDisabled,
  },
})

export const PrimaryIconButton = styled(PrimaryButton)({
  padding: '5x 5px',
  [theme.breakpoints.up(1200)]: {
    padding: '10px 10px',
  },
  [theme.breakpoints.up(1600)]: {
    padding: '10px 10px',
  },
  minWidth: 'auto',
})

export const SecondaryButton = styled(PrimaryButton)({
  border: `1px solid ${theme.palette.primary.main}`,
  boxShadow: 'none',
  '&.Mui-disabled': {
    color: theme.actionDisabled,
    borderColor: theme.actionDisabledBG,
    backgroundColor: theme.white,
  },
})

export const SuccessButton = styled(PrimaryButton)({
  background: theme.palette.success.main,
  '&.Mui-disabled': {
    background: theme.successBG,
    color: theme.white,
  },
  ':hover': {
    background: theme.palette.success.main,
  },
})
