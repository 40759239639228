import { AxiosInstance } from 'axios'
import {
  AddUserRequest,
  UpdateUserRequest,
  GetAllOrganisationsResponse,
  GetOwnOrgUsersResponse,
  UpdateOrganisationRequest,
} from '../types/userTypes'
import { axiosPrivate } from './apiClient'

export const postAddUser = async (axiosPrivate: AxiosInstance, requestData: AddUserRequest) => {
  const response = await axiosPrivate.post('/users/add', requestData)
  return response.data
}

export const postEditUser = async (axiosPrivate: AxiosInstance, id: string, requestData: UpdateUserRequest) => {
  const response = await axiosPrivate.post(`/users/edit/${id}`, requestData)
  return response.data
}

export const getOwnOrgUsers = async (axiosPrivate: AxiosInstance): Promise<GetOwnOrgUsersResponse> => {
  const response = await axiosPrivate.get<GetOwnOrgUsersResponse>('/users/own-org')
  return response.data
}

export const getAllUsers = async (axiosPrivate: AxiosInstance): Promise<GetOwnOrgUsersResponse> => {
  const response = await axiosPrivate.get<GetOwnOrgUsersResponse>('/users')
  return response.data
}

export const getEditUser = async (axiosPrivate: AxiosInstance, id: string) => {
  const response = await axiosPrivate.get(`/users/edit/${id}`)
  return response.data
}

export const deleteUser = async (axiosPrivate: AxiosInstance, id: string) => {
  const response = await axiosPrivate.delete(`/users/${id}`)
  return response.status
}

export const fetchOrganisationList = async (axiosPrivate: AxiosInstance): Promise<GetAllOrganisationsResponse> => {
  const response = await axiosPrivate.get<GetAllOrganisationsResponse>('/organisations')
  return response.data
}

export const postAddOrganisation = async (axiosPrivate: AxiosInstance, requestData: UpdateOrganisationRequest) => {
  const response = await axiosPrivate.post('/organisations/', requestData)
  return response.data
}

export const putEditOrganisation = async (
  axiosPrivate: AxiosInstance,
  id: string,
  requestData: UpdateOrganisationRequest,
) => {
  const response = await axiosPrivate.put(`/organisations/${id}`, requestData)
  return response.data
}

export const deleteOrganisation = async (axiosPrivate: AxiosInstance, id: string) => {
  const response = await axiosPrivate.delete(`/organisations/${id}`)
  return response.status
}
