import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { useWindowSize } from '../../hooks/useWindowSize'

const LoginLayout = () => {
  const [showBackgroundImage, setShowBackgroundImage] = useState<boolean>(true)
  const { width } = useWindowSize()

  useEffect(() => {
    setShowBackgroundImage(!!(width && width > 1024))
  }, [width && width > 1024])

  return (
    <Box
      sx={{
        width: '100%',
        display: 'inline-flex',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundImage: showBackgroundImage
          ? 'linear-gradient(to left, #FFFFFF 53.29%, rgba(255, 255, 255, 0) 83.16%), url(/images/login-background.png)'
          : 'none',
      }}
    >
      <Box sx={{ width: showBackgroundImage ? '50%' : '0' }} />
      <Box sx={{ width: showBackgroundImage ? '50%' : '100%' }}>
        <Outlet />
      </Box>
    </Box>
  )
}

export default LoginLayout
