import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
  Typography,
} from '@mui/material'
import React, { SyntheticEvent, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { postAddOrganisation, postEditUser, putEditOrganisation } from '../../../services/userApi'
import {
  AddUserRequest,
  UpdateOrganisationRequest,
  UpdateUserRequest,
  Organisation,
  User,
  OrganisationType,
} from '../../../types/userTypes'
import { AddUserSchema } from '../../../validationSchemas/userSchema'
import { PrimaryButton, SecondaryButton } from '../../../_components/Buttons'
import { SelectControl } from '../../../_components/Select'
import { TextFieldControl } from '../../../_components/TextField'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { useErrorToast, useSuccessToast } from '../../../hooks/useToast'
import { UpdateOrganisationSchema } from '../../../validationSchemas/organisationSchema'
import { string } from 'yup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash, faRemove, faUpload } from '@fortawesome/free-solid-svg-icons'
import { getValue } from '@testing-library/user-event/dist/utils'
import { OrganisationUpdateListener } from './OrganisationAdminPanel'
import { AxiosError, AxiosResponse } from 'axios'
import { ExtendedAxiosError, ExtendedAxiosResponse } from '../../../types/commonTypes'
import { ImageHolder, ThumbnailHolder, HiddenInput, MissingImage, ImageMimeType } from './Common'

interface OrganisationEditFormProps {
  organisation?: Organisation | null
  organisationTypes?: OrganisationType[] | undefined
  updateListener?: OrganisationUpdateListener
  closeForm: () => void
}

const OrganisationEditBox = styled(Box)({
  width: '100%',
})

export const OrganisationEditForm: React.FC<OrganisationEditFormProps> = ({
  organisation,
  organisationTypes,
  updateListener,
  closeForm,
}) => {
  const { t } = useTranslation(['admin', 'common'])
  const axiosPrivate = useAxiosPrivate()
  const queryClient = useQueryClient()

  const {
    register,
    formState: { errors, isSubmitting, isDirty },
    handleSubmit,
    clearErrors,
    setError,
    setValue,
    getValues,
  } = useForm<UpdateOrganisationRequest>({
    resolver: yupResolver(UpdateOrganisationSchema),
    defaultValues: { organisation_type_id: '1', image: organisation?.image },
  })

  const { mutate: updateOrganisation, isLoading: isUpdatingOrganisation } = useMutation<
    Organisation,
    ExtendedAxiosError,
    UpdateOrganisationRequest
  >({
    mutationKey: 'UpdateOrganisation',
    mutationFn: async (org) => {
      clearErrors()
      return organisation && organisation.id
        ? await putEditOrganisation(axiosPrivate, organisation.id, org)
        : await postAddOrganisation(axiosPrivate, org)
    },
    onSuccess: (organisation) => {
      queryClient.invalidateQueries('GetAllOrganisations')
      useSuccessToast(
        t(organisation && organisation.id ? 'messages.organisation_updated' : 'messages.organisation_created'),
      )
      if (organisation && updateListener) updateListener.updated(organisation)
      closeForm()
    },
    onError: (error) => {
      const errors = error.response?.data?.errors
      if (errors) {
        Object.keys(errors).forEach((field: string) => {
          const message = errors[field].join('; ')
          setError(field as keyof UpdateOrganisationRequest, {
            type: 'server',
            message: message,
          })
          useErrorToast(message)
        })
      } else {
        useErrorToast(
          t(
            organisation && organisation.id
              ? 'messages.organisation_update_error'
              : 'messages.organisation_creation_error',
          ),
        )
      }
      console.warn('Failed to write organisation', error)
      queryClient.invalidateQueries('GetAllOrganisations')
    },
  })

  const handleOrgSubmit = (formData: UpdateOrganisationRequest) => {
    if (organisation?.image && imageUrl != organisation.image) {
      formData.image = imageUrl ?? ''
    } else {
      formData.image = imageUrl
    }

    if (organisation?.thumbnail && imageUrl != organisation.thumbnail) {
      formData.thumbnail = thumbnailUrl ?? ''
    } else {
      formData.thumbnail = thumbnailUrl
    }

    updateOrganisation(formData)
  }

  const [organisationTypeId, setOrganisationTypeId] = React.useState('')
  const [image, setImage] = useState<File | null>(null)
  const [imageUrl, setImageUrl] = useState('')
  const [thumbnail, setThumbnail] = useState<File | null>(null)
  const [thumbnailUrl, setThumbnailUrl] = useState('')

  const formRegistration = {
    name: register('name', { value: organisation?.name ?? '' }),
    handle: register('handle', { value: organisation?.handle ?? '' }),
    type: register('organisation_type_id', { value: organisation?.type?.id }),
    image: register('image', {
      setValueAs: (v) => {
        return v
      },
    }),
    thumbnail: register('thumbnail', {
      setValueAs: (v) => {
        return v
      },
    }),
  }

  const handleImageSelect = (e: React.ChangeEvent<HTMLInputElement>, isThumbnail?: boolean) => {
    const file = e.target?.files?.length ? e.target.files[0] : null
    if (!file) return
    if (!file.type.match(ImageMimeType)) {
      useErrorToast(t('not_valid_image_type'))
      return
    }
    isThumbnail ? setThumbnail(file) : setImage(file)
  }

  useEffect(() => {
    let fileReader: FileReader,
      cancelled = false

    if (image) {
      fileReader = new FileReader()
      fileReader.onload = (e) => {
        const result = e.target?.result
        if (result && !cancelled) {
          setImageUrl(result as string)
          setValue('image', result as string)
        }
      }
      fileReader.readAsDataURL(image)

      return () => {
        cancelled = true
        if (fileReader && fileReader.readyState === 1) fileReader.abort()
      }
    }
  }, [image])

  useEffect(() => {
    let fileReader: FileReader,
      cancelled = false

    if (thumbnail) {
      fileReader = new FileReader()
      fileReader.onload = (e) => {
        const result = e.target?.result
        if (result && !cancelled) {
          setThumbnailUrl(result as string)
          setValue('thumbnail', result as string)
        }
      }
      fileReader.readAsDataURL(thumbnail)

      return () => {
        cancelled = true
        if (fileReader && fileReader.readyState === 1) fileReader.abort()
      }
    }
  }, [thumbnail])

  useEffect(() => {
    const typeId = `${organisation?.type?.id ?? ''}`
    setOrganisationTypeId(typeId)
    setValue('organisation_type_id', typeId)

    setImageUrl(organisation?.image ?? '')
    setThumbnailUrl(organisation?.thumbnail ?? '')
    clearErrors()
  }, [organisation])

  return (
    <OrganisationEditBox>
      <form onSubmit={handleSubmit(handleOrgSubmit, (error) => console.error(error))}>
        <Grid
          container
          spacing={'20px'}
          direction={'row'}
          justifyContent={'flex-start'}
          alignItems={'center'}
          sx={{ paddingTop: '20px' }}
        >
          <Grid item xs={8}>
            <Grid
              container
              spacing={'20px'}
              direction={'row'}
              justifyContent={'flex-start'}
              alignItems={'center'}
              sx={{ paddingTop: '20px' }}
            >
              <Grid item xs={6}>
                <FormControl variant="standard" sx={{ width: '100%' }} error={!!errors.name}>
                  <TextField
                    {...formRegistration.name}
                    error={!!errors['name']}
                    label={t('name')}
                    title={errors.name?.message ?? t('name')}
                  ></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="standard" sx={{ width: '100%' }} error={!!errors.organisation_type_id}>
                  <TextField
                    select
                    {...formRegistration.type}
                    value={organisationTypeId}
                    onChange={(e) => {
                      setOrganisationTypeId(e.target.value)
                      setValue('organisation_type_id', e.target.value)
                    }}
                    error={!!errors['organisation_type_id']}
                    label={t('organisation_type')}
                    title={errors.organisation_type_id?.message ?? t('organisation_type')}
                  >
                    {organisationTypes?.map((type: OrganisationType) => (
                      <MenuItem
                        key={type.id}
                        value={`${type.id}`}
                        title={type.description ?? type.name}
                        selected={type.id == organisation?.type?.id}
                      >
                        {type.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="standard" sx={{ width: '100%' }} error={!!errors.handle}>
                  <TextField
                    {...formRegistration.handle}
                    error={!!errors['handle']}
                    label={t('handle')}
                    title={errors.organisation_type_id?.message ?? t('handle')}
                  ></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Grid container direction="row">
                  <Grid item xs={12}>
                    <ThumbnailHolder>
                      {thumbnailUrl ? (
                        <img
                          src={thumbnailUrl.indexOf('/') > -1 ? thumbnailUrl : `/images/organisation/${thumbnailUrl}`}
                          alt={thumbnailUrl}
                        />
                      ) : (
                        <MissingImage>No thumbnail</MissingImage>
                      )}
                      <IconButton
                        aria-label="select organisation thumbnail"
                        role={undefined}
                        component="label"
                        sx={{
                          position: 'absolute',
                          left: '3px',
                          opacity: thumbnailUrl ? 0.2 : 1.0,
                          ':hover': { opacity: 1.0 },
                        }}
                      >
                        <FontAwesomeIcon icon={faUpload} size="lg" />
                        <HiddenInput
                          {...formRegistration.thumbnail}
                          type="file"
                          multiple={false}
                          id="organisation_thumbnail"
                          accept=".png, .jpg, .jpeg"
                          onChange={(e) => handleImageSelect(e, true)}
                        />
                      </IconButton>
                      {thumbnailUrl && (
                        <IconButton
                          aria-label="remove organisation thumbnail"
                          role={undefined}
                          sx={{
                            position: 'absolute',
                            right: '3px',
                            opacity: 0.2,
                            ':hover': { opacity: 1.0 },
                          }}
                          onClick={() => {
                            setValue('thumbnail', '')
                            setThumbnailUrl('')
                          }}
                        >
                          <FontAwesomeIcon icon={faRemove} size="lg" />
                        </IconButton>
                      )}
                    </ThumbnailHolder>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
              <Box sx={{ display: 'inline-flex', marginTop: '20px' }}>
                <PrimaryButton
                  sx={{ margin: '0 10px 10px 0' }}
                  type="submit"
                  variant="contained"
                  // disabled={!isDirty}
                >
                  {t('common:buttons.submit')}
                </PrimaryButton>
                <SecondaryButton onClick={closeForm}>{t('common:buttons.cancel')}</SecondaryButton>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container direction={'row'} justifyContent={'flex-start'} alignItems={'center'}>
              <Grid item xs={12}>
                <ImageHolder>
                  {imageUrl ? (
                    <img
                      src={imageUrl.indexOf('/') > -1 ? imageUrl : `/images/organisation/${imageUrl}`}
                      alt={imageUrl}
                    />
                  ) : (
                    <MissingImage sx={{ marginTop: '48px' }}>No image</MissingImage>
                  )}
                  <IconButton
                    aria-label="select organisation image"
                    role={undefined}
                    component="label"
                    sx={{
                      position: 'absolute',
                      left: '3px',
                      top: 'calc(50% - 22px)',
                      opacity: imageUrl ? 0.2 : 1.0,
                      ':hover': { opacity: 1.0 },
                    }}
                  >
                    <FontAwesomeIcon icon={faUpload} size="lg" />
                    <HiddenInput
                      {...formRegistration.image}
                      type="file"
                      multiple={false}
                      id="organisation_image"
                      accept=".png, .jpg, .jpeg"
                      onChange={(e) => handleImageSelect(e, false)}
                    />
                  </IconButton>
                  {imageUrl && (
                    <IconButton
                      aria-label="remove organisation image"
                      role={undefined}
                      sx={{
                        position: 'absolute',
                        right: '3px',
                        top: 'calc(50% - 22px)',
                        opacity: 0.2,
                        ':hover': { opacity: 1.0 },
                      }}
                      onClick={() => {
                        setValue('image', '')
                        setImageUrl('')
                      }}
                    >
                      <FontAwesomeIcon icon={faRemove} size="lg" />
                    </IconButton>
                  )}
                </ImageHolder>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </OrganisationEditBox>
  )
}
