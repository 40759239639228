import { useEffect } from 'react'
import { createSocketConnection } from '../services/socketService'
import { Input } from '../types/surveyTypes'
import { User } from '../types/userTypes'
import useAuth from './useAuth'
import useSurvey from './useSurvey'

export type SurveyUpdatedProps = {
  user: User
  metadata: {
    fileId?: number
    inputId: number
    questionId: number
    sectionId: number
    subQuestionId: number
    tierId: number
    surveyId: number
  }
  input: Input
}

function listen(callBack: (payload: SurveyUpdatedProps) => void, channel: string, event: string) {
  if (window.Echo)
    window.Echo.private(channel).listen(event, (payload: SurveyUpdatedProps) => {
      callBack(payload)
    })

  return function cleanUp() {
    if (window.Echo) {
      window.Echo.leaveChannel(`private-${channel}`)
    }
  }
}

export enum EventType {
  SurveyEditStart = 'SurveyEditStart',
  SurveyEditFinish = 'SurveyEditFinish',
  SurveyLocked = 'SurveyLocked',
  SurveyUnLocked = 'SurveyUnLocked',
  SurveySubmitted = 'SurveySubmitted',
  SurveyInputResponseFileTypeUpdated = 'SurveyInputResponseFileTypeUpdated',
  SurveyInputResponseFileDeleted = 'SurveyInputResponseFileDeleted',
}

type Options = {
  type: EventType
  callBack: (payload: SurveyUpdatedProps) => void
}

export const useSocket = ({ type, callBack }: Options) => {
  const { token } = useAuth()
  const { survey } = useSurvey()
  useEffect(() => {
    if (!!token) {
      createSocketConnection(token)
      return listen(callBack, `survey.${survey.id}`, type)
    }
  }, [survey.id])
}

function presenceChannel(
  channel: string,
  hereCallback?: (users: User[]) => void,
  joiningCallback?: (user: User) => void,
  leavingCallback?: (user: User) => void,
) {
  if (window.Echo)
    window.Echo.join(channel)
      .here((users: User[]) => {
        console.log('here: ', users)
        if (typeof hereCallback === 'function') hereCallback(users)
      })
      .joining((user: User) => {
        console.log('joining: ', user)
        if (typeof joiningCallback === 'function') joiningCallback(user)
      })
      .leaving((user: User) => {
        console.log('leaving: ', user)
        if (typeof leavingCallback === 'function') leavingCallback(user)
      })
}

export enum PresenseChannel {
  SurveyEditors = 'surveyeditors',
}

type PresenseChannelOptions = {
  type: PresenseChannel
  hereCallback?: (users: User[]) => void
  joiningCallback?: (user: User) => void
  leavingCallback?: (user: User) => void
}

export const usePresenceChannel = ({
  type,
  hereCallback,
  joiningCallback,
  leavingCallback,
}: PresenseChannelOptions) => {
  const { token } = useAuth()
  const { survey } = useSurvey()
  useEffect(() => {
    if (token) {
      createSocketConnection(token)
      return presenceChannel(`${type}.${survey.id}`, hereCallback, joiningCallback, leavingCallback)
    }
  }, [survey.id])
}
