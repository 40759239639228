import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  styled,
  Typography,
  useMediaQuery,
} from '@mui/material'
import {
  DataGrid,
  GridColDef,
  GridComparatorFn,
  GridEventListener,
  GridRenderCellParams,
  GridRowParams,
  GridSortCellParams,
} from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { useNeedHeightAdjustments } from '../../../hooks/useNeedHeightAdjustments'
import useSurvey from '../../../hooks/useSurvey'
import { useErrorToast, useSuccessToast } from '../../../hooks/useToast'
import { sortInputs } from '../../../services/arrayHelper'
import { getFilenameFromContentDisposition, isAxiosError } from '../../../services/axiosHelper'
import {
  fetchSurvey,
  fetchRootNodeTemplates,
  postAddSurvey,
  submitInput,
  assignSurvey,
} from '../../../services/surveyApi'
import theme from '../../../theme'
import {
  Node,
  NodeList,
  SubmitResponseRequest,
  InputType,
  SubmitResponseResponse,
  SurveyList,
  Survey,
  AssignSurveyRequest,
} from '../../../types/surveyTypes'
import { ScrollableContainer } from '../../../_components/ScrollableContainer'
import { CenteredRadarSpinner, RadarSpinner } from '../../../_components/Spinner'
import {
  AddUserRequest,
  UpdateUserRequest,
  Organisation,
  OrganisationList,
  OrganisationType,
  User,
  UserList,
} from '../../../types/userTypes'
import { Root } from 'react-dom/client'
import { axiosPrivate } from '../../../services/apiClient'
import { PrimaryButton, SecondaryButton } from '../../../_components/Buttons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faCheck, faDownload, faEdit, faSave, faEraser } from '@fortawesome/free-solid-svg-icons'
import { SelectControl } from '../../../_components/Select'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { AddUserSchema } from '../../../validationSchemas/userSchema'
import { AddSurveySchema } from '../../../validationSchemas/surveySchema'
import { deleteOrganisation, deleteUser, fetchOrganisationList, postEditUser } from '../../../services/userApi'
import { StyledDialog } from '../../../_components/Dialog'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import i18n from '../../../i18n'
import { OrganisationEditForm } from './OrganisationEditForm'
import { AdminTypes } from '../../../types/AdminTypes'
import admin from '../admin'
import useDialog from '../../../hooks/useDialog'
import { UserEditForm } from './UserEditForm'
import { UserRole } from '../../../types/authTypes'

interface UserAdminPanelProps {
  userList: UserList | undefined
  adminTypes: AdminTypes | undefined
  handleUserUpdate?: UserUpdateListener
  handleUsersUpdate?: () => void
}

export interface UserUpdateListener {
  updated: (user: User) => void
}

const UserAdminPanel = ({ userList, handleUserUpdate, adminTypes, handleUsersUpdate }: UserAdminPanelProps) => {
  const { t } = useTranslation(['admin'])
  const axiosPrivate = useAxiosPrivate()
  const needHeightAdjustments = useNeedHeightAdjustments()
  const lgScreen = useMediaQuery('(min-width:1600px)')

  const [selectedUser, setSelectedUser] = useState<User | null>(null)
  const [isEditing, setIsEditing] = useState(false)

  const dialog = useDialog()

  const handleRowClick: GridEventListener<'rowClick'> = async (event) => {
    if (!userList || isEditing) return

    const user = userList.data.find((user) => user.id === event.row.id)
    if (user) setSelectedUser(user)
  }

  const updateListener: UserUpdateListener = {
    updated: (user: User) => {
      if (handleUserUpdate) handleUserUpdate.updated(user)
      if (selectedUser) setSelectedUser(user)
      setIsEditing(false)
    },
  }

  const orgComparator: GridComparatorFn<Organisation> = (v1: Organisation, v2: Organisation) => {
    return v1?.name?.localeCompare(v2?.name)
  }

  const columns: GridColDef[] = [
    { field: 'first_name', headerName: 'Forename', flex: 12 },
    { field: 'last_name', headerName: 'Surname', flex: 12 },
    {
      field: 'profile_avatar',
      headerName: 'Avatar',
      renderCell: (params: GridRenderCellParams<User>) =>
        params.row.profile_avatar ? (
          <Box
            component="img"
            src={`/images/user/${params.row.profile_avatar}`}
            sx={{ maxHeight: '44px', maxWidth: '100px', marginTop: '2px', textAlign: 'center', alignItems: 'center' }}
          />
        ) : null,
      flex: 8,
    },
    {
      field: 'email',
      headerName: 'Email',
      renderCell: (params: GridRenderCellParams<User>) => <a href={`mailto:${params.row.email}`}>{params.row.email}</a>,
      flex: 20,
    },
    { field: 'job_title', headerName: 'Job title', flex: 12 },
    {
      field: 'organisation',
      headerName: 'Organisation',
      flex: 20,
      sortComparator: orgComparator,
      renderCell: (params: GridRenderCellParams<User>) =>
        params.row.organisation ? (
          params.row.organisation.thumbnail ? (
            <Box
              component="img"
              src={`/images/organisation/${params.row.organisation.thumbnail}`}
              sx={{
                maxHeight: '40px',
                maxWidth: '100px',
                marginTop: '2px',
                marginBottom: '2px',
                textAlign: 'center',
                alignItems: 'center',
              }}
            />
          ) : (
            <>{params.row.organisation.name}</>
          )
        ) : (
          ''
        ),
    },
    {
      field: 'roles',
      headerName: 'Roles',
      flex: 20,
      valueFormatter: (roles: UserRole[] | undefined) => roles?.map((role) => role.name).join(', '),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: (params: GridRenderCellParams<User>) => {
        return (
          <>
            <IconButton
              disabled={isEditing}
              onClick={() => {
                setIsEditing(true)
              }}
            >
              <FontAwesomeIcon icon={faEdit} />
            </IconButton>
            <IconButton
              disabled={isEditing}
              onClick={() => {
                dialog.openDeleteDialog(`${params.row.first_name} ${params.row.last_name}`, () =>
                  deleteUser(axiosPrivate, params.row.id)
                    .then(() => {
                      const user = params.row
                      user.deleted = true
                      if (updateListener) {
                        updateListener.updated(user)
                      }
                      useSuccessToast(t('messages.user_deleted', { user: user }))
                    })
                    .catch((error) => {
                      if (isAxiosError(error)) {
                        useErrorToast(error.message)
                      }
                    }),
                )
              }}
            >
              <FontAwesomeIcon icon={faEraser} />
            </IconButton>
          </>
        )
      },
    },
  ]

  const rows: User[] = userList?.data ?? []

  return (
    <Box sx={{ position: 'relative', paddingTop: needHeightAdjustments ? '30px' : '50px' }}>
      <Grid container>
        {userList ? (
          <>
            <Grid item sx={{ width: '100%' }}>
              <DataGrid
                columns={columns}
                rows={rows}
                isRowSelectable={(params: GridRowParams) => !isEditing || params.id == selectedUser?.id}
                onRowClick={handleRowClick}
                initialState={{
                  pagination: { paginationModel: { pageSize: 10 } },
                }}
                pageSizeOptions={[5, 10, 15, 20]}
              />
            </Grid>
            <Grid item sx={{ width: '100%', textAlign: 'right', padding: '10px 50px' }}>
              <IconButton
                size="medium"
                sx={{ border: `1px solid ${theme.palette.primary.main}` }}
                onClick={() => {
                  const defaultRole = adminTypes?.roles.find((r) => r.name.toLowerCase().startsWith('respond'))
                  setSelectedUser({
                    id: '',
                    deleted: false,
                    email: '',
                    first_name: '',
                    job_title: '',
                    last_name: '',
                    organisation: undefined,
                    role: undefined,
                    roles: undefined,
                  })
                  setIsEditing(true)
                }}
                disabled={isEditing}
                title="Add a new organisation"
              >
                <FontAwesomeIcon icon={faAdd} size="1x" />
              </IconButton>
            </Grid>
            {selectedUser ? (
              <>
                {' '}
                <Grid item sx={{ width: '100%', paddingTop: '30px' }}>
                  {selectedUser?.profile_avatar ? (
                    <Box
                      component="img"
                      src={`/images/user/${selectedUser}`}
                      sx={{ maxHeight: '60px', float: 'right' }}
                    />
                  ) : null}
                  <Typography variant="h4" sx={{ marginTop: '30px' }}>
                    {selectedUser.first_name} {selectedUser.last_name}
                  </Typography>
                  {isEditing && (
                    <UserEditForm
                      user={selectedUser}
                      closeForm={() => setIsEditing(false)}
                      roles={adminTypes?.roles}
                      organisations={adminTypes?.organisations.data}
                      updateListener={updateListener}
                    />
                  )}
                </Grid>
              </>
            ) : null}
          </>
        ) : (
          <Grid item sx={{ width: '100%' }}>
            <CenteredRadarSpinner />
            <Typography
              variant="subtitle1"
              sx={{ marginTop: '-10px', textAlign: 'center', color: theme.palette.text.secondary }}
            >
              {t('loading_users')}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default UserAdminPanel
