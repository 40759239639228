import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  styled,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { DataGrid, GridColDef, GridEventListener, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { useNeedHeightAdjustments } from '../../../hooks/useNeedHeightAdjustments'
import useSurvey from '../../../hooks/useSurvey'
import { useErrorToast, useSuccessToast } from '../../../hooks/useToast'
import { sortInputs } from '../../../services/arrayHelper'
import { getFilenameFromContentDisposition, isAxiosError } from '../../../services/axiosHelper'
import {
  fetchSurvey,
  fetchRootNodeTemplates,
  postAddSurvey,
  submitInput,
  assignSurvey,
} from '../../../services/surveyApi'
import theme from '../../../theme'
import {
  Node,
  NodeList,
  SubmitResponseRequest,
  InputType,
  SubmitResponseResponse,
  SurveyList,
  Survey,
  AssignSurveyRequest,
} from '../../../types/surveyTypes'
import { ScrollableContainer } from '../../../_components/ScrollableContainer'
import { CenteredRadarSpinner, RadarSpinner } from '../../../_components/Spinner'
import {
  AddUserRequest,
  UpdateUserRequest,
  Organisation,
  OrganisationList,
  OrganisationType,
  User,
} from '../../../types/userTypes'
import { Root } from 'react-dom/client'
import { axiosPrivate } from '../../../services/apiClient'
import { PrimaryButton, SecondaryButton } from '../../../_components/Buttons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faCheck, faDownload, faEdit, faSave, faEraser } from '@fortawesome/free-solid-svg-icons'
import { SelectControl } from '../../../_components/Select'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { AddUserSchema } from '../../../validationSchemas/userSchema'
import { AddSurveySchema } from '../../../validationSchemas/surveySchema'
import { deleteOrganisation, fetchOrganisationList, postEditUser } from '../../../services/userApi'
import { StyledDialog } from '../../../_components/Dialog'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import i18n from '../../../i18n'
import { OrganisationEditForm } from './OrganisationEditForm'
import { AdminTypes } from '../../../types/AdminTypes'
import admin from '../admin'
import useDialog from '../../../hooks/useDialog'

interface OrganisationAdminPanelProps {
  organisationList: OrganisationList | undefined
  adminTypes: AdminTypes | undefined
  handleOrganisationUpdate?: OrganisationUpdateListener
  handleOrganisationsUpdate?: () => void
}

export interface OrganisationUpdateListener {
  updated: (organisation: Organisation) => void
}

const OrganisationAdminPanel = ({
  organisationList,
  handleOrganisationUpdate,
  handleOrganisationsUpdate,
  adminTypes,
}: OrganisationAdminPanelProps) => {
  const { t } = useTranslation(['admin'])
  const axiosPrivate = useAxiosPrivate()
  const needHeightAdjustments = useNeedHeightAdjustments()
  const lgScreen = useMediaQuery('(min-width:1600px)')

  const [selectedOrganisation, setSelectedOrganisation] = useState<Organisation | null>(null)
  const [isEditing, setIsEditing] = useState(false)

  const dialog = useDialog()

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', flex: 3 },
    {
      field: 'thumbnail',
      headerName: '',
      renderCell: (params: GridRenderCellParams<Organisation>) =>
        params.row.thumbnail ? (
          <Box
            component="img"
            src={`/images/organisation/${params.row.thumbnail}`}
            sx={{ maxHeight: '44px', maxWidth: '100px', marginTop: '2px', textAlign: 'center', alignItems: 'center' }}
          />
        ) : null,
      flex: 8,
    },
    { field: 'name', headerName: 'Name', flex: 24 },
    { field: 'type', headerName: 'Type', valueFormatter: (type: OrganisationType) => type?.name, flex: 20 },
    { field: 'handle', headerName: 'Handle', flex: 16 },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: (params: GridRenderCellParams<Organisation>) => {
        return (
          <>
            <IconButton
              disabled={isEditing}
              onClick={() => {
                setIsEditing(true)
              }}
            >
              <FontAwesomeIcon icon={faEdit} />
            </IconButton>
            <IconButton
              disabled={isEditing}
              onClick={() => {
                dialog.openDeleteDialog(params.row.name, () =>
                  deleteOrganisation(axiosPrivate, params.row.id)
                    .then(() => {
                      const organisation = params.row
                      organisation.deleted = true
                      if (updateListener) {
                        updateListener.updated(organisation)
                      }
                      useSuccessToast(t('messages.organisation_deleted', { organisation: organisation }))
                    })
                    .catch((error) => {
                      if (isAxiosError(error)) {
                        useErrorToast(error.message)
                      }
                    }),
                )
              }}
            >
              <FontAwesomeIcon icon={faEraser} />
            </IconButton>
          </>
        )
      },
    },
  ]

  const rows: Array<Organisation> = organisationList?.data ?? []

  const handleRowClick: GridEventListener<'rowClick'> = async (event) => {
    if (!organisationList || isEditing) return

    const organisation = organisationList.data.find((organisation) => organisation.id === event.row.id)
    if (organisation) {
      setSelectedOrganisation(organisation)
    }
  }

  const updateListener: OrganisationUpdateListener = {
    updated: (organisation: Organisation) => {
      if (handleOrganisationUpdate) handleOrganisationUpdate.updated(organisation)
      if (selectedOrganisation) setSelectedOrganisation(organisation)
      setIsEditing(false)
    },
  }

  return (
    <Box sx={{ position: 'relative', paddingTop: needHeightAdjustments ? '30px' : '50px' }}>
      <Grid container>
        {organisationList ? (
          <>
            <Grid item sx={{ width: '100%' }}>
              <DataGrid
                columns={columns}
                rows={rows}
                isRowSelectable={(params: GridRowParams) => !isEditing || params.id == selectedOrganisation?.id}
                onRowClick={handleRowClick}
                initialState={{
                  pagination: { paginationModel: { pageSize: 10 } },
                }}
                pageSizeOptions={[5, 10, 15, 20]}
              />
            </Grid>
            <Grid item sx={{ width: '100%', textAlign: 'right', padding: '10px 50px' }}>
              <IconButton
                size="medium"
                sx={{ border: `1px solid ${theme.palette.primary.main}` }}
                onClick={() => {
                  const defaultType = adminTypes?.organisationTypes.find((t) => t.name.toLowerCase().endsWith('client'))
                  setSelectedOrganisation({
                    handle: '',
                    id: '',
                    name: '',
                    type: defaultType,
                    organisation_type_id: defaultType?.id ?? '',
                  })
                  setIsEditing(true)
                }}
                disabled={isEditing}
                title="Add a new organisation"
              >
                <FontAwesomeIcon icon={faAdd} size="1x" />
              </IconButton>
            </Grid>
            {selectedOrganisation ? (
              <>
                {' '}
                <Grid item sx={{ width: '100%', paddingTop: '30px' }}>
                  {selectedOrganisation?.image ? (
                    <Box
                      component="img"
                      src={`/images/organisation/${selectedOrganisation.image}`}
                      sx={{ maxHeight: '60px', float: 'right' }}
                    />
                  ) : null}
                  <Typography variant="h4" sx={{ marginTop: '30px' }}>
                    {selectedOrganisation.name}
                  </Typography>
                  {isEditing && (
                    <OrganisationEditForm
                      organisation={selectedOrganisation}
                      closeForm={() => setIsEditing(false)}
                      organisationTypes={adminTypes?.organisationTypes}
                      updateListener={updateListener}
                    />
                  )}
                </Grid>
              </>
            ) : null}
          </>
        ) : (
          <Grid item sx={{ width: '100%' }}>
            <CenteredRadarSpinner />
            <Typography
              variant="subtitle1"
              sx={{ marginTop: '-10px', textAlign: 'center', color: theme.palette.text.secondary }}
            >
              {t('loading_organisations')}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default OrganisationAdminPanel
