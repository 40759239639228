export const getMonthName = (date: Date, locale = 'en') => {
  const formatter = new Intl.DateTimeFormat(locale, { month: 'long', timeZone: 'UTC' })
  return formatter.format(date)
}

export const dateDiffInDays = (a: Date, b: Date) => {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate())
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate())

  return Math.floor((utc1 - utc2) / _MS_PER_DAY)
}
