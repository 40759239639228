import { Box, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { ResetPasswordRequest, VerifyResetTokenRequest } from '../../types/authTypes'
import { PrimaryButton } from '../../_components/Buttons'
import { yupResolver } from '@hookform/resolvers/yup'
import { PasswordResetSchema } from '../../validationSchemas/authSchemas'
import FormContainer from './components/FormContainer'
import { useTranslation } from 'react-i18next'
import PasswordTextFieldControl from './components/PasswordTextField'
import { postResetPassword, postVerifyToken } from '../../services/authApi'
import { useNeedHeightAdjustments } from '../../hooks/useNeedHeightAdjustments'

const defaultValues = {
  password: '',
  confirmPassword: '',
}

const PasswordReset = () => {
  const { t } = useTranslation(['auth'])
  const { token } = useParams()
  const navigate = useNavigate()
  const needHeightAdjustments = useNeedHeightAdjustments(800)
  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    clearErrors,
  } = useForm<ResetPasswordRequest>({ resolver: yupResolver(PasswordResetSchema), defaultValues })

  const {
    mutate: verifyToken,
    isError: isVerifyTokenError,
    isSuccess: isVerifyTokenSuccess,
  } = useMutation(async (requestData: VerifyResetTokenRequest) => await postVerifyToken(requestData))

  useEffect(() => {
    if (token) {
      const request = { token } as VerifyResetTokenRequest
      verifyToken(request)
    }
  }, [])

  const { mutate: resetPassword, isLoading: isResetPasswordLoading } = useMutation(
    async (requestData: ResetPasswordRequest) => await postResetPassword(requestData),
    {
      onSuccess: () => {
        navigate('/login')
      },
    },
  )

  const handleResetPassword = (formData: ResetPasswordRequest) => {
    resetPassword({ ...formData, token: token || '' })
  }

  const isDisabled = isResetPasswordLoading || isSubmitting

  return (
    <FormContainer>
      <form onSubmit={handleSubmit(handleResetPassword)}>
        <Box sx={{ textAlign: 'center', marginBottom: needHeightAdjustments ? '40px' : '0' }}>
          <Typography sx={{ mb: '25px' }} variant="h4">
            {t('titles.reset_password')}
          </Typography>
          <Box width="400px">
            {isVerifyTokenError && <Typography variant="h5">{t('messages.invalid_token')}</Typography>}
            {isVerifyTokenSuccess && (
              <>
                <PasswordTextFieldControl
                  sx={{ mb: '20px' }}
                  control={control}
                  name="password"
                  label={t('password')}
                  disabled={isDisabled}
                  errorText={errors.password?.message}
                  onFocus={() => clearErrors('password')}
                />
                <PasswordTextFieldControl
                  sx={{ mb: '20px' }}
                  control={control}
                  name="password_confirmation"
                  label={t('confirm_password')}
                  disabled={isDisabled}
                  errorText={errors.password_confirmation?.message}
                  onFocus={() => clearErrors('password_confirmation')}
                />
              </>
            )}
          </Box>
          {isVerifyTokenSuccess && (
            <PrimaryButton
              type="submit"
              variant="contained"
              sx={{
                marginTop: needHeightAdjustments ? '20px' : '70px',
                marginBottom: needHeightAdjustments ? '10px' : '30px',
              }}
              disabled={isDisabled}
            >
              {t('buttons.reset')}
            </PrimaryButton>
          )}
        </Box>
      </form>
    </FormContainer>
  )
}

export default PasswordReset
