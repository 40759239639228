import { Link, styled, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import { LoginErrorResponse, LoginRequest, LoginResponse } from '../../types/authTypes'
import { LocationProps } from '../../types/commonTypes'
import { yupResolver } from '@hookform/resolvers/yup'
import { TextFieldControl } from '../../_components/TextField'
import { PrimaryButton } from '../../_components/Buttons'
import { LoginSchema } from '../../validationSchemas/authSchemas'
import { fetchMe, login } from '../../services/authApi'
import FormContainer from './components/FormContainer'
import theme from '../../theme'
import { useErrorToast } from '../../hooks/useToast'
import { isAxiosError } from '../../services/axiosHelper'
import { useTranslation } from 'react-i18next'
import PasswordTextFieldControl from './components/PasswordTextField'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import { useNeedHeightAdjustments } from '../../hooks/useNeedHeightAdjustments'
import { useCookies } from 'react-cookie'
import { User } from '../../types/userTypes'

const StyledLink = styled(Link)({
  textDecoration: 'none',
  color: theme.black,
  cursor: 'pointer',
})

const defaultValues = {
  email: '',
  password: '',
}

const Login = () => {
  const [cookies, setCookie] = useCookies(['user'])
  const { t } = useTranslation(['auth'])
  const context = useAuth()
  const navigate = useNavigate()
  const location = useLocation() as LocationProps
  const from = location.state?.from?.pathname || '/'
  const axiosPrivate = useAxiosPrivate()
  const needHeightAdjustments = useNeedHeightAdjustments(800)
  const queryClient = useQueryClient()

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    clearErrors,
  } = useForm<LoginRequest>({ resolver: yupResolver(LoginSchema), defaultValues })

  const { mutate: loginUser, isLoading } = useMutation<LoginResponse, unknown, LoginRequest>(
    async (requestData) => await login(requestData),
    {
      onSuccess: (responseData) => {
        if (responseData) {
          context?.setToken(responseData.access_token)
        }
      },
      onError: (error) => {
        if (isAxiosError<LoginErrorResponse>(error) && error?.response?.data) {
          useErrorToast(t('validation.login_error'))
        }
      },
    },
  )

  const {} = useQuery<User>(['user'], async () => await fetchMe(axiosPrivate), {
    enabled: !!context?.token,
    onSuccess: (responseData) => {
      context?.setUser({ ...responseData })
      queryClient.invalidateQueries(['survey'])
      if (from === '/') {
        console.log(responseData)
      }
      navigate(from, { replace: true })
    },
  })

  const handleLogin = (formData: LoginRequest) => {
    loginUser(formData)
  }

  const handleForgotPassword = () => {
    navigate('/password/email')
  }

  const isDisabled = isLoading || isSubmitting

  return (
    <FormContainer>
      <form onSubmit={handleSubmit(handleLogin)}>
        <Box sx={{ textAlign: 'center', marginBottom: needHeightAdjustments ? '40px' : '0' }}>
          <Typography sx={{ mb: '25px' }} variant="h4">
            {t('titles.login')}
          </Typography>
          <Box width="400px">
            <TextFieldControl
              sx={{ mb: '20px' }}
              control={control}
              name="email"
              type="email"
              label={t('email')}
              autoComplete="email"
              disabled={isDisabled}
              errorText={errors.email?.message}
              onFocus={() => clearErrors('email')}
            />
            <PasswordTextFieldControl
              sx={{ mb: '20px' }}
              control={control}
              name="password"
              label={t('password')}
              autoComplete="current-password"
              disabled={isDisabled}
              errorText={errors.password?.message}
              onFocus={() => clearErrors('password')}
            />
          </Box>
          <PrimaryButton
            type="submit"
            variant="contained"
            sx={{
              marginTop: needHeightAdjustments ? '20px' : '70px',
              marginBottom: needHeightAdjustments ? '10px' : '30px',
            }}
            disabled={isDisabled}
          >
            {t('buttons.login')}
          </PrimaryButton>
          <Box>
            <StyledLink onClick={handleForgotPassword}>{t('forgotten_password')}</StyledLink>
          </Box>
        </Box>
      </form>
    </FormContainer>
  )
}

export default Login
