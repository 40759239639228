import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface CenteredRadarSpinnerProps {
  isBigLoad?: boolean
}

export const CenteredRadarSpinner = ({ isBigLoad }: CenteredRadarSpinnerProps) => {
  const { t } = useTranslation(['common'])

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        minHeight: '100%',
      }}
    >
      <RadarSpinner />
      {isBigLoad && <Typography>{t('spinner_load')}</Typography>}
    </Box>
  )
}

export const RadarSpinner = () => {
  /* generated by https://loading.io/ */
  return (
    <>
      <div className="loadingio-spinner-ripple-v71zg6n7erk">
        <div className="ldio-7uutdxphgwf">
          <div></div>
          <div></div>
        </div>
      </div>
      <style type="text/css">
        {`
          @keyframes ldio-7uutdxphgwf {
            0% {
              top: 96px;
              left: 96px;
              width: 0;
              height: 0;
              opacity: 1;
            }
            100% {
              top: 18px;
              left: 18px;
              width: 156px;
              height: 156px;
              opacity: 0;
            }
          }.ldio-7uutdxphgwf div {
            position: absolute;
            border-width: 4px;
            border-style: solid;
            opacity: 1;
            border-radius: 50%;
            animation: ldio-7uutdxphgwf 1s cubic-bezier(0,0.2,0.8,1) infinite;
          }.ldio-7uutdxphgwf div:nth-child(1) {
            border-color: #1a3668;
            animation-delay: 0s;
          }
          .ldio-7uutdxphgwf div:nth-child(2) {
            border-color: #d8b372;
            animation-delay: -0.5s;
          }
          .loadingio-spinner-ripple-v71zg6n7erk {
            width: 200px;
            height: 200px;
            display: inline-block;
            overflow: hidden;
            background: none;
          }
          .ldio-7uutdxphgwf {
            width: 100%;
            height: 100%;
            position: relative;
            transform: translateZ(0) scale(1);
            backface-visibility: hidden;
            transform-origin: 0 0; /* see note above */
          }
          .ldio-7uutdxphgwf div { box-sizing: content-box; }
          /* generated by https://loading.io/ */
        `}
      </style>
    </>
  )
}
