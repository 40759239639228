import { useEffect, useState } from 'react'
import { useWindowSize } from './useWindowSize'

const useMainLayoutPadding = () => {
  const { width } = useWindowSize()
  const [paddingValue, setPaddingValue] = useState<number>(240)

  useEffect(() => {
    let value = 0
    if (width) {
      if (width <= 900) value = 50
      else if (width > 900 && width <= 1100) value = 80
      else if (width > 1100 && width <= 1280) value = 120
      else if (width > 1280 && width <= 1400) value = 170
      else if (width > 1400 && width <= 1700) value = 200
      // Account for monitors larger than 1700 width (i.e. 4K monitors)
      else if (width > 1700) value = (width - 1400) / 2
    }
    setPaddingValue(value)
  }, [width])

  return paddingValue
}

export default useMainLayoutPadding
