import { faCheck, faLock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography, Card, CardActionArea, styled, Box, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import useAuth from '../../../hooks/useAuth'
import useLockUnlockSocket from '../../../hooks/useLockUnlockSocket'
import theme from '../../../theme'
import { Node } from '../../../types/surveyTypes'

const QuestionCard = styled(Card)({
  background: theme.primaryBG,
  borderRadius: '9px',
  marginTop: '20px',
  marginBottom: '20px',
  boxShadow: 'none',
})

const QuestionNumber = styled(Typography)({
  marginRight: '50px',
  textTransform: 'uppercase',
  display: 'flex',
  alignItems: 'center',
  letterSpacing: '1px',
})

interface QuestionInfoCardProps {
  tierNumber: number
  sectionNumber: number
  questionIndex: number
  question: Node
}

const QuestionInfoCard = ({ tierNumber, sectionNumber, questionIndex, question }: QuestionInfoCardProps) => {
  const { t } = useTranslation(['surveyQuestions'])
  const navigate = useNavigate()
  const { user } = useAuth()
  const [locked, setLocked] = useState<boolean>()

  const questionNumber = questionIndex + 1

  const handleQuestionCardClick = () => {
    navigate(`/survey/${tierNumber}/${sectionNumber}/${questionNumber}`)
  }

  useLockUnlockSocket({
    questionId: question.id,
    lockedCallback: () => {
      setLocked(true)
    },
    unlockedCallback: () => {
      setLocked(false)
    },
  })

  const isLocked = (locked != undefined && locked) || (locked == undefined && !!question.locked_at)

  return (
    <QuestionCard>
      <CardActionArea
        sx={{
          display: 'inline-flex',
          justifyContent: 'space-between',
          padding: '16px 27px',
        }}
        onClick={handleQuestionCardClick}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <QuestionNumber variant="overline">
            T{tierNumber}.{sectionNumber}.{questionNumber}
          </QuestionNumber>
          <Typography variant="body1" sx={{ marginRight: '60px' }}>
            {question.title}
          </Typography>
        </Box>
        <Box sx={{ color: theme.complimentary, display: 'inline-flex' }}>
          <Box mr="10px">
            {question.inputs?.every((input) => input.response?.submitted) && (
              <FontAwesomeIcon icon={faCheck} size="lg" />
            )}
          </Box>
          <Box>
            {isLocked && (
              <Tooltip title={t('tooltips.question_locked')} disableHoverListener={!isLocked} followCursor>
                <FontAwesomeIcon icon={faLock} size="lg" />
              </Tooltip>
            )}
          </Box>
        </Box>
      </CardActionArea>
    </QuestionCard>
  )
}

export default QuestionInfoCard
